import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropDownList, DropDownListProps } from '@progress/kendo-react-dropdowns';
import { loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';
import style from './style.module.scss';

import deMessages from './de.json';
import enMessages from './en.json';

loadMessages(deMessages, 'de');
loadMessages(enMessages, 'en');

export const DropDown = (p: DropDownListProps & { customProp?: any }) => {
  const { t, i18n } = useTranslation();
  const newP = { ...p };
  const customProp = p?.customProp || {};
  const defaultValue = [
    { name: 'className', value: style.dropdown },
    { name: 'textField', value: 'text' },
    { name: 'dataItemKey', value: 'value' },
    { name: 'data', value: [] },
    { name: 'popupSettings', value: p.popupSettings || { className: style.popup } },
  ];

  defaultValue.forEach((item) => {
    if (!customProp[item.name]) {
      customProp[item.name] = item.value;
    }
  });

  if (customProp.placeholder) {
    customProp.defaultItem = {
      [customProp.textField]: t(customProp.placeholder),
      [customProp.dataItemKey]: '',
    };

    if (!newP.value) {
      delete newP.value;
    }
  }

  if (!p?.value?.id) {
    customProp.className = `${customProp.className} ${style.placeholder}`;
  }

  return (
    <LocalizationProvider language={i18n.language}>
      <DropDownList {...{ ...newP, ...customProp }} />
    </LocalizationProvider>
  );
};
