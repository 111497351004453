import React, { FC, useEffect } from 'react';
import { GridCellProps, GridDetailRowProps } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { SortDescriptor } from '@progress/kendo-data-query';
import { ButtonSpinner, TableGrid } from '../../../../components';
import { useAppSelector } from '../../../../store';
import { useTranslation } from 'react-i18next';
import { IStorageArea, IStorageAreaInfo } from '../../../../store/slices/storageArea.slice';
import style from './style.module.scss';

interface ITableComponent {
  selectedItems?: string[];
  selectItemHandler?: (dataItem: IStorageArea) => void;
  allItemsSelectHandler?: () => void;
  allItemsSelected?: boolean;
  expandChange: (props: GridCellProps) => Promise<void>;
  dataItemList: IStorageArea[] | IStorageAreaInfo[];
  withPager?: boolean;
  isNested?: boolean;
  dataHandler?: (data: { sort: Array<SortDescriptor>; itemsPerPage?: number; currentPage?: number; inputValue?: string }) => void;
  addButtonText?: string;
  navigate?: () => void;
  withHeaderSearch?: boolean;
  deleteHandler?: (hide: () => void, ids: string[]) => void;
  customLastItem?: JSX.Element | null | undefined;
  customFirstItem?: JSX.Element | null | undefined;
  customTableHeight?: string;
  storageAreaWidth?: string;
  quantity?: string;
  customColspan?: string;
  customDetail?: any;
  customInputValue?: string;
  customItems?: {
    field: string;
    title: string;
    width?: string | undefined;
    headerCell?: any;
    cell?: any;
  }[];
  itemsToOpen?: { [key: string]: boolean };
}

const TableComponent: FC<ITableComponent> = ({
  selectedItems,
  selectItemHandler,
  allItemsSelectHandler,
  allItemsSelected,
  expandChange,
  dataItemList,
  withPager,
  isNested,
  dataHandler,
  addButtonText,
  navigate,
  withHeaderSearch = false,
  deleteHandler,
  customLastItem = undefined,
  customTableHeight,
  customFirstItem,
  storageAreaWidth,
  quantity,
  customColspan,
  customDetail,
  customInputValue,
  customItems,
  itemsToOpen,
}) => {
  const { t } = useTranslation();
  const storageAreaDataCount = useAppSelector((state) => state.storageArea.storageAreaDataCount);
  const isNestedListLoading = useAppSelector((state) => state.storageArea.isNestedListLoading);
  const nestedListLoadingId = useAppSelector((state) => state.storageArea.nestedListLoadingId);

  useEffect(() => {
    document
      .querySelectorAll('.k-detail-cell')
      ?.forEach((element) => element?.setAttribute('colspan', customColspan ? customColspan : customFirstItem ? '8' : '9'));
  }, []);

  return (
    <TableGrid
      customTableHeight={customTableHeight}
      data={dataItemList.map((i) => {
        return {
          ...i,
          isIncluded: selectedItems?.includes(i.id),
          storageClassesData: i.storageClassesData?.map(el => el.number).join(', '),
          withCustomDetail: i.storageLocation.length && itemsToOpen !== undefined && itemsToOpen[i.id],
        };
      })}
      items={
        customItems && customItems.length
          ? customItems
          : [
              {
                field: 'name',
                title: 'Storage Area',
                width: storageAreaWidth ?? '',
              },
              {
                field: 'description',
                title: 'Description',
              },
              {
                field: 'maximumCapacity',
                title: 'Max Capacity',
                width: '170px',
              },
              {
                field: 'freeCapacitySpace',
                title: 'Free Capacity',
              },
              {
                field: 'volume',
                title: 'Max Space',
              },
              {
                field: 'freeSpace',
                title: 'Free Space',
              },
              {
                field: 'storageClassesData',
                title: 'Storage Classes',
              },
            ]
      }
      customDetail={customDetail}
      customLastItem={customLastItem}
      detail={(props: GridDetailRowProps) => {
        if (props.dataItem.nestedList?.length) {
          const isNestedElement = !!props.dataItem.nestedList?.length;
          return (
            <TableComponent
              selectedItems={selectedItems}
              selectItemHandler={selectItemHandler}
              allItemsSelectHandler={allItemsSelectHandler}
              allItemsSelected={allItemsSelected}
              expandChange={expandChange}
              dataItemList={props.dataItem.nestedList.map((el: any) => {
                return {
                  ...el,
                  isSelectForbidden: props.dataItem.isSelectForbidden || (quantity && quantity > el.freeCapacitySpace),
                  withCustomDetail: el.storageLocation.length && itemsToOpen && itemsToOpen[el.id],
                };
              })}
              withPager={false}
              isNested={isNestedElement}
              withHeaderSearch={false}
              deleteHandler={deleteHandler}
              customLastItem={customLastItem}
              customFirstItem={customFirstItem}
              storageAreaWidth={storageAreaWidth}
              customDetail={customDetail}
              customColspan={customColspan}
              customItems={customItems}
              itemsToOpen={itemsToOpen}
            />
          );
        }
        return null;
      }}
      totalCount={storageAreaDataCount}
      withPager={withPager}
      expandField='expanded'
      cellRender={(defaultRendering: React.ReactElement<HTMLTableCellElement> | null, props: GridCellProps) => {
        if (props.field === 'name') {
          return (
            <td aria-colindex={props.columnIndex} role={'gridcell'} className={props.className}>
              <div
                style={{ paddingLeft: props.dataItem.childNumberByHierarchy ? props.dataItem.childNumberByHierarchy * 20 : 0 }}
                className={style.arrowButtonWrapper}
              >
                {isNestedListLoading && props.dataItem.id === nestedListLoadingId ? (
                  <Button className={style.arrowButtonLoader}>
                    <ButtonSpinner isSmall />
                  </Button>
                ) : (
                  <Button
                    style={!props.dataItem.isHasNestedElements ? { opacity: 0, cursor: 'default' } : undefined}
                    icon='arrow-chevron-down'
                    className={props.dataItem.expanded ? style.arrowButtonUp : style.arrowButtonDown}
                    onClick={props.dataItem.isHasNestedElements ? () => expandChange(props) : undefined}
                  />
                )}
                {props.dataItem.name}
              </div>
            </td>
          );
        }
        return defaultRendering;
      }}
      isNested={isNested}
      dataHandler={dataHandler}
      navigate={navigate}
      addButtonText={addButtonText}
      withHeaderSearch={withHeaderSearch}
      deleteFunc={deleteHandler}
      deleteText={t('Are you sure that you want to delete storage areas?') || ''}
      deleteTitle={t('Delete Storage Areas?') || ''}
      selectAreaItemHandler={selectItemHandler}
      customSelectedItems={selectedItems}
      customAllItemsSelectHandler={allItemsSelectHandler}
      customAllItemsSelected={allItemsSelected}
      customFirstItem={customFirstItem}
      saveSorting
      sortingKey='storageArea'
      customInputValue={customInputValue}
    />
  );
};

export default TableComponent;
