import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  clearStorageClassesData,
  deleteStorageClasses,
  getStorageClassesData,
  setIsEmptyStorageClassDataList,
  setIsStorageClassDataFirstLoading,
  setIsStorageClassDeleted,
  setStorageClassModalActive,
} from '../../store/slices/storageClass.slice';
import { PlaceholderComponent, PulsLoader } from '../../components';
import { useNavigate } from 'react-router-dom';
import ContentComponent from './components/ContentComponent';
import { SortDescriptor } from '@progress/kendo-data-query';
import { storageSorting } from '../../util/localStorageSorting';

const StorageClasses = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isEmptyStorageClassDataList, storageClassesData, isStorageClassDataLoading, isStorageClassDataFirstLoading } = useAppSelector(
    (state) => state.storageClass,
  );

  const deleteHandler = (hide: () => void, ids: string[]) => {
    dispatch(
      deleteStorageClasses({
        ids,
        text: t('Storage classes have been successfully deleted.'),
        hide,
      }),
    );
  };

  const dataHandler = ({
    sort,
    currentPage,
    inputValue,
    isFirstTime,
    itemsPerPage,
  }: {
    sort: Array<SortDescriptor>;
    itemsPerPage?: number;
    currentPage?: number;
    inputValue?: string;
    isFirstTime?: boolean;
  }) => {
    dispatch(
      getStorageClassesData({
        limit: itemsPerPage,
        page: currentPage,
        search: inputValue,
        sortBy: sort?.[0]?.field || '',
        sortOrder: sort?.[0]?.dir || '',
        isFirstTime,
      }),
    );
  };

  useEffect(() => {
    const data = storageSorting.get('storageClass');

    dataHandler({ sort: data?.sort ?? [], itemsPerPage: 25, currentPage: 1, isFirstTime: true });
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearStorageClassesData());
      dispatch(setStorageClassModalActive(false));
      dispatch(setIsStorageClassDeleted(false));
      dispatch(setIsStorageClassDataFirstLoading(true));
      dispatch(setIsEmptyStorageClassDataList(false));
    };
  }, []);

  return (
    <div>
      {isStorageClassDataLoading && isStorageClassDataFirstLoading ? (
        <PulsLoader />
      ) : !storageClassesData.length && isEmptyStorageClassDataList ? (
        <PlaceholderComponent
          btnText='Add Storage Classes'
          btnHandler={() => {
            navigate('new-class', {
              replace: true,
            });
          }}
          subtitle="You haven't created any Storage Classes yet. To start working, please click the 'Add Storage Classes' button."
          title='Create Storage Classes'
          placeholderImg={'classNoDataIcon'}
          width='543px'
          btnWith='356px'
        />
      ) : (
        <ContentComponent dataHandler={dataHandler} deleteHandler={deleteHandler} />
      )}
    </div>
  );
};

export default StorageClasses;
