import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../../api/api';
import { IUnit } from './unit.slice';
import { setIsModalActive } from './app.slice';
import { showNotification } from './notification.slice';
import { showErrorNotification } from '../../util/showErrorNotification';
import { reset } from '..';

export interface IStorageCriteriaBody {
  name: string;
  description: string;
  number: string;
  unit: IUnit[];
  maximumCapacity: number;
}

export interface IStorageCriteria extends IStorageCriteriaBody {
  id: string;
}

interface IStorageClassState {
  storageCriteriaData: IStorageCriteria[];
  storageCriteriaDataCount: number;
  storageCriteria: IStorageCriteria | null;
  isStorageCriteriaDataLoading: boolean;
  isStorageClassCriteriaFirstLoading: boolean;
  isStorageCriteriaDeleted: boolean;
  isEmptyStorageCriteriaDataList: boolean;
}

export interface IStorageCriteriaDataResponse {
  data: IStorageCriteria[];
  totalCount: number;
}

const initialState: IStorageClassState = {
  storageCriteriaData: [],
  storageCriteriaDataCount: 0,
  storageCriteria: null,
  isStorageCriteriaDataLoading: false,
  isStorageClassCriteriaFirstLoading: true,
  isStorageCriteriaDeleted: false,
  isEmptyStorageCriteriaDataList: false,
};

export const getStorageCriterias = async () => {
  try {
    return await API.storageCriteria.get.getStorageCriteria({});
  } catch (error) {
    return [];
  }
};

export const getStorageCriteriaData = createAsyncThunk(
  'storageCriteria/getStorageCriteriaData',
  async (
    {
      page,
      limit,
      search,
      sortBy,
      sortOrder,
      isFirstTime,
    }: {
      page?: number;
      limit?: number;
      search?: string;
      sortBy?: string;
      sortOrder?: 'asc' | 'desc' | '';
      isFirstTime?: boolean;
    },
    thunkApi,
  ) => {
    try {
      const storageCriteriaData = await API.storageCriteria.get.getStorageCriteria({ page, limit, search, sortBy, sortOrder });

      return { storageCriteriaData, isFirstTime };
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const getStorageCriteriaAreaData = createAsyncThunk(
  'storageCriteria/getStorageCriteriaAreaData',
  async ({ sortBy, sortOrder }: { sortBy?: string; sortOrder?: 'asc' | 'desc' | undefined }, thunkApi) => {
    try {
      const storageClassesData = await API.storageCriteria.get.getStorageCriteria({ sortBy, sortOrder });

      return storageClassesData;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const createStorageCriteria = createAsyncThunk(
  'storageCriteria/createStorageCriteria',
  async ({ data, navigate, successMessage }: { data: IStorageCriteriaBody; navigate: () => void; successMessage: string }, thunkApi) => {
    try {
      await API.storageCriteria.post.createStorageCriteria(data);

      thunkApi.dispatch(
        showNotification({
          text: successMessage,
          type: 'success',
        }),
      );

      navigate();
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const updateStorageCriteria = createAsyncThunk(
  'storageCriteria/updateStorageCriteria',
  async (
    {
      id,
      data,
      navigate,
      successMessage,
    }: {
      id: string;
      data: IStorageCriteriaBody;
      navigate: () => void;
      successMessage: string;
    },
    thunkApi,
  ) => {
    try {
      const storageClass = await API.storageCriteria.put.updateStorageCriteriaById(id, data);

      thunkApi.dispatch(
        showNotification({
          text: successMessage,
          type: 'success',
        }),
      );

      navigate();

      return storageClass;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const getStorageCriteriaById = createAsyncThunk('storageCriteria/getStorageCriteriaById', async (id: string, thunkApi) => {
  try {
    const storageClassesData = await API.storageCriteria.get.getStorageCriteriaById(id);

    return storageClassesData;
  } catch (error) {
    thunkApi.dispatch(showErrorNotification(error));
  }
});

export const deleteStorageCriteria = createAsyncThunk(
  'storageCriteria/deleteStorageCriteria',
  async ({ ids, text, navigate, hide }: { ids: string[]; text: string; navigate?: () => void; hide?: () => void }, thunkApi) => {
    try {
      await API.storageCriteria.delete.deleteStorageCriteriaById(ids);

      if (hide) {
        hide();
      }
      if (navigate) {
        navigate();
      }

      thunkApi.dispatch(setIsModalActive(false));
      thunkApi.dispatch(
        showNotification({
          text,
          type: 'success',
        }),
      );

      return ids;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

const storageCriteriaSlice = createSlice({
  name: 'storageCriteria',
  initialState,
  reducers: {
    clearStorageCriteriaData: (state) => {
      state.storageCriteriaData = [];
    },
    clearStorageCriteria: (state) => {
      state.storageCriteria = null;
    },
    setIsStorageClassCriteriaFirstLoading: (state, action: PayloadAction<boolean>) => {
      state.isStorageClassCriteriaFirstLoading = action.payload;
    },
    setIsStorageCriteriaDeleted: (state, action: PayloadAction<boolean>) => {
      state.isStorageCriteriaDeleted = action.payload;
    },
    setIsEmptyStorageCriteriaDataList: (state, action: PayloadAction<boolean>) => {
      state.isEmptyStorageCriteriaDataList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStorageCriteriaData.pending, (state) => {
      state.isStorageCriteriaDataLoading = true;
    });
    builder.addCase(getStorageCriteriaData.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageCriteriaData = [...(action.payload.storageCriteriaData as IStorageCriteriaDataResponse).data];
        state.storageCriteriaDataCount = (action.payload.storageCriteriaData as IStorageCriteriaDataResponse).totalCount;

        if (action.payload?.isFirstTime) {
          state.isEmptyStorageCriteriaDataList = (action.payload?.storageCriteriaData as IStorageCriteriaDataResponse).data.length ? false : true;
        }
      }
      state.isStorageCriteriaDataLoading = false;
      state.isStorageClassCriteriaFirstLoading = false;
    });
    builder.addCase(getStorageCriteriaData.rejected, (state) => {
      state.isStorageCriteriaDataLoading = false;
      state.isStorageClassCriteriaFirstLoading = false;
    });
    builder.addCase(createStorageCriteria.pending, (state) => {
      state.isStorageCriteriaDataLoading = true;
    });
    builder.addCase(createStorageCriteria.fulfilled, (state) => {
      state.isStorageCriteriaDataLoading = false;
    });
    builder.addCase(createStorageCriteria.rejected, (state) => {
      state.isStorageCriteriaDataLoading = false;
    });
    builder.addCase(updateStorageCriteria.pending, (state) => {
      state.isStorageCriteriaDataLoading = true;
    });
    builder.addCase(updateStorageCriteria.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageCriteria = action.payload;
      }
      state.isStorageCriteriaDataLoading = false;
    });
    builder.addCase(updateStorageCriteria.rejected, (state) => {
      state.isStorageCriteriaDataLoading = false;
    });
    builder.addCase(getStorageCriteriaById.pending, (state) => {
      state.isStorageCriteriaDataLoading = true;
    });
    builder.addCase(getStorageCriteriaById.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageCriteria = action.payload;
      }
      state.isStorageCriteriaDataLoading = false;
    });
    builder.addCase(getStorageCriteriaById.rejected, (state) => {
      state.isStorageCriteriaDataLoading = false;
    });
    builder.addCase(deleteStorageCriteria.pending, (state) => {
      state.isStorageCriteriaDataLoading = true;
    });
    builder.addCase(deleteStorageCriteria.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageCriteriaData = [...state.storageCriteriaData.filter((i) => !action.payload?.includes(i.id) && i)];
      }
      state.isStorageCriteriaDataLoading = false;
    });
    builder.addCase(deleteStorageCriteria.rejected, (state) => {
      state.isStorageCriteriaDataLoading = false;
    });
    builder.addCase(getStorageCriteriaAreaData.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageCriteriaData = [...(action.payload as IStorageCriteria[])];
      }
    });
    builder.addCase(reset, () => {
      return initialState;
    });
  },
});

export const {
  clearStorageCriteria,
  clearStorageCriteriaData,
  setIsStorageClassCriteriaFirstLoading,
  setIsStorageCriteriaDeleted,
  setIsEmptyStorageCriteriaDataList,
} = storageCriteriaSlice.actions;

export const storageCriteriaReducer = storageCriteriaSlice.reducer;
