import { IActivityProtocol } from '../../store/slices/activityProtocol.slice';
import { adminInstance, instance } from '../api';

const getActivityProtocol = ({
  limit,
  page,
  search,
  sortBy,
  sortOrder,
  endDate,
  startDate,
}: {
  page?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
  search?: string;
  startDate?: string;
  endDate?: string;
}) => {
  return adminInstance
    .get<{ data: IActivityProtocol[]; totalCount: number }>('api/v1/activity-protocol', {
      params: {
        limit,
        page,
        search,
        sortBy,
        sortOrder,
        endDate,
        startDate,
      },
    })
    .then((res) => res.data);
};

export const ActivityProtocol = {
  get: {
    getActivityProtocol,
  },
};
