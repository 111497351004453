import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { PlaceholderComponent, PulsLoader } from '../../components';
import { useNavigate } from 'react-router-dom';
import ContentComponent from './components/ContentComponent';
import { clearUnitsData, deleteUnit, getUnitsData, setIsEmptyUnitDataList, setIsUnitDataFirstLoading } from '../../store/slices/unit.slice';
import { SortDescriptor } from '@progress/kendo-data-query';
import { storageSorting } from '../../util/localStorageSorting';

const Units = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { unitsData, isUnitDataLoading, isUnitDataFirstLoading, isEmptyUnitDataList } = useAppSelector((state) => state.unit);

  const deleteHandler = (hide: () => void, ids: string[]) => {
    dispatch(deleteUnit({ ids, text: t('Units have been successfully deleted.'), hide }));
  };

  const dataHandler = ({
    sort,
    currentPage,
    inputValue,
    itemsPerPage,
    isFirstTime,
  }: {
    sort: Array<SortDescriptor>;
    itemsPerPage?: number;
    currentPage?: number;
    inputValue?: string;
    isFirstTime?: boolean;
  }) => {
    dispatch(
      getUnitsData({
        limit: itemsPerPage,
        page: currentPage,
        search: inputValue,
        sortBy: sort?.[0]?.field || '',
        sortOrder: sort?.[0]?.dir || '',
        isFirstTime,
      }),
    );
  };

  useEffect(() => {
    const data = storageSorting.get('unit');

    dataHandler({ sort: data?.sort ?? [], itemsPerPage: 25, currentPage: 1, isFirstTime: true });
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearUnitsData());
      dispatch(setIsUnitDataFirstLoading(true));
      dispatch(setIsEmptyUnitDataList(false));
    };
  }, []);

  return (
    <div>
      {isUnitDataLoading && isUnitDataFirstLoading ? (
        <PulsLoader />
      ) : !unitsData.length && isEmptyUnitDataList ? (
        <PlaceholderComponent
          btnText='Add Unit'
          btnHandler={() => {
            navigate('new-unit', {
              replace: true,
            });
          }}
          subtitle="You haven't created any Units yet. To start working, please click the 'Add Units' button."
          title='Create New Unit'
          placeholderImg={'unitNoDataIcon'}
          width='543px'
          btnWith='356px'
        />
      ) : (
        <ContentComponent dataHandler={dataHandler} deleteHandler={deleteHandler} />
      )}
    </div>
  );
};

export default Units;
