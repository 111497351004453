import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../../api/api';
import { reset } from '..';

const initialState: any = {
  userInfo: null,
};

export const getUserInfo = createAsyncThunk('user/me', async (_, thunkApi) => {
  try {
    const user = await API.user.get.getUserInfo();
    console.log(user, 'users');
    return user;
  } catch (error) {
    console.log('error', error);
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUserInfo: (state) => {
      state.userInfo = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      if (action.payload) {
        state.userInfo = action.payload;
      }
    });
    builder.addCase(reset, () => {
      return initialState;
    });
  },
});

export const { clearUserInfo } = userSlice.actions;

export const userReducer = userSlice.reducer;
