import React, { CSSProperties, FC } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.scss';
import { Button } from '@progress/kendo-react-buttons';
import { Svg } from '..';

interface ILinkNavigationComponent {
  navigate: () => void;
  listText: string;
  detailText: string;
  customStyle?: CSSProperties;
  withButton?: boolean;
  btnText?: string;
  btnHandler?: () => void;
}

export const LinkNavigationComponent: FC<ILinkNavigationComponent> = ({
  detailText,
  listText,
  navigate,
  btnHandler,
  btnText = '',
  customStyle,
  withButton = false,
}) => {
  const { t } = useTranslation();

  return (
    <div className={style.wrapper} style={{ ...customStyle }}>
      <div className={style.linkWrapper}>
        <div className={style.backWrapper} onClick={navigate}>
          <Svg icon='backIcon' className={style.img} />
          {t(listText)}
        </div>
        <div className={style.pageTitle}>
          <span>/</span> {detailText}
        </div>
      </div>
      {withButton ? (
        <Button
          type='button'
          className={style.button}
          onClick={() => {
            if (btnHandler) {
              btnHandler();
            }
          }}
        >
          {btnText}
        </Button>
      ) : null}
    </div>
  );
};
