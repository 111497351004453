import { Log, UserManager } from 'oidc-client';

import constants from '../helpers/constants';
import { userToken } from '../util/userToken';
import { reset, store } from '../store';
import { NavigateFunction } from 'react-router-dom';
import { getUserInfo } from '../store/slices/user.slice';

const settings = {
  authority: constants.stsAuthority,
  client_id: constants.clientId,
  redirect_uri: `${constants.clientRoot}signin-callback.html`,
  response_type: 'id_token token',
  scope: constants.clientScope,
  post_logout_redirect_uri: `${constants.clientRoot}login`,
};

const userManager = new UserManager(settings);

Log.logger = console;
Log.level = Log.INFO;

export const getUser = () => {
  return userManager.getUser();
};

export const login = (navigate: NavigateFunction) => {
  if (process.env.REACT_APP_ENV === 'dev') {
    userToken.set('access_token');
    store.dispatch(getUserInfo());
    navigate('/warehouse');
    return;
  }
  return userManager.signinRedirect();
};

export const renewToken = () => {
  return userManager.signinSilent();
};

export const logout = (navigate?: NavigateFunction) => {
  userToken.remove();
  store.dispatch(reset());
  if (process.env.REACT_APP_ENV === 'dev') {
    navigate && navigate('/login');
    return;
  }
  return userManager.signoutRedirect();
};
