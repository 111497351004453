import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.scss';
import { Svg } from '..';

interface IModalHeader {
  title: string;
  modalCloseHandler: () => void;
}

export const ModalHeader: FC<IModalHeader> = ({ title, modalCloseHandler }) => {
  const { t } = useTranslation();

  return (
    <div className={style.headerTextWrapper}>
      <div className={style.modalTitle}>{t(title)}</div>
      <Svg icon='closeIcon' className={style.closeIconHandler} onClick={modalCloseHandler}/>
    </div>
  );
};
