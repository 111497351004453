const basename = '/lav';

export const ROLES = {
  ADMIN: 'LAV.Admin',
  MANAGEMENT: 'LAV.Verwaltung',
  FOREMAN: 'LAV.Schichtfuehrer',
  EMPLOYEE: 'LAV.Mitarbeiter',
};

export const SUPERVISORY_ROLES = [ROLES.ADMIN, ROLES.MANAGEMENT, ROLES.FOREMAN];

export default {
  basename,
  stsAuthority: process.env.REACT_APP_STS_AUTHORITY,
  clientId: process.env.REACT_APP_CLIENT_ID,
  clientRoot: window.location.origin + basename + '/',
  clientScope: process.env.REACT_APP_CLIENT_SCOPE,
  apiRoot: process.env.REACT_APP_API_ROOT,
  unexpectedError: 'An unexpected error occurred',
  LOADING_LIST_STATUSES: {
    PLANED: 'planed',
    READY: 'ready',
    FINISHED: 'finished',
    CLOSED: 'closed',
  } as const,
  QR_CODE_TYPES: {
    ITEM: 'item',
  } as const,
};
