import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../../api/api';
import { showErrorNotification } from '../../util/showErrorNotification';
import { setIsModalActive } from './app.slice';
import { showNotification } from './notification.slice';
import { StorageAreaResponseBody } from './storageArea.slice';
import { reset } from '..';

export interface IStorageLocationBody {
  number: number;
  volume: number;
}

export interface IStorageLocation extends IStorageLocationBody {
  id: string;
  storageAreaList: StorageAreaResponseBody[];
  storageItemId: string;
}

export interface IStorageLocationInfo extends IStorageLocationBody {
  id: string;
  storageAreaList: StorageAreaResponseBody[];
  storageItem: { id: string; number: string }[];
}

export interface IStorageLocationDataResponse {
  data: IStorageLocation[];
  totalCount: number;
}

interface IStorageLocationState {
  storageLocationData: IStorageLocation[];
  storageLocationDataCount: number;
  storageLocation: IStorageLocation | null;
  isStorageLocationDataLoading: boolean;
  isStorageLocationFirstLoading: boolean;
  isEmptyStorageLocationDataList: boolean;
}

const initialState: IStorageLocationState = {
  storageLocationData: [],
  storageLocationDataCount: 0,
  storageLocation: null,
  isStorageLocationDataLoading: false,
  isStorageLocationFirstLoading: true,
  isEmptyStorageLocationDataList: false,
};

export const getStorageLocationData = createAsyncThunk(
  'storageLocation/getStorageLocationData',
  async (
    {
      page,
      limit,
      search,
      sortBy,
      sortOrder,
      isFirstTime,
    }: {
      page?: number;
      limit?: number;
      search?: string;
      sortBy?: string;
      sortOrder?: 'asc' | 'desc' | '';
      isFirstTime?: boolean;
    },
    thunkApi,
  ) => {
    try {
      const storageLocationData = await API.storageLocation.get.getStorageLocation(page, limit, search, sortBy, sortOrder);

      return { isFirstTime, storageLocationData };
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const getStorageLocationById = createAsyncThunk('storageLocation/getStorageLocationById', async (id: string, thunkApi) => {
  try {
    const storageLocation = await API.storageLocation.get.getStorageLocationById(id);

    return storageLocation;
  } catch (error) {
    thunkApi.dispatch(showErrorNotification(error));
  }
});

export const createStorageLocation = createAsyncThunk(
  'storageLocation/createStorageLocation',
  async ({ data, navigate, successMessage }: { data: IStorageLocationBody; navigate: () => void; successMessage: string }, thunkApi) => {
    try {
      await API.storageLocation.post.createStorageLocation(data);

      thunkApi.dispatch(
        showNotification({
          text: successMessage,
          type: 'success',
        }),
      );

      navigate();
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const updateStorageLocation = createAsyncThunk(
  'storageLocation/updateStorageLocation',
  async (
    {
      id,
      data,
      navigate,
      successMessage,
    }: {
      id: string;
      data: IStorageLocationBody;
      navigate: () => void;
      successMessage: string;
    },
    thunkApi,
  ) => {
    try {
      const storageLocation = await API.storageLocation.put.updateStorageLocationById(id, data);

      thunkApi.dispatch(
        showNotification({
          text: successMessage,
          type: 'success',
        }),
      );

      navigate();

      return storageLocation;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const deleteStorageLocation = createAsyncThunk(
  'storageLocation/deleteStorageLocation',
  async ({ ids, text, navigate, hide }: { ids: string[]; text: string; navigate?: () => void; hide?: () => void }, thunkApi) => {
    try {
      await API.storageLocation.delete.deleteStorageLocation(ids);

      if (hide) {
        hide();
      }
      if (navigate) {
        navigate();
      }

      thunkApi.dispatch(setIsModalActive(false));
      thunkApi.dispatch(
        showNotification({
          text,
          type: 'success',
        }),
      );

      return ids;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

const storageLocationSlice = createSlice({
  name: 'storageLocation',
  initialState,
  reducers: {
    restoreStorageLocation: (state) => {
      state.isStorageLocationFirstLoading = initialState.isStorageLocationFirstLoading;
      state.storageLocationData = initialState.storageLocationData;
      state.storageLocationDataCount = initialState.storageLocationDataCount;
      state.isEmptyStorageLocationDataList = initialState.isEmptyStorageLocationDataList;
      state.storageLocation = initialState.storageLocation;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStorageLocationData.pending, (state) => {
      state.isStorageLocationDataLoading = true;
    });
    builder.addCase(getStorageLocationData.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageLocationData = action.payload.storageLocationData.data;
        state.storageLocationDataCount = action.payload.storageLocationData.totalCount;
      }

      state.isStorageLocationDataLoading = false;
      state.isStorageLocationFirstLoading = false;

      if (action.payload?.isFirstTime) {
        state.isEmptyStorageLocationDataList = action.payload.storageLocationData.data.length ? false : true;
      }
    });
    builder.addCase(getStorageLocationData.rejected, (state) => {
      state.isStorageLocationDataLoading = false;
      state.isStorageLocationFirstLoading = false;
    });
    builder.addCase(getStorageLocationById.pending, (state) => {
      state.isStorageLocationDataLoading = true;
    });
    builder.addCase(getStorageLocationById.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageLocation = action.payload;
      }
      state.isStorageLocationDataLoading = false;
    });
    builder.addCase(getStorageLocationById.rejected, (state) => {
      state.isStorageLocationDataLoading = false;
    });
    builder.addCase(updateStorageLocation.pending, (state) => {
      state.isStorageLocationDataLoading = true;
    });
    builder.addCase(updateStorageLocation.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageLocation = action.payload;
      }
      state.isStorageLocationDataLoading = false;
    });
    builder.addCase(updateStorageLocation.rejected, (state) => {
      state.isStorageLocationDataLoading = false;
    });
    builder.addCase(deleteStorageLocation.pending, (state) => {
      state.isStorageLocationDataLoading = true;
    });
    builder.addCase(deleteStorageLocation.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageLocationData = [...state.storageLocationData.filter((i) => !action.payload?.includes(i.id) && i)];
      }
      state.isStorageLocationDataLoading = false;
    });
    builder.addCase(deleteStorageLocation.rejected, (state) => {
      state.isStorageLocationDataLoading = false;
    });
    builder.addCase(createStorageLocation.pending, (state) => {
      state.isStorageLocationDataLoading = true;
    });
    builder.addCase(createStorageLocation.fulfilled, (state) => {
      state.isStorageLocationDataLoading = false;
    });
    builder.addCase(createStorageLocation.rejected, (state) => {
      state.isStorageLocationDataLoading = false;
    });
    builder.addCase(reset, () => {
      return initialState;
    });
  },
});

export const { restoreStorageLocation } = storageLocationSlice.actions;

export const storageLocationReducer = storageLocationSlice.reducer;
