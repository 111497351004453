import React, { CSSProperties, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { changeCurrentWarehouse, IWarehouse } from '../../../../store/slices/warehouse.slice';

import style from './style.module.scss';

interface IWarehouseSelect {
  customStyle?: CSSProperties;
  customAvatarLetterColor?: string;
}

const WarehouseSelect: FC<IWarehouseSelect> = ({ customAvatarLetterColor, customStyle }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const warehouseData = useAppSelector((state) => state.warehouse.warehousesData);
  const warehouse = useAppSelector((state) => state.warehouse.warehouse);

  const onChangeWarehouse = (selectedWarehouse: IWarehouse) => {
    dispatch(changeCurrentWarehouse({ warehouseId: selectedWarehouse.id }));
  };

  return (
    <div>
      {warehouseData.length ? (
        <DropDownList
          style={{
            backgroundColor: '#fff',
            padding: '10px 12px',
            border: '1px solid #C8CED9',
            borderRadius: '6px',
            fontFamily: 'Open Sans',
            fontWeight: 400,
            fontSize: '16px',
            color: '#1D1D1B',
            width: '262px',
            display: 'flex',
            justifyContent: 'space-between',
            ...customStyle,
          }}
          data={warehouseData}
          textField='name'
          dataItemKey='id'
          value={warehouse}
          popupSettings={{ className: style.popup }}
          valueRender={(element, value) => {
            return (
              <div className={style.valueContainer}>
                <div
                  className={style.valueAvatar}
                  style={{
                    color: customAvatarLetterColor ? customAvatarLetterColor : '',
                  }}
                >
                  {value?.name?.charAt(0)}
                </div>
                <div className={style.valueName}>{value?.name}</div>
              </div>
            );
          }}
          header={<p className={style.headerContainer}>{t('All Warehouses:')}</p>}
          footer={
            <div className={style.footerContainer}>
              <div className={style.footerDivider} />
              <Button type={'button'} className={style.footerButton}>
                {t('Warehouse Administration')}
              </Button>
            </div>
          }
          itemRender={(li, itemProps) => {
            return (
              <div
                className={itemProps.selected ? style.activeListItem : style.listItem}
                onClick={() => {
                  onChangeWarehouse(itemProps.dataItem);
                }}
              >
                {itemProps.dataItem.name}
              </div>
            );
          }}
        />
      ) : null}
    </div>
  );
};

export default WarehouseSelect;
