import { ReactComponent as ActiveArrowIcon } from '../img/activeArrowIcon.svg';
import { ReactComponent as AreaNoDataIcon } from '../img/areaNoDataIcon.svg';
import { ReactComponent as Arrow } from '../img/arrow.svg';
import { ReactComponent as ArrowIcon } from '../img/arrowIcon.svg';
import { ReactComponent as AscArrow } from '../img/ascArrow.svg';
import { ReactComponent as BackHeaderArrow } from '../img/backHeaderArrow.svg';
import { ReactComponent as BackIcon } from '../img/backIcon.svg';
import { ReactComponent as BetriebIcon } from '../img/betriebIcon.svg';
import { ReactComponent as BillingIcon } from '../img/billingIcon.svg';
import { ReactComponent as BookIcon } from '../img/bookIcon.svg';
import { ReactComponent as CheckboxMinusIcon } from '../img/checkboxMinusIcon.svg';
import { ReactComponent as CheckIcon } from '../img/checkIcon.svg';
import { ReactComponent as ClassNoDataIcon } from '../img/classNoDataIcon.svg';
import { ReactComponent as CloseIcon } from '../img/closeIcon.svg';
import { ReactComponent as CommunalIcon } from '../img/communalIcon.svg';
import { ReactComponent as ConfirmationDeleteIcon } from '../img/confirmationDeleteIcon.svg';
import { ReactComponent as CriteriaNoDataIcon } from '../img/criteriaNoDataIcon.svg';
import { ReactComponent as DamageDbIcon } from '../img/damageDbIcon.svg';
import { ReactComponent as DashboardIcon } from '../img/dashboardIcon.svg';
import { ReactComponent as DeleteIcon } from '../img/deleteIcon.svg';
import { ReactComponent as DescArrow } from '../img/descArrow.svg';
import { ReactComponent as DownloadIcon } from '../img/downloadIcon.svg';
import { ReactComponent as DropdownIcon } from '../img/dropdownIcon.svg';
import { ReactComponent as DropdownIconActive } from '../img/dropdownIconActive.svg';
import { ReactComponent as EditIcon } from '../img/editIcon.svg';
import { ReactComponent as ErrorNotificationIcon } from '../img/errorNotificationIcon.svg';
import { ReactComponent as FireBrigadeReportIcon } from '../img/fireBrigadeReportIcon.svg';
import { ReactComponent as FirmaIcon } from '../img/firmaIcon.svg';
import { ReactComponent as GreenCheck } from '../img/greenCheck.svg';
import { ReactComponent as GreyEditIcon } from '../img/greyEditIcon.svg';
import { ReactComponent as KonzernIcon } from '../img/konzernIcon.svg';
import { ReactComponent as LangIcon } from '../img/langIcon.svg';
import { ReactComponent as LoadingListIcon } from '../img/loadingListIcon.svg';
import { ReactComponent as LoadingListNoDataIcon } from '../img/loadingListNoDataIcon.svg';
import { ReactComponent as LogisticIcon } from '../img/logisticIcon.svg';
import { ReactComponent as Logo } from '../img/logo.svg';
import { ReactComponent as ManufactorIcon } from '../img/manufactorIcon.svg';
import { ReactComponent as NextArrow } from '../img/nextArrow.svg';
import { ReactComponent as NextPageIcon } from '../img/nextPageIcon.svg';
import { ReactComponent as OrangePlusIcon } from '../img/orangePlusIcon.svg';
import { ReactComponent as PdfICon } from '../img/pdfICon.svg';
import { ReactComponent as PlaceholderPinIcon } from '../img/placeholderPinIcon.svg';
import { ReactComponent as PlusIcon } from '../img/plusIcon.svg';
import { ReactComponent as PrevArrow } from '../img/prevArrow.svg';
import { ReactComponent as Qr } from '../img/qr.svg';
import { ReactComponent as ReceivingAreaIcon } from '../img/receivingAreaIcon.svg';
import { ReactComponent as RedCross } from '../img/redCross.svg';
import { ReactComponent as RedDeleteIcon } from '../img/redDeleteIcon.svg';
import { ReactComponent as RemoveIcon } from '../img/removeIcon.svg';
import { ReactComponent as SearchIcon } from '../img/searchIcon.svg';
import { ReactComponent as SelectionIcon } from '../img/selectionIcon.svg';
import { ReactComponent as SignOutIcon } from '../img/signOutIcon.svg';
import { ReactComponent as SignOutIcon2 } from '../img/signOutIcon2.svg';
import { ReactComponent as StorageLocationNoDataIcon } from '../img/storageLocationNoDataIcon.svg';
import { ReactComponent as StorageSearchCalendarIcon } from '../img/storageSearchCalendarIcon.svg';
import { ReactComponent as StorageSearchIcon } from '../img/storageSearchIcon.svg';
import { ReactComponent as SuccessfulDeletionIcon } from '../img/successfulDeletionIcon.svg';
import { ReactComponent as SuccessNotificationIcon } from '../img/successNotificationIcon.svg';
import { ReactComponent as SystemIcon } from '../img/systemIcon.svg';
import { ReactComponent as TrasLogo } from '../img/trasLogo.svg';
import { ReactComponent as TrasTokanLogo } from '../img/trasTokanLogo.svg';
import { ReactComponent as UnitNoDataIcon } from '../img/unitNoDataIcon.svg';
import { ReactComponent as UpdateIcon } from '../img/updateIcon.svg';
import { ReactComponent as WarehouseIcon } from '../img/warehouseIcon.svg';
import { ReactComponent as WarehouseNoDataIcon } from '../img/warehouseNoDataIcon.svg';
import { ReactComponent as WeightIcon } from '../img/weightIcon.svg';
import { ReactComponent as SeeError } from '../img/seeError.svg';
import { ReactComponent as DetermineStorageClass } from '../img/determineStorageClass.svg';
import { ReactComponent as DeterminationCheck } from '../img/determinationCheck.svg';
import { ReactComponent as History } from '../img/history.svg';
import { ReactComponent as DeterminationError } from '../img/determinationError.svg';
import { ReactComponent as GreyDeleteIcon } from '../img/greyDeleteIcon.svg';
import { ReactComponent as SensisLogo } from '../img/sensisLogo.svg';
import { ReactComponent as notFoundIcon } from '../img/notFoundIcon.svg';
import { ReactComponent as OpenIcon } from '../img/openIcon.svg';

type IImage = ReturnType<typeof ActiveArrowIcon>;
const svgRegister = {
  activeArrowIcon: ActiveArrowIcon,
  areaNoDataIcon: AreaNoDataIcon,
  arrow: Arrow,
  arrowIcon: ArrowIcon,
  ascArrow: AscArrow,
  backHeaderArrow: BackHeaderArrow,
  backIcon: BackIcon,
  betriebIcon: BetriebIcon,
  billingIcon: BillingIcon,
  bookIcon: BookIcon,
  checkIcon: CheckIcon,
  checkboxMinusIcon: CheckboxMinusIcon,
  classNoDataIcon: ClassNoDataIcon,
  closeIcon: CloseIcon,
  communalIcon: CommunalIcon,
  confirmationDeleteIcon: ConfirmationDeleteIcon,
  criteriaNoDataIcon: CriteriaNoDataIcon,
  damageDbIcon: DamageDbIcon,
  dashboardIcon: DashboardIcon,
  deleteIcon: DeleteIcon,
  descArrow: DescArrow,
  downloadIcon: DownloadIcon,
  dropdownIcon: DropdownIcon,
  dropdownIconActive: DropdownIconActive,
  editIcon: EditIcon,
  errorNotificationIcon: ErrorNotificationIcon,
  fireBrigadeReportIcon: FireBrigadeReportIcon,
  firmaIcon: FirmaIcon,
  greenCheck: GreenCheck,
  greyEditIcon: GreyEditIcon,
  konzernIcon: KonzernIcon,
  langIcon: LangIcon,
  loadingListIcon: LoadingListIcon,
  loadingListNoDataIcon: LoadingListNoDataIcon,
  logisticIcon: LogisticIcon,
  logo: Logo,
  manufactorIcon: ManufactorIcon,
  nextArrow: NextArrow,
  nextPageIcon: NextPageIcon,
  orangePlusIcon: OrangePlusIcon,
  pdfICon: PdfICon,
  placeholderPinIcon: PlaceholderPinIcon,
  plusIcon: PlusIcon,
  prevArrow: PrevArrow,
  qr: Qr,
  receivingAreaIcon: ReceivingAreaIcon,
  redCross: RedCross,
  redDeleteIcon: RedDeleteIcon,
  removeIcon: RemoveIcon,
  searchIcon: SearchIcon,
  selectionIcon: SelectionIcon,
  signOutIcon: SignOutIcon,
  signOutIcon2: SignOutIcon2,
  storageLocationNoDataIcon: StorageLocationNoDataIcon,
  storageSearchCalendarIcon: StorageSearchCalendarIcon,
  storageSearchIcon: StorageSearchIcon,
  successNotificationIcon: SuccessNotificationIcon,
  successfulDeletionIcon: SuccessfulDeletionIcon,
  systemIcon: SystemIcon,
  trasLogo: TrasLogo,
  trasTokanLogo: TrasTokanLogo,
  unitNoDataIcon: UnitNoDataIcon,
  updateIcon: UpdateIcon,
  warehouseIcon: WarehouseIcon,
  warehouseNoDataIcon: WarehouseNoDataIcon,
  weightIcon: WeightIcon,
  seeError: SeeError,
  determineStorageClass: DetermineStorageClass,
  determinationCheck: DeterminationCheck,
  history: History,
  determinationError: DeterminationError,
  greyDeleteIcon: GreyDeleteIcon,
  sensisLogo: SensisLogo,
  notFoundIcon: notFoundIcon,
  openIcon: OpenIcon
} as unknown as { [key: string]: IImage };
export default svgRegister;
