import React, { Suspense } from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from '@progress/kendo-react-buttons';
import { useTranslation } from 'react-i18next';

import packageJson from '../../../package.json';
import trasTokanLogo from '../../img/trasTokanLogo.svg';
import signOutIcon2 from '../../img/signOutIcon2.svg';
import style from './style.module.scss';
import { ReactComponent as LangIcon } from '../../img/langIcon.svg';
import { reset, useAppDispatch, useAppSelector } from '../../store';
import { logout } from '../../services/auth';
import { sidebarConfig } from '../../util/sidebarConfig';
import { hasAccess } from '../../helpers/functions';

export const Sidebar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { warehouse } = useAppSelector((state) => state.warehouse);
  const { userInfo } = useAppSelector((state) => state.user);
  const role = userInfo?.role;

  const logoutAction = async () => {
    await logout(navigate);
    dispatch(reset);
  };

  const changeLanguageHandler = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  const isModalActive = useAppSelector((state) => state.app.client.isModalActive);

  return (
    <Suspense>
      <div
        className={style.wrapper}
        style={{
          filter: isModalActive ? 'blur(2px)' : '',
        }}
      >
        <div className={style.logoWrapper}>
          <img src={trasTokanLogo} alt='logo' />
          <div className={style.versionNumber}>{packageJson.version}</div>
        </div>
        <nav className={style.nav}>
          {!warehouse ? null : (
            <div className={style.warehouseWrapper}>
              <div
                className={style.warehouseNameWrapper}
                onClick={() => {
                  navigate(`/warehouse/${warehouse.id}/details`);
                }}
              >
                <div className={style.firstLetterWrapper}>{warehouse.name.charAt(0).toUpperCase()}</div>
                <div className={style.warehouseName}>{warehouse.name}</div>
              </div>
              <div className={style.divider}></div>
            </div>
          )}
          {sidebarConfig.map((linkGroup) => {
            if (linkGroup.roles && !hasAccess(linkGroup.roles, role)) return null;
            return (linkGroup.groupName === 'Operations' || linkGroup.groupName === 'Reports') && !warehouse ? null : (
              <div key={linkGroup.groupName} className={style.groupWrapper}>
                <div className={style.groupNameTitle}>{t(linkGroup.groupName)}</div>
                {linkGroup.linkNames.map((link) => {
                  if (link.roles && !hasAccess(link.roles, role)) return null;
                  return (
                    <NavLink
                      to={link.route}
                      key={link.title}
                      className={({ isActive }) => {
                        return isActive ? style.activeNavLink : style.navLink;
                      }}
                    >
                      {({ isActive }) => {
                        return (
                          <>
                            <link.icon style={isActive ? { color: link.activeColor } : { color: link.inactiveColor }} />
                            {t(link.title)}
                          </>
                        );
                      }}
                    </NavLink>
                  );
                })}
              </div>
            );
          })}
        </nav>
        <div className={style.footerWrapper}>
          <div className={style.langWrapper}>
            <LangIcon color='#fff' className={style.langIcon} />
            <Button
              fillMode='flat'
              onClick={() => changeLanguageHandler('de')}
              className={i18n.language === 'de' ? style.activeLangButton : style.langButton}
            >
              German
            </Button>
            <div className={style.dividerForLangButton} />
            <Button
              fillMode='flat'
              onClick={() => changeLanguageHandler('en')}
              className={i18n.language === 'en' ? style.activeLangButton : style.langButton}
            >
              English
            </Button>
          </div>
          <div className={style.signOutWrapper} onClick={logoutAction}>
            <span>{userInfo?.name}</span>
            <img src={signOutIcon2} alt='signOut' />
          </div>
        </div>
      </div>
    </Suspense>
  );
};
