import { configureStore, createAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { appReducer } from './slices/app.slice';
import { usersReducer } from './slices/users.slice';
import { userReducer } from './slices/user.slice';
import { warehouseReducer } from './slices/warehouse.slice';
import { storageClassReducer } from './slices/storageClass.slice';
import { storageCriteriaReducer } from './slices/storageCriteria.slice';
import { storageAreaReducer } from './slices/storageArea.slice';
import { unitReducer } from './slices/unit.slice';
import { notificationReducer } from './slices/notification.slice';
import { storageItemReducer } from './slices/storageItem.slice';
import { loadingListReducer } from './slices/loadingList.slice';
import { storageLocationReducer } from './slices/storageLocation.slice';
import { storageAreaBookingHistoryReducer } from './slices/storageAreaBookingHistory';
import { activityProtocolReducer } from './slices/activityProtocol.slice';

export const reset = createAction('reset');

export const store = configureStore({
  reducer: {
    app: appReducer,
    users: usersReducer,
    user: userReducer,
    warehouse: warehouseReducer,
    storageClass: storageClassReducer,
    storageCriteria: storageCriteriaReducer,
    storageLocation: storageLocationReducer,
    storageArea: storageAreaReducer,
    unit: unitReducer,
    notification: notificationReducer,
    storageItem: storageItemReducer,
    loadingList: loadingListReducer,
    storageAreaBookingHistory: storageAreaBookingHistoryReducer,
    activityProtocol: activityProtocolReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
