import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@progress/kendo-react-buttons';
import { ModalWrapper, Svg } from '../index';
import style from './style.module.scss';

interface IDeletionModal {
  closeHandler: () => void;
  deleteHandler: (cb: () => void, ids: string[]) => void;
  deleteTitle: string;
  deleteText: string;
  ids: string[];
}

const DeletionModalComponent: FC<IDeletionModal> = ({ closeHandler, deleteHandler, deleteText, deleteTitle, ids }) => {
  const { t } = useTranslation();

  return (
    <div className={style.wrapper}>
      <div
        className={style.iconWrapper}
        style={{
          marginBottom: '15.1px',
        }}
      >
        <Svg icon='confirmationDeleteIcon' width={73} height={79} />
      </div>
      <div className={style.title}>{deleteTitle}</div>
      <div className={style.deleteText}>{deleteText}</div>
      <div className={style.buttonWrapper}>
        <Button onClick={closeHandler} className={style.cancelBtn}>
          {t('Cancel')}
        </Button>
        <Button onClick={() => deleteHandler(closeHandler, ids)} className={style.deleteBtn}>
          {t('Delete')}
        </Button>
      </div>
      <Svg icon='closeIcon' className={style.closeIcon} onClick={closeHandler}/>
    </div>
  );
};

export class DeletionModal extends React.Component {
  state = {
    show: false,
    deleteHandler: () => {},
    deleteTitle: '',
    deleteText: '',
    ids: [],
  };

  show = (props: IDeletionModal) => {
    const { deleteHandler, deleteTitle, deleteText, ids } = props;
    this.setState({ show: true, deleteHandler, deleteTitle, deleteText, ids });
  };

  hide = () => {
    this.setState({ show: false, deleteHandler: () => {}, deleteTitle: '', deleteText: '', ids: [] });
  };

  render() {
    if (!this.state.show) {
      return null;
    }

    return (
      <ModalWrapper modalCloseHandler={this.hide}>
        <DeletionModalComponent {...{ closeHandler: this.hide, ...this.state }} />
      </ModalWrapper>
    );
  }
}
