import { IStorageArea, IStorageAreaInfo, IStorageAreaInfoResponse, IStorageAreaResponse, StorageAreaRequestBody } from '../../store/slices/storageArea.slice';
import { adminInstance } from '../api';

const getStorageArea = (params?: {
  page?: number;
  limit?: number;
  search?: string;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc' | '';
  storageItemId?: string;
}) => {
  return adminInstance.get<IStorageAreaResponse | IStorageArea[]>(`api/v1/storage-area`, { params }).then((res) => res.data);
};

const searchStorageArea = (search?: string, storageItemId?: string) => {
  return adminInstance
    .get<IStorageAreaResponse | IStorageArea[]>(`api/v1/storage-area/search`, { params: { search, storageItemId } })
    .then((res) => res.data);
};

const getStorageAreaById = (id: string) => {
  return adminInstance.get<IStorageArea>(`api/v1/storage-area/${id}`).then((res) => res.data);
};

const getNestedStorageAreaById = (id: string, storageItemId?: string) => {
  return adminInstance.get<IStorageArea[]>(`api/v1/storage-area/nested/${id}`, { params: { storageItemId } }).then((res) => res.data);
};

const createStorageArea = (data: StorageAreaRequestBody) => {
  return adminInstance.post<IStorageArea>(`api/v1/storage-area`, data).then((res) => res.data);
};

const updateStorageAreaById = (id: string, data: StorageAreaRequestBody) => {
  return adminInstance.put<IStorageArea>(`api/v1/storage-area/${id}`, data).then((res) => res.data);
};

const deleteStorageAreaById = (ids: string[]) => {
  return adminInstance
    .delete(
      `api/v1/storage-area?${ids.reduce((prev, i) => {
        const curr = `${prev}&ids[]=${i}`;
        return curr;
      }, '')}`,
    )
    .then((res) => res.data);
};

const updateStorageAreasCapacityById = ({ comment, storageAreaId, value }: { storageAreaId: string; comment: string; value: number }) => {
  return adminInstance.put<IStorageArea>(`api/v1/storage-area/${storageAreaId}/change-capacity`, { comment, value }).then((res) => res.data);
};

const updateStorageAreaTextFieldsById = (
  id: string,
  data: { name?: string; description?: string; contractNumbers?: string[]; isLocationRequired?: boolean },
) => {
  return adminInstance.put<IStorageArea>(`api/v1/storage-area/${id}/text-fields`, data).then((res) => res.data);
};

const getStorageAreaInfo = (params?: {
  page?: number;
  limit?: number;
  search?: string;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc' | '';
  storageItemId?: string;
}) => {
  return adminInstance.get<IStorageAreaInfoResponse>(`api/v1/storage-area/info`, { params }).then((res) => res.data);
};

const getNestedStorageAreaByIdInfo = (id: string, storageItemId?: string) => {
  return adminInstance.get<IStorageAreaInfo[]>(`api/v1/storage-area/nested/${id}/info`, { params: { storageItemId } }).then((res) => res.data);
};

const StorageArea = {
  get: {
    getStorageArea,
    searchStorageArea,
    getStorageAreaById,
    getNestedStorageAreaById,
    getStorageAreaInfo,
    getNestedStorageAreaByIdInfo,
  },
  post: {
    createStorageArea,
  },
  put: {
    updateStorageAreaById,
    updateStorageAreasCapacityById,
    updateStorageAreaTextFieldsById,
  },
  delete: {
    deleteStorageAreaById,
  },
};

export default StorageArea;
