export type IKey =
  | 'storageClass'
  | 'unit'
  | 'storageCriteria'
  | 'storageArea'
  | 'storageSearch'
  | 'storageLocation'
  | 'receivingArea'
  | 'loadingList'
  | 'brigadeReport'
  | 'bookingHistory'
  | 'storageItemDocuments'
  | 'storageAreaOperations';

export const storageSorting = {
  set: ({ data, key }: { key: IKey; data: { [key: string]: any } }) => {
    localStorage.setItem(key, JSON.stringify(data));
  },
  get: (key: IKey) => {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  },
  remove: (key: IKey) => {
    localStorage.removeItem(key);
  },
};
