import React, { useLayoutEffect, useEffect } from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import { Loader } from './components/Loader';
import { Notification, PulsLoader } from './components';
import MainContent from './content/MainContent';
import { useAppDispatch, useAppSelector } from './store';
import './App.scss';
import '@progress/kendo-theme-default/dist/all.css';
import { getUserInfo } from './store/slices/user.slice';
import { userToken } from './util/userToken';
import constants, { ROLES } from './helpers/constants';
import { RouteConfig, routerConfig } from './util/routerConfig';
import { hasAccess } from './helpers/functions';
import Login from './pages/Login';

if (!window.location.pathname.includes(constants.basename)) {
  window.history.replaceState('', '', constants.basename + window.location.pathname);
}

function App() {
  const dispatch = useAppDispatch();
  const isModalActive = useAppSelector((state) => state.app.client.isModalActive);
  const isLoading = useAppSelector((state) => state.app.client.isLoading);
  const role = useAppSelector((state) => state.user?.userInfo?.role);
  const token = userToken.get();

  useLayoutEffect(() => {
    if (isModalActive) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [isModalActive]);

  useEffect(() => {
    if (token) {
      dispatch(getUserInfo());
    }
  }, [token]);

  useEffect(() => {
    const PageHasBeenForceRefreshed = 'page-has-been-force-refreshed';

    const action = (e: any) => {
      if (/Uncaught SyntaxError: Unexpected token '<'/.test(e?.message)) {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(window.localStorage.getItem(PageHasBeenForceRefreshed) || 'false') as boolean;

        if (!pageHasAlreadyBeenForceRefreshed) {
          window.localStorage.setItem(PageHasBeenForceRefreshed, 'true');
          return window.location.reload();
        } else {
          window.localStorage.setItem(PageHasBeenForceRefreshed, 'false');
        }
      }
    };

    setTimeout(() => {
      window.localStorage.setItem(PageHasBeenForceRefreshed, 'false');
    }, 5000);

    window.addEventListener('error', action);

    return () => {
      window.removeEventListener('error', action);
    };
  }, []);

  if (token && !role?.length) {
    return <PulsLoader />;
  }

  const renderRoutes = (config: RouteConfig[], role: string[]) => {
    return config.map((route: RouteConfig) => {
      return (
        <Route
          key={route.name}
          path={route.path}
          element={route.roles ? hasAccess(route.roles, role) ? <route.element /> : <Navigate replace to={route.fallbackPath} /> : <route.element />}
        >
          {route.children && renderRoutes(route.children, role)}
        </Route>
      );
    });
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path='*' element={<MainContent />}>
          {renderRoutes(routerConfig, role)}
          <Route
            path='*'
            element={
              hasAccess([ROLES.ADMIN, ROLES.MANAGEMENT], role) ? <Navigate replace to='warehouse' /> : <Navigate replace to='receiving-area' />
            }
          />
        </Route>
        <Route path='login' element={<Login />} />
      </Route>,
    ),
    { basename: constants.basename },
  );

  return (
    <div className='App'>
      <RouterProvider router={router} />
      {isLoading ? <Loader /> : null}
      <Notification />
    </div>
  );
}

export default App;
