import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalHeader, Input, DropDown } from '../../../../components';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import style from './style.module.scss';
import { useAppSelector } from '../../../../store';
import { Button } from '@progress/kendo-react-buttons';
import { IStorageBody } from '../../../../store/slices/storageArea.slice';
import { ComponentTypeWithoutLoadingList } from '../StorageSettingsTable';

interface IModal {
  type: ComponentTypeWithoutLoadingList;
  closeModalHandler: () => void;
  classesAndCriteriaAddHandler: (type: ComponentTypeWithoutLoadingList, data: IStorageBody) => void;
  classesAndCriteriaUpdateHandler: (type: ComponentTypeWithoutLoadingList, data: IStorageBody) => void;
  selectedItem?: IStorageBody;
  selectedItems: string[];
}

const Modal: FC<IModal> = ({
  closeModalHandler,
  classesAndCriteriaAddHandler,
  type,
  selectedItem,
  classesAndCriteriaUpdateHandler,
  selectedItems,
}) => {
  const { t } = useTranslation();
  const classes = useAppSelector((state) => state.storageClass.storageClassesData);
  const criteria = useAppSelector((state) => state.storageCriteria.storageCriteriaData);

  const [name, setName] = useState<{ id: string; text: string; number: string; name: string } | null>();
  const [maximumCapacity, setMaximumCapacity] = useState('');

  useEffect(() => {
    if (selectedItem) {
      if (type === 'class') {
        const item = classes?.find((el) => el.id === selectedItem.storage);
        if (item) {
          setName({
            id: item.id,
            text: item.name,
            name: item.name,
            number: item.number,
          });
        }
      } else {
        const item = criteria?.find((el) => el.id === selectedItem.storage);
        if (item) {
          setName({
            id: item.id,
            text: item.name,
            name: item.name,
            number: item.number,
          });
        }
      }
      setMaximumCapacity(selectedItem.maximumCapacity.toString());
    }
  }, []);

  if (selectedItem && !name) {
    return null;
  }

  return (
    <div>
      <ModalHeader modalCloseHandler={closeModalHandler} title={type === 'class' ? 'Add Storage Class' : 'Add Storage Criterias'} />
      <Form
        initialValues={{
          name: name,
          maxCapacity: maximumCapacity,
        }}
        onSubmitClick={({ values }) => {
          if (selectedItem) {
            classesAndCriteriaUpdateHandler(type, {
              storage: values.name.id,
              maximumCapacity: values.maxCapacity,
              isAllowedToRemove: selectedItem.isAllowedToRemove,
              name: values.name.name,
              number: values.name.number,
            });
          } else {
            classesAndCriteriaAddHandler(type, {
              storage: values.name.id,
              maximumCapacity: values.maxCapacity,
              isAllowedToRemove: true,
              name: values.name.name,
              number: values.name.number,
            });
          }

          closeModalHandler();
        }}
        render={(props) => {
          const isClass = type === 'class';
          const name = isClass ? 'Storage Class Number' : 'Storage Criterias Number';
          const mapper = (i: any) => ({ text: i.number, id: i.id, name: i.name, number: i.number });
          const data = isClass
            ? classes?.filter((el) => !selectedItems.includes(el.id)).map(mapper)
            : criteria?.filter((el) => !selectedItems.includes(el.id)).map(mapper);
          const isDisabled = !props.valueGetter('maxCapacity') || !props.valueGetter('name')?.id;

          return (
            <FormElement>
              <fieldset
                style={{
                  border: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    padding: '0 20px',
                  }}
                >
                  <div
                    style={{
                      marginBottom: '20px',
                    }}
                  >
                    <div className={style.fieldTitle}>{t(name)}</div>
                    <Field
                      name='name'
                      component={DropDown}
                      customProp={{
                        placeholder: `${t('Select')} ${t(name)}`,
                        data,
                        textField: 'text',
                        dataItemKey: 'id',
                      }}
                    />
                  </div>
                  <div
                    style={{
                      marginBottom: '20px',
                    }}
                  >
                    <div className={style.fieldTitle}>{t('Maximum capacity')}</div>
                    <Field
                      name='maxCapacity'
                      type='number'
                      component={Input}
                      customProp={{
                        placeholder: t('Enter'),
                      }}
                    />
                  </div>
                </div>
                <div className={style.btnWrapper}>
                  <Button
                    type={'button'}
                    onClick={() => {
                      closeModalHandler();
                      props.onFormReset();
                    }}
                    className={style.cancelBtn}
                  >
                    {t('Abbrechen')}
                  </Button>
                  <Button type='submit' disabled={isDisabled} className={style.createBtn}>
                    {`${isClass ? t('Add Storage Class') : t('Add Storage Criterias')}`}
                  </Button>
                </div>
              </fieldset>
            </FormElement>
          );
        }}
      />
    </div>
  );
};

export default Modal;
