import React, { FC, useEffect } from 'react';
import { Notification as KendoNotification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { useAppDispatch, useAppSelector } from '../../store';
import { hideNotification } from '../../store/slices/notification.slice';
import style from './style.module.scss';

const notificationClasses = {
  success: style.successNotification,
  error: style.errorNotification,
  none: style.noneNotification,
  warning: style.warningNotification,
  info: style.infoNotification,
};

export const Notification: FC = () => {
  const dispatch = useAppDispatch();
  const { isOpen, type, text } = useAppSelector((state) => state.notification);

  const onCloseNotification = () => {
    dispatch(hideNotification());
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isOpen) {
      timer = setTimeout(() => {
        dispatch(hideNotification());
      }, 10000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isOpen]);

  return (
    <NotificationGroup style={{ top: 0, left: '57%', transform: 'translateX(-50%)' }}>
      <Fade>
        {isOpen && type && text && (
          <KendoNotification type={{ style: type, icon: true }} closable={true} onClose={onCloseNotification} className={notificationClasses[type]}>
            <span>{text}</span>
          </KendoNotification>
        )}
      </Fade>
    </NotificationGroup>
  );
};
