import React, { useEffect } from 'react';
import style from './style.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentWrapper, LinkNavigationComponent, PulsLoader, TableGrid } from '../../components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { clearStorageAreaBookingHistory, getStorageAreaBookingHistory } from '../../store/slices/storageAreaBookingHistory';
import { SortDescriptor } from '@progress/kendo-data-query';
import { format } from 'date-fns';
import { clearStorageArea, getStorageAreaById } from '../../store/slices/storageArea.slice';
import { GridCellProps } from '@progress/kendo-react-grid';

const StorageAreaBookingHistoryOperations = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { areaId } = useParams();
  const navigate = useNavigate();
  const { storageAreaBookingHistory, storageAreaBookingHistoryTotalCount, isFirstTimeStorageAreaBookingHistoryLoading } = useAppSelector(
    (state) => state.storageAreaBookingHistory,
  );
  const { storageArea, isStorageAreaDataLoading } = useAppSelector((state) => state.storageArea);

  const dataHandler = ({
    sort,
    currentPage,
    itemsPerPage,
    isFirstTime,
  }: {
    sort?: Array<SortDescriptor>;
    itemsPerPage?: number;
    currentPage?: number;
    isFirstTime?: boolean;
  }) => {
    dispatch(
      getStorageAreaBookingHistory({
        limit: itemsPerPage,
        page: currentPage,
        sortBy: sort?.[0]?.field || '',
        sortOrder: sort?.[0]?.dir || '',
        storageArea: areaId,
        isFirstTime,
      }),
    );
  };

  useEffect(() => {
    if (areaId) {
      Promise.all([
        dispatch(getStorageAreaById(areaId)),
        dataHandler({
          currentPage: 1,
          itemsPerPage: 25,
          isFirstTime: true,
        }),
      ]);
    }

    return () => {
      dispatch(clearStorageArea());
      dispatch(clearStorageAreaBookingHistory());
    };
  }, []);

  useEffect(() => {
    if (storageArea && !storageArea?.storageAreaIsDissolveType) {
      navigate(`/storage-area`);
    }
  }, [storageArea]);

  return (
    <div>
      <ContentWrapper
        style={{
          height: '100vh',
        }}
      >
        <LinkNavigationComponent
          navigate={() => {
            navigate(`/storage-area`, {
              replace: false,
            });
          }}
          listText='Storage Area List'
          detailText={`${t('Booking History')} - ${storageArea?.name}`}
          customStyle={{
            marginBottom: '20px',
            position: 'relative',
            zIndex: 2,
          }}
        />
        <div
          style={{
            background: '#ffffff',
            border: '1px solid rgba(200, 206, 217, 0.5)',
            borderRadius: '16px',
            padding: '24px 20px',
            height: 'calc(100% - 52px)',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          <div className={style.infoWrapper}>
            <div>
              <span className={style.currentQuantityText}>{t('Current Quantity')}: </span>
              <span className={style.quantityText}>
                {storageArea ? Number((storageArea?.maximumCapacity - storageArea?.freeCapacitySpace).toFixed(3)) : ''} t
              </span>
            </div>
          </div>
          <div>
            {isFirstTimeStorageAreaBookingHistoryLoading && isStorageAreaDataLoading ? (
              <PulsLoader />
            ) : (
              <TableGrid
                data={storageAreaBookingHistory.map((el) => {
                  return {
                    ...el,
                    date: format(new Date(el.date), 'dd.MM.yyyy HH:mm'),
                    quantityAndUnit: el.storageItem
                      ? `${el.storageItem.quantity} ${el.storageItem.unit}`
                      : el.quantity && el.unit
                      ? `${el.quantity} ${el.unit?.toLowerCase()}`
                      : '',
                    storageObject: el.storageItem ? el.storageItem.number : '-',
                    bookingType: t(el.bookingType),
                  };
                })}
                items={[
                  {
                    title: 'Booking Type',
                    field: 'bookingType',
                    width: '160px',
                  },
                  {
                    title: 'Quantity & Unit',
                    field: 'quantityAndUnit',
                    width: '180px',
                    cell: (props: GridCellProps) => {
                      return (
                        <td
                          className={`k-table-td ${style.item} ${
                            props.dataItem.bookingType === 'In' || props.dataItem.bookingType === 'Eingang' ? style.in : style.out
                          }`}
                        >
                          {props.dataItem.quantityAndUnit}
                        </td>
                      );
                    },
                  },
                  {
                    title: 'Storage Object',
                    field: 'storageObject',
                    width: '185px',
                  },
                  {
                    title: 'Date&Time',
                    field: 'date',
                    width: '180px',
                  },
                  {
                    title: 'User',
                    field: 'user',
                    width: '120px',
                  },
                  {
                    title: 'Waste No.',
                    field: 'storageItem.wasteNumber',
                  },
                  {
                    title: 'Booking Comment',
                    field: 'bookingComment',
                  },
                ]}
                customFirstItem={null}
                customLastItem={null}
                customTableHeight={'calc(100vh - 247px)'}
                dataHandler={dataHandler}
                sortingKey='bookingHistory'
                saveSorting
                totalCount={storageAreaBookingHistoryTotalCount}
              />
            )}
          </div>
        </div>
      </ContentWrapper>
    </div>
  );
};

export default StorageAreaBookingHistoryOperations;
