import { IWarehouse, IWarehouseBody } from '../../store/slices/warehouse.slice';
import { adminInstance } from '../api';

const getWarehouseData = () => {
  return adminInstance
    .get<IWarehouse[]>(`api/v1/warehouse`)
    .then((res) => res.data);
};

const createWarehouse = (data: IWarehouseBody) => {
  return adminInstance.post<IWarehouse>(`api/v1/warehouse`, data).then((res) => res.data);
};

const getWarehouseById = (id: string) => {
  return adminInstance.get<IWarehouse>(`api/v1/warehouse/${id}`).then((res) => res.data);
};

const updateWarehouseById = (id: string, data: IWarehouseBody) => {
  return adminInstance.put<IWarehouse>(`api/v1/warehouse/${id}`, data).then((res) => res.data);
};

const deleteWarehouseById = (id: string) => {
  return adminInstance.delete(`api/v1/warehouse/${id}`).then((res) => res.data);
};

const Warehouse = {
  get: {
    getWarehouseData,
    getWarehouseById,
  },
  post: {
    createWarehouse,
  },
  put: {
    updateWarehouseById,
  },
  delete: {
    deleteWarehouseById,
  },
};

export default Warehouse;
