import { AxiosError } from 'axios';
import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../api/api';
import { Loader } from '../components/Loader';
import { userToken } from '../util/userToken';

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const navigate = useNavigate();
  const [isLoaderActive, setIsLoaderActive] = useState(true);
  const token = userToken.get();

  useEffect(() => {
    if(!token) {
      navigate('/login')
    }
    setIsLoaderActive(false);
  }, []);

  if (isLoaderActive) {
    return <Loader />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
