import React, { FC } from 'react';
import { useAppSelector, useAppDispatch } from '../../../../store';
import { useNavigate } from 'react-router-dom';
import { GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { IStorageArea, getNestedStorageAreaById } from '../../../../store/slices/storageArea.slice';
import TableComponent from '../TableComponent';
import DetailButton from '../../../../components/DetailButton';
import style from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { Svg } from '../../../../components';

interface IContentComponent {
  selectedItems: string[];
  selectItemHandler: (datatIem: IStorageArea) => void;
  allItemsSelected: boolean;
  allItemsSelectHandler: () => void;
  dataHandler: (data: { sort: Array<SortDescriptor>; itemsPerPage?: number; currentPage?: number; inputValue?: string }) => void;
  deleteHandler: (hide: () => void, ids: string[]) => void;
}

const ContentComponent: FC<IContentComponent> = ({
  allItemsSelectHandler,
  allItemsSelected,
  dataHandler,
  deleteHandler,
  selectItemHandler,
  selectedItems,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const storageAreaData = useAppSelector((state) => state.storageArea.storageAreaData);
  const navigate = useNavigate();

  const expandChange = async (props: GridCellProps) => {
    dispatch(
      getNestedStorageAreaById({
        expanded: !props.dataItem.expanded,
        id: props.dataItem.id,
      }),
    );
  };

  const LastItem = (props: GridCellProps & { onArrowsClick: () => void; onHistoryClick: () => void }) => {
    return (
      <td
        className={`k-table-td ${style.linkCell}`}
        style={{
          ...props.style,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '10px',
          }}
        >
          {props.dataItem.storageAreaIsDissolveType ? (
            <div className={style.historyBtn} onClick={props.onHistoryClick}>
              <Svg
                icon='history'
                style={{
                  width: '16px',
                  height: '16px',
                  marginRight: '8px',
                }}
              />
              {t('History')}
            </div>
          ) : null}
          <DetailButton actionHandle={props.onArrowsClick} />
        </div>
      </td>
    );
  };

  return (
    <div>
      <TableComponent
        selectedItems={selectedItems}
        selectItemHandler={selectItemHandler}
        allItemsSelectHandler={allItemsSelectHandler}
        allItemsSelected={allItemsSelected}
        expandChange={expandChange}
        dataItemList={storageAreaData}
        dataHandler={dataHandler}
        addButtonText='Add Storage Area'
        navigate={() => {
          navigate('new-area', {
            replace: false,
          });
        }}
        customLastItem={
          <GridColumn
            cell={(props: GridCellProps) => (
              <LastItem
                {...props}
                onArrowsClick={() => {
                  navigate(props.dataItem.id);
                }}
                onHistoryClick={() => {
                  navigate(`${props.dataItem.id}/booking-history`);
                }}
              />
            )}
            width='160px'
          />
        }
        withHeaderSearch={true}
        deleteHandler={deleteHandler}
        isNested={false}
      />
    </div>
  );
};

export default ContentComponent;
