import { IStorageClass, IStorageClassBody, IStorageClassDataResponse } from '../../store/slices/storageClass.slice';
import { adminInstance } from '../api';

const getStorageClasses = ({
  limit,
  page,
  search,
  sortBy,
  sortOrder,
}: {
  page?: number;
  limit?: number;
  search?: string;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc' | '';
}) => {
  return adminInstance
    .get<IStorageClassDataResponse | IStorageClass[]>(`api/v1/storage-class`, {
      params: {
        page,
        limit,
        search,
        sortBy,
        sortOrder,
      },
    })
    .then((res) => res.data);
};
const getStorageClassById = (id: string) => {
  return adminInstance.get<IStorageClass>(`api/v1/storage-class/${id}`).then((res) => res.data);
};

const createStorageClass = (data: IStorageClassBody) => {
  return adminInstance.post<IStorageClass>(`api/v1/storage-class`, data).then((res) => res.data);
};

const updateStorageClassById = (id: string, data: IStorageClassBody) => {
  return adminInstance.put<IStorageClass>(`api/v1/storage-class/${id}`, data).then((res) => res.data);
};

const deleteStorageClassById = (ids: string[]) => {
  return adminInstance
    .delete<IStorageClass>(`api/v1/storage-class`, {
      params: {
        ids,
      },
    })
    .then((res) => res.data);
};

const StorageClasses = {
  get: {
    getStorageClasses,
    getStorageClassById,
  },
  post: {
    createStorageClass,
  },
  put: {
    updateStorageClassById,
  },
  delete: {
    deleteStorageClassById,
  },
};

export default StorageClasses;
