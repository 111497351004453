import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.scss';
import { Button } from '@progress/kendo-react-buttons';
import { Svg } from '..';

interface IPlaceholderComponent {
  title: string;
  subtitle: string;
  btnText: string;
  btnHandler: () => void;
  placeholderImg: string;
  width: string;
  btnWith: string;
}

export const PlaceholderComponent: FC<IPlaceholderComponent> = ({ btnText, btnHandler, subtitle, title, placeholderImg, width, btnWith }) => {
  const { t } = useTranslation();

  return (
    <div
      className={style.wrapper}
      style={{
        width: width,
      }}
    >
      <div className={style.img}>
        <Svg icon={placeholderImg} />
      </div>
      <div className={style.createWarehouseText}>{t(title)}</div>
      <div className={style.createWarehouseSubtitleText}>{t(subtitle)}</div>
      <div className={style.btnWrapper}>
        <Button
          onClick={btnHandler}
          className={style.button}
          style={{
            width: btnWith,
          }}
        >
          <Svg icon='plusIcon' />
          {t(btnText)}
        </Button>
      </div>
    </div>
  );
};
