import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { reset } from '..';

export interface IAppState {
  client: {
    isLoading: boolean;
    isModalActive: boolean;
  };
}

const initialState: IAppState = {
  client: {
    isLoading: false,
    isModalActive: false,
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.client.isLoading = action.payload;
    },
    setIsModalActive: (state, action: PayloadAction<boolean>) => {
      state.client.isModalActive = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => {
      return initialState;
    });
  },
});

export const { setIsLoading, setIsModalActive } = appSlice.actions;

export const appReducer = appSlice.reducer;
