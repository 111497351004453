import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { useNavigate } from 'react-router-dom';
import {
  clearStorageAreaData,
  deleteStorageArea,
  getStorageAreaData,
  searchStorageAreaData,
  IStorageArea,
  setIsStorageAreaDataFirstLoading,
  setIsEmptyStorageAreaDataList,
} from '../../store/slices/storageArea.slice';
import { PlaceholderComponent, PulsLoader } from '../../components';
import ContentComponent from './components/ContentComponent';
import { SortDescriptor } from '@progress/kendo-data-query';
import { getStorageClassesAreaData } from '../../store/slices/storageClass.slice';
import { storageSorting } from '../../util/localStorageSorting';

const StorageAreas = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isEmptyStorageAreaDataList, storageAreaData, isStorageAreaDataLoading, isStorageAreaDataFirstLoading } = useAppSelector(
    (state) => state.storageArea,
  );
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [allItemsSelected, setAllItemsSelected] = useState(false);

  const getAllNestedIds = (storageAreaList: IStorageArea[], resultIdList: string[]) => {
    storageAreaList.forEach((currentStorageArea) => {
      if (currentStorageArea.canBeChanged) {
        resultIdList.push(currentStorageArea.id);
      }

      if (currentStorageArea.nestedList) {
        getAllNestedIds(currentStorageArea.nestedList, resultIdList);
      }
    });
    return resultIdList;
  };

  const getStorageAreaDataById = () => {
    const storageAreaDataById: { [id: string]: IStorageArea } = {};
    const transformStorageAreaData = (data: IStorageArea[]) => {
      data.forEach((currentStorageAreaData) => {
        storageAreaDataById[currentStorageAreaData.id] = currentStorageAreaData;
        if (currentStorageAreaData.nestedList) {
          transformStorageAreaData(currentStorageAreaData.nestedList);
        }
      });
    };
    transformStorageAreaData(storageAreaData);
    return storageAreaDataById;
  };

  const allItemsSelectHandler = () => {
    if (!allItemsSelected) {
      setAllItemsSelected(true);
      let ids: string[] = [];
      ids = getAllNestedIds(storageAreaData, ids);
      setSelectedItems(ids);
      return;
    }
    if (allItemsSelected) {
      setAllItemsSelected(false);
      setSelectedItems([]);
      return;
    }
  };

  const selectItemHandler = (dataItem: IStorageArea) => {
    const storageAreaDataById = getStorageAreaDataById();
    if (selectedItems.includes(dataItem.id)) {
      const newSelectedItems = selectedItems.filter((i) => i !== dataItem.id);
      setSelectedItems(newSelectedItems);
      setAllItemsSelected(Object.keys(storageAreaDataById).length === newSelectedItems.length);
    }
    if (!selectedItems.includes(dataItem.id)) {
      let ids: string[] = [];
      if (dataItem.nestedList) {
        ids = getAllNestedIds(dataItem.nestedList, ids);
      }
      const uniqNewSelectedItems = new Set([...selectedItems, ...ids, dataItem.id]);
      const newSelectedItems = Array.from(uniqNewSelectedItems);
      setSelectedItems(newSelectedItems);
      setAllItemsSelected(Object.keys(storageAreaDataById).length === newSelectedItems.length);
    }
  };

  const deleteHandler = (hide: () => void, ids: string[]) => {
    dispatch(
      deleteStorageArea({
        ids,
        text: t('Storage areas have been successfully deleted.'),
        hide,
      }),
    );
    setSelectedItems([]);
  };

  const dataHandler = ({
    sort,
    currentPage,
    inputValue,
    isFirstTime,
    itemsPerPage,
  }: {
    sort: Array<SortDescriptor>;
    itemsPerPage?: number;
    currentPage?: number;
    inputValue?: string;
    isFirstTime?: boolean;
  }) => {
    if (inputValue) {
      dispatch(searchStorageAreaData({ search: inputValue }));
    } else {
      dispatch(
        getStorageAreaData({
          limit: itemsPerPage,
          page: currentPage,
          search: inputValue,
          sortBy: sort?.[0]?.field || '',
          sortOrder: sort?.[0]?.dir || '',
          isFirstTime,
        }),
      );
    }
  };

  useEffect(() => {
    const data = storageSorting.get('storageArea');

    dataHandler({ sort: data?.sort ?? [], itemsPerPage: 25, currentPage: 1, isFirstTime: true });

    return () => {
      dispatch(clearStorageAreaData());
    };
  }, []);

  useEffect(() => {
    if (selectedItems.length) {
      const storageAreaDataById = getStorageAreaDataById();
      const selectedStorageArea = selectedItems.flatMap((id) => {
        if (storageAreaDataById[id]?.nestedList) {
          return { nestedList: storageAreaDataById[id].nestedList };
        }
        return [];
      });

      let ids: string[] = [];
      selectedStorageArea.forEach(({ nestedList }) => {
        if (nestedList) {
          ids = getAllNestedIds(nestedList, ids);
        }
      });

      const filteredItems = [...selectedItems, ...ids].filter((id) => Object.keys(storageAreaDataById).includes(id));
      const uniqNewSelectedItems = new Set(filteredItems);
      const newSelectedItems = Array.from(uniqNewSelectedItems);
      setSelectedItems(newSelectedItems);
      setAllItemsSelected(Object.keys(storageAreaDataById).length === newSelectedItems.length);
    }
  }, [storageAreaData]);

  useEffect(() => {
    return () => {
      dispatch(clearStorageAreaData());
      dispatch(setIsStorageAreaDataFirstLoading(true));
      dispatch(setIsEmptyStorageAreaDataList(false));
    };
  }, []);

  return (
    <div>
      {isStorageAreaDataLoading && isStorageAreaDataFirstLoading ? (
        <PulsLoader />
      ) : !storageAreaData.length && isEmptyStorageAreaDataList ? (
        <PlaceholderComponent
          btnText='Add Storage Area'
          btnHandler={() => {
            navigate('new-area', {
              replace: true,
            });
          }}
          subtitle="You haven't created any Storage Area yet. To start working, please click the 'Add Storage Area' button."
          title='Create Storage Area'
          placeholderImg={'areaNoDataIcon'}
          width='543px'
          btnWith='356px'
        />
      ) : (
        <ContentComponent
          allItemsSelectHandler={allItemsSelectHandler}
          allItemsSelected={allItemsSelected}
          selectItemHandler={selectItemHandler}
          selectedItems={selectedItems}
          dataHandler={dataHandler}
          deleteHandler={deleteHandler}
        />
      )}
    </div>
  );
};

export default StorageAreas;
