import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { showErrorNotification } from '../../util/showErrorNotification';
import API from '../../api/api';
import { showNotification } from './notification.slice';
import { IStorageItem } from './storageItem.slice';
import { setIsModalActive } from './app.slice';
import constants from '../../helpers/constants';
import { reset } from '..';

export type StatusesType = (typeof constants.LOADING_LIST_STATUSES)[keyof typeof constants.LOADING_LIST_STATUSES];

export interface ILoadingListItemRequestBody {
  name: string;
  dateExecution: string;
  targetFacility: string;
  description?: string;
  itemList: string[];
}

export interface ILoadingListItemResponseBody {
  id: string;
  name: string;
  status: string;
  dateExecution: string;
  targetFacility: string;
  description?: string;
  itemList: IStorageItem[];
  loadedItemList: string[];
  number: number;
}

export interface ILoadingListResponse {
  data: ILoadingListItemResponseBody[];
  totalCount: number;
}

export interface ILoadingListItem {
  id: string;
  name: string;
  dateExecution: string;
  targetFacility: string;
  description?: string;
  status: string;
  itemList: IStorageItem[];
  loadedItemList: string[];
  number: number;
}

interface ILoadingListState {
  isDataLoading: boolean;
  item: ILoadingListItem | null;
  itemsDataCount: number;
  itemsData: ILoadingListItem[];
  isFirstTimeDataLoading: boolean;
  isEmptyDataList: boolean;
  isStatusLoading: boolean;
  isButtonLoading: boolean;
}

const initialState: ILoadingListState = {
  isDataLoading: false,
  item: null,
  itemsDataCount: 0,
  itemsData: [],
  isFirstTimeDataLoading: true,
  isEmptyDataList: false,
  isStatusLoading: false,
  isButtonLoading: false,
};

export const getLoadingList = createAsyncThunk(
  'loadingList/getLoadingList',
  async (
    {
      page,
      limit,
      search,
      sortBy,
      sortOrder,
      isFirstTime,
      statuses,
    }: {
      page?: number;
      limit?: number;
      search?: string;
      sortBy?: string;
      sortOrder?: 'asc' | 'desc' | '';
      isFirstTime?: boolean;
      statuses?: string[];
    },
    thunkApi,
  ) => {
    try {
      const loadingListData = await API.loadingList.get.getLoadingList({
        page,
        limit,
        search,
        sortBy,
        sortOrder,
        statuses,
      });

      return { loadingListData, isFirstTime };
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const getLoadingListById = createAsyncThunk('loadingList/getLoadingListById', async (id: string, thunkApi) => {
  try {
    const loadingList = await API.loadingList.get.getLoadingListById(id);

    return loadingList;
  } catch (error) {
    thunkApi.dispatch(showErrorNotification(error));
  }
});

export const createLoadingList = createAsyncThunk(
  'loadingList/createLoadingList',
  async ({ data, navigate, successMessage }: { data: ILoadingListItemRequestBody; navigate: () => void; successMessage: string }, thunkApi) => {
    try {
      await API.loadingList.post.createLoadingList(data);

      thunkApi.dispatch(
        showNotification({
          text: successMessage,
          type: 'success',
        }),
      );

      navigate();
    } catch (error) {
      console.log(error);
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const updateLoadingList = createAsyncThunk(
  'loadingList/updateLoadingList',
  async (
    {
      id,
      data,
      navigate,
      successMessage,
    }: {
      id: string;
      data: ILoadingListItemRequestBody;
      navigate?: () => void;
      successMessage: string;
    },
    thunkApi,
  ) => {
    try {
      const loadingList = await API.loadingList.put.updateLoadingListById(id, data);

      thunkApi.dispatch(
        showNotification({
          text: successMessage,
          type: 'success',
        }),
      );

      if (navigate) navigate();

      return loadingList;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const deleteLoadingList = createAsyncThunk(
  'loadingList/deleteLoadingList',
  async ({ ids, text, navigate, hide }: { ids: string[]; text: string; navigate?: () => void; hide?: () => void }, thunkApi) => {
    try {
      await API.loadingList.delete.deleteLoadingList(ids);

      if (hide) {
        hide();
      }

      if (navigate) {
        navigate();
      }

      thunkApi.dispatch(setIsModalActive(false));
      thunkApi.dispatch(
        showNotification({
          text,
          type: 'success',
        }),
      );
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const updateLoadingListStatus = createAsyncThunk(
  'loadingList/updateLoadingListStatus',
  async (
    { id, data, navigate, successMessage }: { id: string; data: { status: StatusesType }; navigate?: () => void; successMessage: string },
    thunkApi,
  ) => {
    try {
      const loadingList = await API.loadingList.put.updateLoadingListStatus(id, data);

      thunkApi.dispatch(
        showNotification({
          text: successMessage,
          type: 'success',
        }),
      );

      if (navigate) navigate();

      return loadingList;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const loadItemInLoadingList = createAsyncThunk(
  'loadingList/loadItemInLoadingList',
  async (
    { id, data, navigate, successMessage }: { id: string; data: { loadedItemList: string[] }; navigate?: () => void; successMessage: string },
    thunkApi,
  ) => {
    try {
      const loadingList = await API.loadingList.put.loadItemList(id, data);

      thunkApi.dispatch(
        showNotification({
          text: successMessage,
          type: 'success',
        }),
      );

      if (navigate) navigate();

      return loadingList;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

const loadingListSlice = createSlice({
  name: 'loadingList',
  initialState,
  reducers: {
    resetLoadingListLoadings: (state) => {
      state.isFirstTimeDataLoading = initialState.isFirstTimeDataLoading;
      state.isDataLoading = initialState.isDataLoading;
      state.isEmptyDataList = initialState.isEmptyDataList;
    },
    resetLoadingListItem: (state) => {
      state.item = initialState.item;
      state.isDataLoading = initialState.isDataLoading;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLoadingList.pending, (state) => {
      state.isDataLoading = true;
    });
    builder.addCase(getLoadingList.fulfilled, (state, action) => {
      if (action.payload) {
        state.itemsData = action.payload.loadingListData.data;
        state.itemsDataCount = action.payload.loadingListData.totalCount;
      }
      state.isDataLoading = false;
      state.isFirstTimeDataLoading = false;

      if (action.payload?.isFirstTime) {
        state.isEmptyDataList = action.payload.loadingListData.data.length ? false : true;
      }
    });
    builder.addCase(getLoadingList.rejected, (state) => {
      state.isDataLoading = false;
      state.isFirstTimeDataLoading = false;
    });
    builder.addCase(getLoadingListById.pending, (state) => {
      state.isDataLoading = true;
    });
    builder.addCase(getLoadingListById.fulfilled, (state, action) => {
      if (action.payload) {
        state.item = action.payload;
      }
      state.isDataLoading = false;
    });
    builder.addCase(getLoadingListById.rejected, (state) => {
      state.isDataLoading = false;
    });
    builder.addCase(createLoadingList.pending, (state) => {
      state.isButtonLoading = true;
    });
    builder.addCase(createLoadingList.fulfilled, (state) => {
      state.isButtonLoading = false;
    });
    builder.addCase(createLoadingList.rejected, (state) => {
      state.isButtonLoading = false;
    });
    builder.addCase(updateLoadingList.pending, (state) => {
      state.isButtonLoading = true;
    });
    builder.addCase(updateLoadingList.fulfilled, (state, action) => {
      if (action.payload) {
        state.itemsData = state.itemsData.map((currentItem) => {
          if (currentItem.id === action.payload?.id) {
            return action.payload;
          }
          return currentItem;
        });
        state.item = action.payload;
      }
      state.isButtonLoading = false;
    }),
      builder.addCase(updateLoadingList.rejected, (state) => {
        state.isButtonLoading = false;
      });
    builder.addCase(updateLoadingListStatus.pending, (state) => {
      state.isStatusLoading = true;
    });
    builder.addCase(updateLoadingListStatus.fulfilled, (state, action) => {
      if (action.payload) {
        state.itemsData = state.itemsData.map((currentItem) => {
          if (currentItem.id === action.payload?.id) {
            return action.payload;
          }
          return currentItem;
        });
        state.item = action.payload;
      }
      state.isStatusLoading = false;
    });
    builder.addCase(updateLoadingListStatus.rejected, (state) => {
      state.isStatusLoading = false;
    });
    builder.addCase(loadItemInLoadingList.pending, (state) => {
      state.isDataLoading = true;
    });
    builder.addCase(loadItemInLoadingList.fulfilled, (state, action) => {
      if (action.payload) {
        state.itemsData = state.itemsData.map((currentItem) => {
          if (currentItem.id === action.payload?.id) {
            return action.payload;
          }
          return currentItem;
        });
        state.item = action.payload;
      }
      state.isDataLoading = false;
    });
    builder.addCase(loadItemInLoadingList.rejected, (state) => {
      state.isDataLoading = false;
    });
    builder.addCase(reset, () => {
      return initialState;
    });
  },
});

export const { resetLoadingListLoadings, resetLoadingListItem } = loadingListSlice.actions;

export const loadingListReducer = loadingListSlice.reducer;
