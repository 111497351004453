import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { useNavigate } from 'react-router-dom';
import { PlaceholderComponent, PulsLoader } from '../../components';
import ContentComponent from './components/ContentComponent';
import { SortDescriptor } from '@progress/kendo-data-query';
import { deleteStorageLocation, getStorageLocationData, restoreStorageLocation } from '../../store/slices/storageLocation.slice';
import { storageSorting } from '../../util/localStorageSorting';

const StorageLocation = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const storageLocationData = useAppSelector((state) => state.storageLocation.storageLocationData);
  const isEmptyStorageLocationDataList = useAppSelector((state) => state.storageLocation.isEmptyStorageLocationDataList);
  const isStorageLocationFirstLoading = useAppSelector((state) => state.storageLocation.isStorageLocationFirstLoading);

  const deleteHandler = (hide: () => void, ids: string[]) => {
    dispatch(
      deleteStorageLocation({
        ids,
        text: t('Storage Location have been successfully deleted.'),
        hide,
      }),
    );
  };

  const dataHandler = ({
    sort,
    currentPage,
    inputValue,
    isFirstTime,
    itemsPerPage,
  }: {
    sort: Array<SortDescriptor>;
    itemsPerPage?: number;
    currentPage?: number;
    inputValue?: string;
    isFirstTime?: boolean;
  }) => {
    dispatch(
      getStorageLocationData({
        limit: itemsPerPage,
        page: currentPage,
        search: inputValue,
        sortBy: sort?.[0]?.field || '',
        sortOrder: sort?.[0]?.dir || '',
        isFirstTime,
      }),
    );
  };

  useEffect(() => {
    const data = storageSorting.get('storageLocation');

    dataHandler({ sort: data?.sort ?? [], itemsPerPage: 25, currentPage: 1, isFirstTime: true });

    return () => {
      dispatch(restoreStorageLocation());
    };
  }, []);

  return (
    <div>
      {isStorageLocationFirstLoading ? (
        <PulsLoader />
      ) : (
        <>
          {!storageLocationData.length && isEmptyStorageLocationDataList ? (
            <PlaceholderComponent
              btnText='Add Storage Locations'
              btnHandler={() => {
                navigate('new-location', { replace: true });
              }}
              subtitle="You haven't created any Storage Locations yet. To start working, please click the 'Add Storage Locations' button."
              title='Create Storage Locations'
              placeholderImg={'storageLocationNoDataIcon'}
              width='543px'
              btnWith='356px'
            />
          ) : (
            <ContentComponent dataHandler={dataHandler} deleteHandler={deleteHandler} />
          )}
        </>
      )}
    </div>
  );
};

export default StorageLocation;
