import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.scss';

import { SmallLoader } from '../Loader';

interface IButton {
  type?: 'button' | 'submit';
  onClick?: () => void;
  btnText: string;
  width?: string;
  disabled?: boolean;
  bgcColor?: string;
  textColor?: string;
  outLineColor?: string;
  loading?: boolean;
}

export const Button: FC<IButton> = ({
  onClick,
  type = 'button',
  width = '',
  disabled = false,
  btnText,
  textColor = '#FFFFFF',
  bgcColor = '#EA7600',
  outLineColor,
  loading,
}) => {
  const { t } = useTranslation();

  const btnClassNames = () => {
    let className = `${style.customButton}`;

    if (disabled) {
      className = `${className} ${style.disabled}`;
    }

    return className;
  };

  return (
    <button
      className={btnClassNames()}
      onClick={loading ? () => null : onClick}
      type={type}
      disabled={disabled}
      style={{
        width: width ?? '',
        color: textColor ?? '',
        background: bgcColor ?? '',
        cursor: disabled ? 'auto' : '',
        border: outLineColor ? '1px solid' : undefined,
        borderColor: outLineColor ?? undefined,
        fontSize: '16px',
        fontWeight: 600,
        overflow: 'hidden',
      }}
    >
      {loading ? <SmallLoader /> : t(btnText)}
    </button>
  );
};
