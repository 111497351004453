import React, { FC } from 'react';
import { TableGrid } from '../../../../components';
import { useAppSelector } from '../../../../store';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SortDescriptor } from '@progress/kendo-data-query';

interface IContentComponent {
  dataHandler: (data: { sort: Array<SortDescriptor>; itemsPerPage?: number; currentPage?: number; inputValue?: string }) => void;
  deleteHandler: (hide: () => void, ids: string[]) => void;
}

const ContentComponent: FC<IContentComponent> = ({ dataHandler, deleteHandler }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const storageLocationData = useAppSelector((state) => state.storageLocation.storageLocationData);
  const storageLocationDataCount = useAppSelector((state) => state.storageLocation.storageLocationDataCount);

  return (
    <div>
      <TableGrid
        data={storageLocationData.map((currentStorageLocationData) => ({
          ...currentStorageLocationData,
          usedByStorageArea: currentStorageLocationData.storageAreaList.map(({ name }) => name).join(', '),
        }))}
        items={[
          {
            field: 'number',
            title: 'Number of Storage Location',
          },
          {
            field: 'volume',
            title: 'Volume',
          },
          {
            field: 'usedByStorageArea',
            title: 'Used By Storage Area',
          },
        ]}
        totalCount={storageLocationDataCount}
        dataHandler={dataHandler}
        navigate={() => {
          navigate('new-location', {
            replace: false,
          });
        }}
        withHeaderSearch
        addButtonText='Add Storage Location'
        deleteFunc={deleteHandler}
        deleteText={t('Are you sure that you want to delete storage Location?') || ''}
        deleteTitle={t('Delete Storage Location?') || ''}
        saveSorting
        sortingKey='storageLocation'
      />
    </div>
  );
};

export default ContentComponent;
