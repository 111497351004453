import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import API from '../../api/api';
import { showErrorNotification } from '../../util/showErrorNotification';
import { showNotification } from './notification.slice';
import { IStorageLocation, IStorageLocationInfo } from './storageLocation.slice';
import { reset, RootState } from '..';

interface IUnit {
  abbreviation: string;
  description: string;
  id: string;
  name: string;
}

interface IStorageData {
  description: string;
  id: string;
  maximumCapacity: number;
  name: string;
  number: string;
  unit: IUnit;
}

export interface Storage {
  freeCapacity?: number;
  storage: IStorageData;
  maximumCapacity: number;
}

export interface IStorageBody {
  freeCapacity?: number;
  storage: string;
  maximumCapacity: number;
  isAllowedToRemove?: boolean;
  name?: string;
  number?: string;
}

export interface IStorageAreaBody {
  name: string;
  volume: number;
  maximumCapacity: number;
  description: string;
  superiorStorageArea: string;
  childNumberByHierarchy?: number;
  expanded?: boolean;
  isHasNestedElements?: boolean;
  isLocationRequired?: boolean;
  storageAreaIsDissolveType?: boolean;
  canBeChanged?: boolean;
  contractNumbers?: string[];
}

export interface IStorageClassData {
  description: string;
  id: string;
  maximumCapacity: number;
  name: string;
  number: string;
  unit: IUnit;
}

export interface StorageAreaResponseBody extends IStorageAreaBody {
  storageClass: Array<Storage>;
  storageCriteria: Array<Storage>;
  storageLocation: Array<Omit<IStorageLocation, 'storageAreaList'>>;
  storageClassesData: IStorageClassData[];
}
export interface StorageAreaInfoResponseBody extends IStorageAreaBody {
  storageClass: Array<Storage>;
  storageCriteria: Array<Storage>;
  storageLocation: Array<Omit<IStorageLocationInfo, 'storageAreaList'>>;
  storageClassesData: IStorageClassData[];
}
export interface StorageAreaRequestBody extends IStorageAreaBody {
  storageClass: Array<IStorageBody>;
  storageCriteria: Array<IStorageBody>;
  storageLocation: string[];
}

export interface IStorageArea extends StorageAreaResponseBody {
  isBookForbidden: boolean;
  freeCapacitySpace: number;
  freeSpace: number;
  id: string;
  nestedList?: IStorageArea[];
  isSelectItemForbidden?: boolean;
}
export interface IStorageAreaInfo extends StorageAreaInfoResponseBody {
  freeCapacitySpace: number;
  freeSpace: number;
  id: string;
  nestedList?: IStorageAreaInfo[];
  isSelectItemForbidden?: boolean;
}

export interface IStorageAreaResponse {
  data: IStorageArea[];
  totalCount: number;
}
export interface IStorageAreaInfoResponse {
  data: IStorageAreaInfo[];
  totalCount: number;
}

interface IStorageClassState {
  storageAreaData: IStorageArea[];
  storageAreaDataForStorageItems: IStorageArea[];
  storageAreaDataCount: number;
  storageArea: IStorageArea | null;
  isStorageAreaDataLoading: boolean;
  isStorageAreaDataFirstLoading: boolean;
  isStorageAreaDeleted: boolean;
  isStorageAreaHandlePageActive: boolean;
  isNestedListLoading: boolean;
  nestedListLoadingId: string | null;
  isEmptyStorageAreaDataList: boolean;
  storageAreaDataInfo: IStorageAreaInfo[];
}

const initialState: IStorageClassState = {
  storageAreaData: [],
  storageAreaDataForStorageItems: [],
  storageAreaDataCount: 0,
  storageArea: null,
  isStorageAreaDataLoading: false,
  isStorageAreaDataFirstLoading: true,
  isStorageAreaDeleted: false,
  isStorageAreaHandlePageActive: false,
  isNestedListLoading: false,
  nestedListLoadingId: null,
  isEmptyStorageAreaDataList: false,
  storageAreaDataInfo: [],
};

export const getStorageAreaData = createAsyncThunk(
  'storageArea/getStorageAreaData',
  async (
    {
      page,
      limit,
      search,
      sortBy,
      sortOrder,
      isFirstTime,
      storageItemId,
    }: {
      page?: number;
      limit?: number;
      search?: string;
      sortBy?: string;
      storageItemId?: string;
      sortOrder?: 'asc' | 'desc' | '';
      isFirstTime?: boolean;
    },
    thunkApi,
  ) => {
    try {
      const storageAreaData = await API.storageArea.get.getStorageArea({ page, limit, search, sortBy, sortOrder, storageItemId });

      const state = thunkApi.getState() as unknown as RootState;
      const storageItem = state.storageItem.storageItem;

      return { storageAreaData, isFirstTime, storageItem };
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const searchStorageAreaData = createAsyncThunk(
  'storageArea/getStorageAreaData',
  async ({ search, storageItemId }: { search: string; storageItemId?: string }, thunkApi) => {
    try {
      const storageAreaData = await API.storageArea.get.searchStorageArea(search, storageItemId);

      return { storageAreaData };
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const getSuperiorStorageAreaData = createAsyncThunk('storageArea/getSuperiorStorageAreaData', async (_, thunkApi) => {
  try {
    const storageClassesData = await API.storageArea.get.getStorageArea();

    return storageClassesData;
  } catch (error) {
    thunkApi.dispatch(showErrorNotification(error));
  }
});

export const getStorageAreaDataForStorageItems = createAsyncThunk('storageArea/getStorageAreaDataForStorageItems', async (_, thunkApi) => {
  try {
    const storageClassesData = await API.storageArea.get.getStorageArea();

    return storageClassesData;
  } catch (error) {
    thunkApi.dispatch(showErrorNotification(error));
  }
});

export const createStorageArea = createAsyncThunk(
  'storageArea/createStorageArea',
  async ({ data, navigate, successMessage }: { data: StorageAreaRequestBody; navigate: () => void; successMessage: string }, thunkApi) => {
    try {
      await API.storageArea.post.createStorageArea(data);

      thunkApi.dispatch(
        showNotification({
          text: successMessage,
          type: 'success',
        }),
      );

      navigate();
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const updateStorageArea = createAsyncThunk(
  'storageArea/updateStorageArea',
  async (
    {
      id,
      data,
      navigate,
      successMessage,
    }: {
      id: string;
      data: StorageAreaRequestBody;
      navigate: () => void;
      successMessage: string;
    },
    thunkApi,
  ) => {
    try {
      const storageArea = await API.storageArea.put.updateStorageAreaById(id, data);

      thunkApi.dispatch(
        showNotification({
          text: successMessage,
          type: 'success',
        }),
      );

      navigate();

      return storageArea;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const getStorageAreaById = createAsyncThunk('storageArea/getStorageAreaById', async (id: string, thunkApi) => {
  try {
    const storageAreaData = await API.storageArea.get.getStorageAreaById(id);

    return storageAreaData;
  } catch (error) {
    thunkApi.dispatch(showErrorNotification(error));
  }
});

export const getNestedStorageAreaById = createAsyncThunk(
  'storageArea/getNestedStorageAreaById',
  async ({ expanded, id, storageItemId }: { expanded: boolean; id: string; storageItemId?: string }, thunkApi) => {
    try {
      const state = thunkApi.getState() as unknown as RootState;
      const storageItem = state.storageItem.storageItem;

      if (expanded) {
        const storageAreaList = await API.storageArea.get.getNestedStorageAreaById(id, storageItemId);
        return { storageAreaList, id, expanded, storageItem };
      } else {
        return { id, expanded };
      }
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const deleteStorageArea = createAsyncThunk(
  'storageArea/deleteStorageArea',
  async ({ ids, navigate, text, hide }: { ids: string[]; text: string; navigate?: () => void; hide?: () => void }, thunkApi) => {
    try {
      await API.storageArea.delete.deleteStorageAreaById(ids);

      if (hide) {
        hide();
      }

      if (navigate) {
        navigate();
      }

      thunkApi.dispatch(
        showNotification({
          text,
          type: 'success',
        }),
      );

      return ids;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const updateStorageAreasCapacityById = createAsyncThunk(
  'storageArea/updateStorageAreasCapacityById',
  async ({ comment, storageAreaId, value }: { storageAreaId: string; comment: string; value: number; closeHandler: () => void }, thunkApi) => {
    try {
      const data = await API.storageArea.put.updateStorageAreasCapacityById({
        comment,
        storageAreaId,
        value,
      });

      return data;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const updateStorageAreaTextFieldsById = createAsyncThunk(
  'storageArea/updateStorageAreaTextFieldsById',
  async (
    {
      id,
      data,
      navigate,
      successMessage,
    }: {
      id: string;
      data: { name?: string; description?: string; contractNumbers?: string[]; isLocationRequired?: boolean };
      navigate?: () => void;
      successMessage: string;
    },
    thunkApi,
  ) => {
    try {
      const storageArea = await API.storageArea.put.updateStorageAreaTextFieldsById(id, data);

      thunkApi.dispatch(
        showNotification({
          text: successMessage,
          type: 'success',
        }),
      );

      if (navigate) {
        navigate();
      }

      return storageArea;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const getStorageAreaDataInfo = createAsyncThunk(
  'storageArea/getStorageAreaDataInfo',
  async (
    {
      page,
      limit,
      search,
      sortBy,
      sortOrder,
      isFirstTime,
      storageItemId,
    }: {
      page?: number;
      limit?: number;
      search?: string;
      sortBy?: string;
      storageItemId?: string;
      sortOrder?: 'asc' | 'desc' | '';
      isFirstTime?: boolean;
    },
    thunkApi,
  ) => {
    try {
      const storageAreaData = await API.storageArea.get.getStorageAreaInfo({ page, limit, search, sortBy, sortOrder, storageItemId });

      const state = thunkApi.getState() as unknown as RootState;
      const storageItem = state.storageItem.storageItem;

      return { storageAreaData, isFirstTime, storageItem };
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const getNestedStorageAreaByIdInfo = createAsyncThunk(
  'storageArea/getNestedStorageAreaByIdInfo',
  async ({ expanded, id, storageItemId }: { expanded: boolean; id: string; storageItemId?: string }, thunkApi) => {
    try {
      const state = thunkApi.getState() as unknown as RootState;
      const storageItem = state.storageItem.storageItem;

      if (expanded) {
        const storageAreaList = await API.storageArea.get.getNestedStorageAreaByIdInfo(id, storageItemId);
        return { storageAreaList, id, expanded, storageItem };
      } else {
        return { id, expanded };
      }
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

const storageAreaSlice = createSlice({
  name: 'storageArea',
  initialState,
  reducers: {
    clearStorageAreaData: (state) => {
      state.storageAreaData = [];
    },
    clearStorageArea: (state) => {
      state.storageArea = null;
    },
    setIsStorageAreaDataFirstLoading: (state, action: PayloadAction<boolean>) => {
      state.isStorageAreaDataFirstLoading = action.payload;
    },
    setIsStorageAreaDeleted: (state, action: PayloadAction<boolean>) => {
      state.isStorageAreaDeleted = action.payload;
    },
    setIsStorageAreaHandlePageActive: (state, action: PayloadAction<boolean>) => {
      state.isStorageAreaHandlePageActive = action.payload;
    },
    clearStorageAreaDataForStorageItems: (state) => {
      state.storageAreaDataForStorageItems = [];
    },
    setIsEmptyStorageAreaDataList: (state, action: PayloadAction<boolean>) => {
      state.isEmptyStorageAreaDataList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStorageAreaData.pending, (state, action) => {
      state.isStorageAreaDataLoading = true;
      if (action.meta.arg.isFirstTime) {
        state.isStorageAreaDataFirstLoading = true;
      }
    });
    builder.addCase(getStorageAreaData.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageAreaData = [...(action.payload.storageAreaData as IStorageAreaResponse).data];
        state.storageAreaDataCount = (action.payload.storageAreaData as IStorageAreaResponse).totalCount;
        if (action.payload?.isFirstTime) {
          state.isEmptyStorageAreaDataList = (action.payload?.storageAreaData as IStorageAreaResponse).data.length ? false : true;
        }
      }
      state.isStorageAreaDataLoading = false;
      state.isStorageAreaDataFirstLoading = false;
    });
    builder.addCase(getStorageAreaData.rejected, (state) => {
      state.isStorageAreaDataLoading = false;
      state.isStorageAreaDataFirstLoading = false;
    });
    builder.addCase(createStorageArea.pending, (state) => {
      state.isStorageAreaDataLoading = true;
    });
    builder.addCase(createStorageArea.fulfilled, (state) => {
      state.isStorageAreaDataLoading = false;
    });
    builder.addCase(createStorageArea.rejected, (state) => {
      state.isStorageAreaDataLoading = false;
    });
    builder.addCase(updateStorageArea.pending, (state) => {
      state.isStorageAreaDataLoading = true;
    });
    builder.addCase(updateStorageArea.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageArea = action.payload;
      }
      state.isStorageAreaDataLoading = false;
    });
    builder.addCase(updateStorageArea.rejected, (state) => {
      state.isStorageAreaDataLoading = false;
    });
    builder.addCase(getStorageAreaById.pending, (state) => {
      state.isStorageAreaDataLoading = true;
    });
    builder.addCase(getStorageAreaById.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageArea = action.payload;
      }
      state.isStorageAreaDataLoading = false;
    });
    builder.addCase(getStorageAreaById.rejected, (state) => {
      state.isStorageAreaDataLoading = false;
    });
    builder.addCase(getNestedStorageAreaById.pending, (state, action) => {
      if (action.meta.arg.expanded) {
        state.isNestedListLoading = true;
        state.nestedListLoadingId = action.meta.arg.id;
      }
    });
    builder.addCase(getNestedStorageAreaById.fulfilled, (state, action) => {
      if (action.payload) {
        const recursiveFindChild = (storageAreaList: IStorageArea[], id: string) => {
          const allStorageAreaData = storageAreaList.map((currentStorageArea) => {
            if (currentStorageArea.id === id) {
              const newStorageArea = { ...currentStorageArea };
              newStorageArea.expanded = action.payload?.expanded;
              if (action.payload?.storageAreaList) {
                newStorageArea.nestedList = action.payload.storageAreaList.map((nestedStorageArea) => {
                  if (action.payload?.storageItem?.storageClass?.id) {
                    const el = nestedStorageArea.storageClass.find(
                      (storageClass) => storageClass.storage.id === action.payload?.storageItem?.storageClass?.id,
                    );
                    if (el && el.freeCapacity! < +action.payload.storageItem.quantity) {
                      return {
                        ...nestedStorageArea,
                        childNumberByHierarchy: currentStorageArea.childNumberByHierarchy ? currentStorageArea.childNumberByHierarchy + 1 : 1,
                        isSelectItemForbidden: true,
                      };
                    }
                  }
                  let isSelectItemForbidden = false;
                  if (action.payload?.storageItem?.storageCriteria?.length) {
                    action.payload.storageItem.storageCriteria.forEach((criteria) => {
                      const el = nestedStorageArea.storageCriteria.find((storageCriteria) => storageCriteria.storage.id === criteria.id);

                      if (el && el.freeCapacity! < +action.payload!.storageItem!.quantity) {
                        isSelectItemForbidden = true;
                      }
                    });
                  }
                  return {
                    ...nestedStorageArea,
                    childNumberByHierarchy: currentStorageArea.childNumberByHierarchy ? currentStorageArea.childNumberByHierarchy + 1 : 1,
                    isSelectItemForbidden,
                  };
                });
              } else {
                newStorageArea.nestedList = undefined;
              }
              return newStorageArea;
            }
            if (currentStorageArea.nestedList) {
              currentStorageArea.nestedList = recursiveFindChild(currentStorageArea.nestedList, id);
            }
            return currentStorageArea;
          });

          return allStorageAreaData;
        };

        state.storageAreaData = recursiveFindChild(state.storageAreaData, action.payload?.id);
      }
      state.isNestedListLoading = false;
      state.nestedListLoadingId = null;
    });
    builder.addCase(getNestedStorageAreaById.rejected, (state) => {
      state.isNestedListLoading = false;
      state.nestedListLoadingId = null;
    });
    builder.addCase(deleteStorageArea.pending, (state) => {
      state.isStorageAreaDataLoading = true;
    });
    builder.addCase(deleteStorageArea.fulfilled, (state, action) => {
      if (action.payload) {
        const recursiveFilter = (storageAreaList: IStorageArea[], ids: string[]) => {
          const allStorageAreaData = storageAreaList.map((currentStorageArea) => {
            currentStorageArea.nestedList = currentStorageArea.nestedList?.filter((i) => !ids?.includes(i.id) && i);
            if (currentStorageArea.nestedList) {
              currentStorageArea.isHasNestedElements = currentStorageArea.nestedList?.length ? true : false;
              currentStorageArea.nestedList = recursiveFilter(currentStorageArea.nestedList, ids);
            }
            return currentStorageArea;
          });

          return allStorageAreaData;
        };

        const parentsStorageAreaList = [...state.storageAreaData.filter((i) => !action.payload?.includes(i.id) && i)];
        state.storageAreaData = recursiveFilter(parentsStorageAreaList, action.payload);
      }
      state.isStorageAreaDataLoading = false;
    });
    builder.addCase(deleteStorageArea.rejected, (state) => {
      state.isStorageAreaDataLoading = false;
    });
    builder.addCase(getSuperiorStorageAreaData.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageAreaData = [...(action.payload as IStorageArea[])];
      }
    });
    builder.addCase(getStorageAreaDataForStorageItems.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageAreaDataForStorageItems = [...(action.payload as IStorageArea[])];
      }
    });
    builder.addCase(updateStorageAreasCapacityById.pending, (state) => {
      state.isStorageAreaDataLoading = true;
    });
    builder.addCase(updateStorageAreasCapacityById.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageArea = action.payload;
        action.meta.arg.closeHandler();
      }
      state.isStorageAreaDataLoading = false;
    });
    builder.addCase(updateStorageAreasCapacityById.rejected, (state) => {
      state.isStorageAreaDataLoading = false;
    });
    builder.addCase(updateStorageAreaTextFieldsById.pending, (state) => {
      state.isStorageAreaDataLoading = true;
    });
    builder.addCase(updateStorageAreaTextFieldsById.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageArea = action.payload;
      }
      state.isStorageAreaDataLoading = false;
    });
    builder.addCase(updateStorageAreaTextFieldsById.rejected, (state) => {
      state.isStorageAreaDataLoading = false;
    });
    builder.addCase(getStorageAreaDataInfo.pending, (state, action) => {
      state.isStorageAreaDataLoading = true;
      if (action.meta.arg.isFirstTime) {
        state.isStorageAreaDataFirstLoading = true;
      }
    });
    builder.addCase(getStorageAreaDataInfo.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageAreaDataInfo = [...(action.payload.storageAreaData as IStorageAreaInfoResponse).data];
        state.storageAreaDataCount = (action.payload.storageAreaData as IStorageAreaInfoResponse).totalCount;

        if (action.payload.storageItem?.id) {
          state.storageAreaData = [
            ...state.storageAreaData.map((storageArea) => {
              if (action.payload?.storageItem?.storageClass?.id) {
                const el = storageArea.storageClass.find((storageClass) => storageClass.storage.id === action.payload?.storageItem?.storageClass?.id);
                if (el && el.freeCapacity! < +action.payload.storageItem.quantity) {
                  return { ...storageArea, isSelectItemForbidden: true };
                }
              }
              let isSelectItemForbidden = false;
              if (action.payload?.storageItem?.storageCriteria?.length) {
                action.payload.storageItem.storageCriteria.forEach((criteria) => {
                  const el = storageArea.storageCriteria.find((storageCriteria) => storageCriteria.storage.id === criteria.id);

                  if (el && el.freeCapacity! < +action.payload!.storageItem!.quantity!) {
                    isSelectItemForbidden = true;
                  }
                });
              }
              return { ...storageArea, isSelectItemForbidden };
            }),
          ];
        }

        if (action.payload?.isFirstTime) {
          state.isEmptyStorageAreaDataList = (action.payload?.storageAreaData as IStorageAreaInfoResponse).data.length ? false : true;
        }
      }
      state.isStorageAreaDataLoading = false;
      state.isStorageAreaDataFirstLoading = false;
    });
    builder.addCase(getStorageAreaDataInfo.rejected, (state) => {
      state.isStorageAreaDataLoading = false;
      state.isStorageAreaDataFirstLoading = false;
    });
    builder.addCase(getNestedStorageAreaByIdInfo.pending, (state, action) => {
      if (action.meta.arg.expanded) {
        state.isNestedListLoading = true;
        state.nestedListLoadingId = action.meta.arg.id;
      }
    });
    builder.addCase(getNestedStorageAreaByIdInfo.fulfilled, (state, action) => {
      if (action.payload) {
        const recursiveFindChild = (storageAreaList: IStorageAreaInfo[], id: string) => {
          const allStorageAreaData = storageAreaList.map((currentStorageArea) => {
            if (currentStorageArea.id === id) {
              const newStorageArea = { ...currentStorageArea };
              newStorageArea.expanded = action.payload?.expanded;
              if (action.payload?.storageAreaList) {
                (newStorageArea.nestedList as any) = action.payload.storageAreaList.map((nestedStorageArea) => {
                  if (action.payload?.storageItem?.storageClass?.id) {
                    const el = nestedStorageArea.storageClass.find(
                      (storageClass) => storageClass.storage.id === action.payload?.storageItem?.storageClass?.id,
                    );
                    if (el && el.freeCapacity! < +action.payload.storageItem.quantity) {
                      return {
                        ...nestedStorageArea,
                        childNumberByHierarchy: currentStorageArea.childNumberByHierarchy ? currentStorageArea.childNumberByHierarchy + 1 : 1,
                        isSelectItemForbidden: true,
                      };
                    }
                  }
                  let isSelectItemForbidden = false;
                  if (action.payload?.storageItem?.storageCriteria?.length) {
                    action.payload.storageItem.storageCriteria.forEach((criteria) => {
                      const el = nestedStorageArea.storageCriteria.find((storageCriteria) => storageCriteria.storage.id === criteria.id);

                      if (el && el.freeCapacity! < +action.payload!.storageItem!.quantity) {
                        isSelectItemForbidden = true;
                      }
                    });
                  }
                  return {
                    ...nestedStorageArea,
                    childNumberByHierarchy: currentStorageArea.childNumberByHierarchy ? currentStorageArea.childNumberByHierarchy + 1 : 1,
                    isSelectItemForbidden,
                  };
                });
              } else {
                newStorageArea.nestedList = undefined;
              }
              return newStorageArea;
            }
            if (currentStorageArea.nestedList) {
              currentStorageArea.nestedList = recursiveFindChild(currentStorageArea.nestedList, id);
            }
            return currentStorageArea;
          });

          return allStorageAreaData;
        };

        state.storageAreaDataInfo = recursiveFindChild(state.storageAreaDataInfo, action.payload?.id);
      }
      state.isNestedListLoading = false;
      state.nestedListLoadingId = null;
    });
    builder.addCase(getNestedStorageAreaByIdInfo.rejected, (state) => {
      state.isNestedListLoading = false;
      state.nestedListLoadingId = null;
    });
    builder.addCase(reset, () => {
      return initialState;
    });
  },
});

export const {
  clearStorageArea,
  clearStorageAreaData,
  setIsStorageAreaDeleted,
  setIsStorageAreaDataFirstLoading,
  setIsStorageAreaHandlePageActive,
  clearStorageAreaDataForStorageItems,
  setIsEmptyStorageAreaDataList,
} = storageAreaSlice.actions;

export const storageAreaReducer = storageAreaSlice.reducer;
