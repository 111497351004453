import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { reset } from '..';

interface INotificationData {
  type: 'none' | 'success' | 'error' | 'warning' | 'info' | null;
  text: string | null;
}

interface INotificationState extends INotificationData {
  isOpen: boolean;
}

const initialState: INotificationState = {
  type: null,
  text: null,
  isOpen: false,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showNotification: (state, action: PayloadAction<INotificationData>) => {
      if (action.payload.type && action.payload.text) {
        state.type = action.payload.type;
        state.text = action.payload.text;
        state.isOpen = true;
      }
    },
    hideNotification: (state) => {
      state.isOpen = false;
      state.text = initialState.text;
      state.type = initialState.type;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => {
      return initialState;
    });
  },
});

export const { showNotification, hideNotification } = notificationSlice.actions;

export const notificationReducer = notificationSlice.reducer;
