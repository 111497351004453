import { IStorageLocation, IStorageLocationBody, IStorageLocationDataResponse } from '../../store/slices/storageLocation.slice';
import { adminInstance } from '../api';

const getStorageLocation = (page?: number, limit?: number, search?: string, sortBy?: string, sortOrder?: 'asc' | 'desc' | '') => {
  return adminInstance
    .get<IStorageLocationDataResponse>(`api/v1/storage-location`, {
      params: {
        page,
        limit,
        search,
        sortBy,
        sortOrder,
      },
    })
    .then((res) => res.data);
};

const getStorageLocationById = (id: string) => {
  return adminInstance.get<IStorageLocation>(`api/v1/storage-location/${id}`).then((res) => res.data);
};

const createStorageLocation = (data: IStorageLocationBody) => {
  return adminInstance.post<IStorageLocation>(`api/v1/storage-location`, data).then((res) => res.data);
};

const updateStorageLocationById = (id: string, data: IStorageLocationBody) => {
  return adminInstance.put<IStorageLocation>(`api/v1/storage-location/${id}`, data).then((res) => res.data);
};

const deleteStorageLocation = (ids: string[]) => {
  return adminInstance
    .delete(`api/v1/storage-location`, {
      params: {
        ids,
      },
    })
    .then((res) => res.data);
};

const StorageLocation = {
  get: {
    getStorageLocation,
    getStorageLocationById,
  },
  post: {
    createStorageLocation,
  },
  put: {
    updateStorageLocationById,
  },
  delete: {
    deleteStorageLocation,
  },
};

export default StorageLocation;
