import React, { SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import style from './style.module.scss';
import { useAppDispatch, useAppSelector } from '../../store';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IStorageBody,
  clearStorageArea,
  clearStorageAreaData,
  deleteStorageArea,
  getStorageAreaById,
  getSuperiorStorageAreaData,
  setIsStorageAreaHandlePageActive,
  updateStorageAreaTextFieldsById,
} from '../../store/slices/storageArea.slice';
import { updateStorageArea } from '../../store/slices/storageArea.slice';
import { createStorageArea } from '../../store/slices/storageArea.slice';
import { Button } from '@progress/kendo-react-buttons';
import StorageSettingsTable, { ComponentTypeWithoutLoadingList } from './components/StorageSettingsTable';
import ClassAndCriteriaModal from './components/Modal';
import LocationModal from './components/Modal/LocationModal';
import { ButtonSpinner, ModalWrapper, PulsLoader, Input, DropDown, TextArea, LinkNavigationComponent, Svg } from '../../components';
import { setIsModalActive } from '../../store/slices/app.slice';
import { clearStorageClassesData, getStorageClassesAreaData } from '../../store/slices/storageClass.slice';
import { clearStorageCriteriaData, getStorageCriteriaAreaData } from '../../store/slices/storageCriteria.slice';
import { DeletionModal } from '../../helpers/modals';
import { getStorageLocationData, restoreStorageLocation } from '../../store/slices/storageLocation.slice';
import ExitConfirmationModal from '../../components/ExitConfirmationModal';
import { Checkbox } from '@progress/kendo-react-inputs';

const StorageAreaHandlerPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { areaId, id } = useParams();
  const navigate = useNavigate();
  const fieldsetRef = useRef(null);
  const storageArea = useAppSelector((state) => state.storageArea.storageArea);
  const isStorageAreaDataLoading = useAppSelector((state) => state.storageArea.isStorageAreaDataLoading);
  const isModalActive = useAppSelector((state) => state.app.client.isModalActive);
  const storageAreas = useAppSelector((state) => state.storageArea.storageAreaData);
  const [modalType, setModalType] = useState<ComponentTypeWithoutLoadingList | null>(null);
  const [storageClasses, setStorageClasses] = useState<IStorageBody[]>([]);

  const [storageCriterias, setStorageCriterias] = useState<IStorageBody[]>([]);
  const [storageLocation, setStorageLocation] = useState(storageArea?.storageLocation || []);
  const [fieldSetScrollIsOnTop, setFieldSetScrollIsOnTop] = useState(true);
  const [selectedItem, setSelectedItem] = useState<number>(-1);
  const [isLocationRequired, setIsLocationRequired] = useState(storageArea?.isLocationRequired || false);
  const isUpdatingDisabled = storageArea && !storageArea.canBeChanged;

  const volumeValidation = (value: string) => {
    if (storageArea) {
      const val = +storageArea.volume - +storageArea.freeSpace;
      return +value >= val
        ? ''
        : `${t("Volume can't be less than the volume of the items in it")} (${t('not less than')} ${val % 1 === 0 ? val : val.toFixed(4)})`;
    }
    return '';
  };

  const capacityValidation = (value: string) => {
    if (storageArea) {
      const val = +storageArea.maximumCapacity - +storageArea.freeCapacitySpace;
      return +value >= +storageArea.maximumCapacity - +storageArea.freeCapacitySpace
        ? ''
        : `${t("Capacity can't be less than the capacity of the items in it")} (${t('not less than')} ${val % 1 === 0 ? val : val.toFixed(4)})`;
    }
    return '';
  };

  const modalCloseHandler = () => {
    dispatch(setIsModalActive(false));
    setModalType(null);
    setSelectedItem(-1);
  };

  const deleteHandler = (hide: () => void) => {
    if (areaId) {
      dispatch(
        deleteStorageArea({
          ids: [areaId],
          navigate: () => {
            navigate(`/warehouse/${id}/area`);
          },
          hide,
          text: t('Storage area has been successfully deleted'),
        }),
      );
    }
  };

  const classesAndCriteriaAddHandler = (type: ComponentTypeWithoutLoadingList, data: IStorageBody) => {
    if (type === 'class') {
      const newStorageClasses = [...storageClasses, data];
      setStorageClasses(newStorageClasses);
    } else if (type === 'criteria') {
      const newStorageCriterias = [...storageCriterias, data];
      setStorageCriterias(newStorageCriterias);
    }
  };

  const classesAndCriteriaUpdateHandler = (type: ComponentTypeWithoutLoadingList, data: IStorageBody & { isAllowedToRemove?: boolean }) => {
    if (type === 'class') {
      const updatedList = [
        ...storageClasses.map((el, i) => {
          if (i === selectedItem) {
            return data;
          }
          return el;
        }),
      ];
      setStorageClasses(updatedList);
    } else if (type === 'criteria') {
      const updatedList = [
        ...storageCriterias.map((el, i) => {
          if (i === selectedItem) {
            return data;
          }
          return el;
        }),
      ];
      setStorageCriterias(updatedList);
    }
  };

  const openModalHandler = () => {
    if (isUpdatingDisabled) {
      return;
    }
    DeletionModal({
      deleteHandler,
      deleteText: t('Are you sure that you want to delete storage areas?'),
      deleteTitle: t('Delete Storage Areas?'),
    });
  };

  const handleScroll = () => {
    if (fieldsetRef && fieldsetRef.current) {
      const scrollTop = (fieldsetRef.current as any).scrollTop;
      if (scrollTop === 0) {
        setFieldSetScrollIsOnTop(true);
      } else {
        setFieldSetScrollIsOnTop(false);
      }
    }
  };

  const btnHandler = (type?: ComponentTypeWithoutLoadingList) => {
    if (isUpdatingDisabled) {
      return;
    }
    dispatch(setIsModalActive(true));
    setModalType(type || null);
  };

  const updateHandler = (data: IStorageBody, sortData: IStorageBody[], type: ComponentTypeWithoutLoadingList) => {
    const idx = sortData.findIndex((el) => el.maximumCapacity === data.maximumCapacity && el.storage === data.storage);
    setSelectedItem(idx);
    dispatch(setIsModalActive(true));

    setModalType(type);
  };

  const deleteItemHandler = ({
    data,
    sortData,
    setter,
    type,
  }: {
    data: IStorageBody;
    sortData: IStorageBody[];
    setter: (value: SetStateAction<IStorageBody[]>) => void;
    type: ComponentTypeWithoutLoadingList;
  }) => {
    const deleteHandler = (hide: () => void) => {
      hide();
      const idx = sortData.findIndex((el) => el.maximumCapacity === data.maximumCapacity && el.storage === data.storage);
      const updatedList = sortData.filter((el, i) => i !== idx && el);
      setter(updatedList);
    };

    const textData: { [key: string]: string } = {
      criteria: 'Criteria',
      class: 'Class',
      location: 'Location',
    };

    DeletionModal({
      deleteHandler,
      deleteText: t(`Are you sure that you want to delete 'Storage ${textData[type]}'?`),
      deleteTitle: t(`Delete Storage ${textData[type]}?`),
    });
  };

  useEffect(() => {
    if (areaId) {
      dispatch(getStorageAreaById(areaId));
    }

    Promise.all([
      dispatch(getStorageClassesAreaData({ sortBy: 'number', sortOrder: 'asc' })),
      dispatch(getStorageCriteriaAreaData({ sortBy: 'number', sortOrder: 'asc' })),
      dispatch(getSuperiorStorageAreaData()),
      dispatch(getStorageLocationData({ sortBy: 'number', sortOrder: 'asc' })),
      dispatch(setIsStorageAreaHandlePageActive(true)),
    ]);

    return () => {
      dispatch(clearStorageArea());
      dispatch(clearStorageClassesData());
      dispatch(clearStorageCriteriaData());
      dispatch(clearStorageAreaData());
      dispatch(setIsStorageAreaHandlePageActive(false));
      dispatch(restoreStorageLocation());
    };
  }, []);

  useEffect(() => {
    if (storageArea) {
      const isAllowed = storageArea.maximumCapacity - +storageArea.freeCapacitySpace === 0 && +storageArea.volume - +storageArea.freeSpace === 0;
      if (storageArea.storageClass.length) {
        setStorageClasses(
          storageArea?.storageClass.map((i) => {
            return {
              maximumCapacity: i.maximumCapacity,
              freeCapacity: i.freeCapacity,
              storage: i.storage.id,
              name: i.storage.name,
              number: i.storage.number,
              isAllowedToRemove: isAllowed ? true : false,
            };
          }),
        );
      }
      if (storageArea.storageCriteria.length) {
        setStorageCriterias(
          storageArea?.storageCriteria.map((i) => {
            return {
              maximumCapacity: i.maximumCapacity,
              freeCapacity: i.freeCapacity,
              storage: i.storage.id,
              name: i.storage.name,
              number: i.storage.number,
              isAllowedToRemove: isAllowed ? true : false,
            };
          }),
        );
      }
      if (storageArea.storageLocation.length) {
        setStorageLocation(
          storageArea.storageLocation.map((el) => {
            return { ...el, isAllowedToRemove: isAllowed ? true : false };
          }),
        );
      }
      setIsLocationRequired(storageArea.isLocationRequired || false);
    }
  }, [storageArea]);

  if (areaId && !storageArea) {
    return <PulsLoader />;
  }

  return (
    <div
      style={{
        height: 'calc(100%)',
      }}
    >
      <LinkNavigationComponent
        navigate={() => {
          navigate(`/warehouse/${id}/area`, {
            replace: false,
          });
        }}
        listText='Storage Area List'
        detailText={areaId ? t('Storage Area Details') : t('Add New Storage Area')}
        customStyle={{
          padding: '24px 20px',
          marginBottom: '0',
          filter: isModalActive ? 'blur(2px)' : '',
          position: 'relative',
          zIndex: 2,
          boxShadow: !fieldSetScrollIsOnTop ? '0px 1px 8px rgba(0, 0, 0, 0.04)' : '',
        }}
      />
      <Form
        initialValues={{
          name: storageArea && areaId ? storageArea.name : '',
          descr: storageArea && areaId ? storageArea.description : '',
          volume: storageArea && areaId ? storageArea.volume : '',
          superiorStorageArea: storageArea && areaId ? storageArea.superiorStorageArea : '',
          maximumCapacity: storageArea && areaId ? storageArea.maximumCapacity : '',
          storageClasses,
          storageCriterias,
          storageLocation,
          isLocationRequired,
          storageAreaIsDissolveType: storageArea ? storageArea.storageAreaIsDissolveType : false,
          contractNumbers: storageArea && areaId && storageArea.contractNumbers?.length ? [...storageArea.contractNumbers] : [],
        }}
        key={areaId}
        ignoreModified
        onSubmit={(values) => {
          const data = {
            description: values.descr,
            maximumCapacity: values.maximumCapacity,
            name: values.name,
            storageClass: storageClasses,
            storageCriteria: storageCriterias,
            storageLocation: storageLocation.map(({ id }) => id),
            volume: values.volume || null,
            superiorStorageArea: values?.superiorStorageArea?.id || null,
            isLocationRequired,
            storageAreaIsDissolveType: values.storageAreaIsDissolveType,
            contractNumbers: values.contractNumbers,
          };

          if (!areaId) {
            dispatch(
              createStorageArea({
                data,
                navigate: () => {
                  navigate(`/warehouse/${id}/area`, {
                    replace: false,
                  });
                },
                successMessage: t('Storage Area has been successfully created.'),
              }),
            );
          } else {
            if (!isUpdatingDisabled) {
              dispatch(
                updateStorageArea({
                  id: areaId,
                  data,
                  navigate: () => {
                    navigate(`/warehouse/${id}/area`, {
                      replace: false,
                    });
                  },
                  successMessage: t('Storage Area has been successfully updated.'),
                }),
              );
            } else {
              dispatch(
                updateStorageAreaTextFieldsById({
                  id: areaId,
                  data: {
                    contractNumbers: [...values.contractNumbers],
                    description: values.descr,
                    isLocationRequired,
                    name: values.name,
                  },
                  navigate: () => {
                    navigate(`/warehouse/${id}/area`, {
                      replace: false,
                    });
                  },
                  successMessage: t('Storage Area has been successfully updated.'),
                }),
              );
            }
          }
        }}
        render={(props: FormRenderProps) => {
          const hasDuplicateContractNumbers = (arr: string[]) => arr.length !== new Set(arr).size;

          const isWithoutDuplicates = !props.valueGetter('contractNumbers').length
            ? true
            : !hasDuplicateContractNumbers(props.valueGetter('contractNumbers'));

          const isWithoutEmptyContracts = !props.valueGetter('contractNumbers').length
            ? true
            : !props.valueGetter('contractNumbers').filter((el: string) => el === '').length;

          const isActive =
            props.valueGetter('volume') &&
            props.valueGetter('name') &&
            props.valueGetter('maximumCapacity') &&
            !props.errors.volume &&
            !props.errors.maximumCapacity &&
            isWithoutDuplicates &&
            isWithoutEmptyContracts;
          return (
            <>
              <FormElement
                style={{
                  filter: isModalActive ? 'blur(2px)' : '',
                }}
                className={style.form}
              >
                <fieldset className={`${style.fieldset}`} ref={fieldsetRef} onScroll={handleScroll}>
                  <div className={style.groupTitle}>{t('Main Info')}</div>
                  <div className={style.mainInfoWrapper}>
                    <div className={style.fieldWrapper}>
                      <div className={style.fieldTitle}>{t('Storage Area Name')}</div>
                      <Field
                        name='name'
                        component={Input}
                        customProp={{
                          placeholder: 'Enter storage area name',
                        }}
                      />
                    </div>
                    <div className={style.fieldWrapper}>
                      <div className={style.fieldTitle}>
                        {t('Volume')}
                        <span className={style.hintText}> ({t('cubic meters')})</span>
                      </div>
                      <Field
                        name='volume'
                        type='number'
                        component={Input}
                        customProp={{
                          placeholder: 'Enter number',
                        }}
                        validator={volumeValidation}
                        disabled={isUpdatingDisabled}
                      />
                    </div>
                    <div className={style.fieldWrapper}>
                      <div className={style.fieldTitle}>{t('Maximum capacity per storage area')}</div>
                      <Field
                        name='maximumCapacity'
                        type='number'
                        component={Input}
                        customProp={{
                          placeholder: 'Enter number',
                        }}
                        validator={capacityValidation}
                        disabled={isUpdatingDisabled}
                      />
                    </div>
                    <div className={style.fieldWrapper}>
                      <div className={style.fieldTitle}>
                        {t('Superior Storage Area')}
                        <span className={style.hintText}> ({t('optional')})</span>
                      </div>
                      <Field
                        name='superiorStorageArea'
                        component={DropDown}
                        customProp={{
                          placeholder: 'Select superior Storage Area',
                          data: storageAreas.filter((i) => {
                            if (!areaId) {
                              return i;
                            }
                            return i.id !== areaId && i;
                          }),
                          textField: 'name',
                          dataItemKey: 'id',
                        }}
                        disabled={isUpdatingDisabled}
                      />
                    </div>
                  </div>
                  <div className={style.textAreaWrapper}>
                    <div className={style.fieldTitle}>
                      {t('Description')}
                      <span className={style.optionalText}> ({t('optional')})</span>
                    </div>
                    <Field name='descr' component={TextArea} customProp={{ placeholder: 'Enter description' }} />
                  </div>
                  <div className={style.checkboxWrapper}>
                    <Field
                      name='storageAreaIsDissolveType'
                      component={Checkbox}
                      label={t('Dissolve storage object on entry')}
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                      className={props.valueGetter('storageAreaIsDissolveType') ? style.checkedCheckbox : ''}
                      disabled={isUpdatingDisabled}
                    />
                  </div>
                  {props.valueGetter('storageAreaIsDissolveType') ? (
                    <div className={style.contractWrapper}>
                      <div className={style.headerWrapper}>
                        <div className={style.headerTitle}>{t('Contract')}</div>
                        <Button
                          className={style.newModelBtn}
                          onClick={() => {
                            props.onChange('contractNumbers', { value: [...props.valueGetter('contractNumbers'), ''] });
                          }}
                          type='button'
                          style={{
                            height: '40px !important',
                          }}
                        >
                          <Svg icon='orangePlusIcon' />
                          {t('Add Contract')}
                        </Button>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexWrap: 'wrap',
                          rowGap: '30px',
                        }}
                      >
                        {props.valueGetter('contractNumbers').map((_: any, idx: number) => {
                          return (
                            <div key={`${idx}`} className={style.contractNumberFieldWrapper}>
                              <Field
                                name={`contractNumbers[${idx}]`}
                                type='text'
                                component={Input}
                                customProp={{
                                  placeholder: 'Enter here',
                                }}
                              />
                              <Svg
                                icon='greyDeleteIcon'
                                style={{
                                  marginLeft: '10px',
                                  cursor: 'pointer',
                                }}
                                width={20}
                                height={20}
                                onClick={() => {
                                  const temp = [...props.valueGetter('contractNumbers')];
                                  temp.splice(idx, 1);
                                  props.onChange('contractNumbers', {
                                    value: [...temp],
                                  });
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                  <StorageSettingsTable
                    btnText='Add Storage Class'
                    data={storageClasses.map((el: IStorageBody) => {
                      return {
                        name: el.name,
                        number: el.number,
                        maximumCapacity: el.maximumCapacity,
                        freeCapacity: el.freeCapacity,
                        storage: el.storage,
                        isAllowedToRemove: el.isAllowedToRemove,
                      };
                    })}
                    headerTitle='Storage Classes Settings'
                    items={[
                      {
                        field: 'number',
                        title: 'Storage Class Number',
                      },
                      {
                        field: 'name',
                        title: 'Storage Class Name',
                      },
                      {
                        field: 'maximumCapacity',
                        title: 'Max capacity',
                      },
                      {
                        field: 'freeCapacity',
                        title: 'Free capacity',
                      },
                    ]}
                    btnHandler={btnHandler}
                    type={'class'}
                    deleteHandler={(data: IStorageBody) => {
                      deleteItemHandler({ data, sortData: storageClasses, setter: setStorageClasses, type: 'class' });
                      props.onChange('storageClasses', { value: [data] });
                    }}
                    updateHandler={(data: IStorageBody) => {
                      updateHandler(data, storageClasses, 'class');
                    }}
                    isUpdatingDisabled={!!isUpdatingDisabled}
                  />
                  <StorageSettingsTable
                    btnText='Add Storage Criteria'
                    data={storageCriterias.map((el) => {
                      return {
                        name: el.name,
                        number: el.number,
                        maximumCapacity: el.maximumCapacity,
                        freeCapacity: el.freeCapacity,
                        storage: el.storage,
                        isAllowedToRemove: el.isAllowedToRemove,
                      };
                    })}
                    headerTitle='Storage Criterias Settings'
                    items={[
                      {
                        field: 'number',
                        title: 'Storage Criterias Number',
                      },
                      {
                        field: 'name',
                        title: 'Storage Criterias Name',
                      },
                      {
                        field: 'maximumCapacity',
                        title: 'Max capacity',
                      },
                      {
                        field: 'freeCapacity',
                        title: 'Free capacity',
                      },
                    ]}
                    btnHandler={btnHandler}
                    type={'criteria'}
                    deleteHandler={(data: IStorageBody) => {
                      deleteItemHandler({ data, sortData: storageCriterias, setter: setStorageCriterias, type: 'criteria' });
                      props.onChange('storageCriterias', { value: [data] });
                    }}
                    updateHandler={(data: IStorageBody) => {
                      updateHandler(data, storageCriterias, 'criteria');
                    }}
                    isUpdatingDisabled={!!isUpdatingDisabled}
                  />
                  <StorageSettingsTable
                    btnText='Add Storage Location'
                    data={storageLocation.map((currentLocation) => ({
                      ...currentLocation,
                      number: +currentLocation.number,
                      volumeValue: +currentLocation.volume,
                      volume: `${currentLocation.volume} m³`,
                    }))}
                    headerTitle='Storage Location Settings'
                    items={[
                      { field: 'number', title: 'Storage Location Number' },
                      { field: 'volume', title: 'Volume' },
                    ]}
                    btnHandler={btnHandler}
                    type={'location'}
                    deleteStorageLocationHandler={(id: string) => {
                      const deleteHandler = (hide: () => void) => {
                        hide();
                        const filteredStorageLocation = storageLocation.filter(({ id: currentId }) => currentId !== id);
                        setStorageLocation(filteredStorageLocation);
                        props.onChange('storageLocation', { value: filteredStorageLocation });
                      };

                      DeletionModal({
                        deleteHandler,
                        deleteText: t(`Are you sure that you want to delete 'Storage Location'?`),
                        deleteTitle: t(`Delete Storage Location?`),
                      });
                    }}
                    isLocationRequired={isLocationRequired}
                    changeIsLocationRequiredValue={(event) => {
                      setIsLocationRequired(event.value);
                      props.onChange('isLocationRequired', { value: event.value });
                    }}
                    isUpdatingDisabled={!!isUpdatingDisabled}
                  />
                </fieldset>
                <div className={areaId ? style.singleButtonWrapper : style.buttonsWrapper}>
                  {areaId ? (
                    <Button
                      className={`${style.deleteBtn} ${isUpdatingDisabled ? style.disabledDeleteBtn : ''}`}
                      onClick={openModalHandler}
                      type='button'
                      title={isUpdatingDisabled ? t("Storage Area with items can't be deleted") ?? '' : ''}
                    >
                      <Svg icon='deleteIcon' width={16} height={16} className={style.deleteImg} />
                      {t('Delete')}
                    </Button>
                  ) : null}
                  <Button
                    type={!isActive ? 'button' : 'submit'}
                    className={!isActive ? style.disabledCreateBtn : style.createBtn}
                    style={{ width: !areaId ? '356px' : '262px' }}
                  >
                    {isStorageAreaDataLoading ? <ButtonSpinner /> : t('Save')}
                  </Button>
                </div>
              </FormElement>
              <ExitConfirmationModal when={!props.submitted && props.modified} />
              {modalType ? (
                <ModalWrapper modalCloseHandler={modalCloseHandler}>
                  {modalType === 'location' ? (
                    <LocationModal
                      closeModalHandler={modalCloseHandler}
                      locationAddHandler={(currentLocation) => {
                        const newStorageLocation = [...storageLocation, currentLocation];
                        setStorageLocation(newStorageLocation);
                        props.onChange('storageLocation', { value: newStorageLocation });
                      }}
                      selectedStorageLocationList={storageLocation.map(({ id }) => id)}
                    />
                  ) : (
                    <ClassAndCriteriaModal
                      closeModalHandler={modalCloseHandler}
                      type={modalType}
                      classesAndCriteriaAddHandler={(type, data) => {
                        classesAndCriteriaAddHandler(type, data);
                        props.onChange(type === 'class' ? 'storageClasses' : 'storageCriterias', { value: [data] });
                      }}
                      classesAndCriteriaUpdateHandler={(type, data) => {
                        classesAndCriteriaUpdateHandler(type, data);
                        props.onChange(type === 'class' ? 'storageClasses' : 'storageCriterias', { value: [data] });
                      }}
                      selectedItem={modalType === 'class' ? storageClasses[selectedItem] : storageCriterias[selectedItem]}
                      selectedItems={modalType === 'class' ? storageClasses.map((el) => el.storage) : storageCriterias.map((el) => el.storage)}
                    />
                  )}
                </ModalWrapper>
              ) : null}
            </>
          );
        }}
      />
    </div>
  );
};

export default StorageAreaHandlerPage;
