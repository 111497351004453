import React, { FC } from "react";
import styles from "./style.module.scss";

interface IContentWrapper {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const ContentWrapper: FC<IContentWrapper> = ({
  children,
  style = {},
}) => {
  return (
    <div className={styles.contentWrapper} style={{...style}}>
      {children}
    </div>
  );
};