import { IBookingHistory, IStorageItem, IStorageItemDocument } from '../../store/slices/storageItem.slice';
import { adminInstance } from '../api';

const getStorageItems = ({
  limit,
  page,
  search,
  sortBy,
  sortOrder,
  endDate,
  startDate,
  storageArea,
  storageObjectNumber,
  user,
  withStorageArea,
  withLoadingList,
  targetFacility,
}: {
  page?: number;
  limit?: number;
  search?: string;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc' | '';
  startDate?: string;
  endDate?: string;
  user?: string;
  storageObjectNumber?: string;
  storageArea?: string[];
  withStorageArea?: boolean;
  withLoadingList?: string | boolean;
  targetFacility?: string;
}) => {
  return adminInstance
    .get<{ data: IStorageItem[]; totalCount: number }>(`api/v1/storage-item`, {
      params: {
        limit,
        page,
        search,
        sortBy,
        sortOrder,
        endDate,
        startDate,
        storageArea,
        storageObjectNumber,
        user,
        withStorageArea,
        withLoadingList,
        targetFacility,
      },
    })
    .then((res) => res.data);
};

const getStorageItemById = (id: string) => {
  return adminInstance.get<IStorageItem>(`api/v1/storage-item/${id}`).then((res) => res.data);
};

const bookStorageArea = ({
  commentBookingProcess,
  commentStorageProcess,
  id,
  storageArea,
  storageLocation,
}: {
  id: string;
  commentBookingProcess?: string;
  commentStorageProcess?: string;
  storageArea: string;
  storageLocation?: string;
}) => {
  return adminInstance
    .post<IStorageItem>(`api/v1/storage-item/${id}`, {
      commentBookingProcess,
      commentStorageProcess,
      storageArea,
      storageLocation,
    })
    .then((res) => res.data);
};

const updateStorageItem = (data: IStorageItem, id: string) => {
  return adminInstance.put(`api/v1/storage-item/${id}/manually`, data).then((res) => res.data);
};

const deleteStorageItemById = (id: string) => {
  return adminInstance.delete(`api/v1/storage-item/${id}`).then((res) => res.data);
};

const getBookingHistory = ({
  id,
  limit,
  page,
  sortBy,
  sortOrder,
}: {
  id: string;
  page?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc' | '';
}) => {
  return adminInstance
    .get<{ data: IBookingHistory[]; totalCount: number }>(`api/v1/booking-history`, {
      params: {
        page: page,
        limit,
        sortBy,
        sortOrder,
        storageItem: id,
      },
    })
    .then((res) => res.data);
};

const getTargetFacilityList = () => {
  return adminInstance.get<string[]>('api/v1/storage-item/target-facility-list').then((res) => res.data);
};

const updateStorageItemsDeterminationClass = ({ determinationClassError, id }: { id: string; determinationClassError: boolean }) => {
  return adminInstance.put<IStorageItem>(`api/v1/storage-item/${id}/manually`, { determinationClassError }).then((res) => res.data);
};

const createStorageItem = (data: {
  customerName: string;
  wasteNumber: string;
  wasteName: string;
  number: string;
  quantity: number;
  unit: string;
  containerNumber: string;
  containerType: string;
  containerTara: string;
  storageClass: string;
  storageCriteria: string[];
  targetFacility: string;
}) => {
  return adminInstance.post(`api/v1/storage-item/manually`, data).then((res) => res.data);
};
const updateStorageItemsDeterminationUnit = ({ determinationUnitError, id }: { id: string; determinationUnitError: boolean }) => {
  return adminInstance.put<IStorageItem>(`api/v1/storage-item/${id}/manually`, { determinationUnitError }).then((res) => res.data);
};

const generatePdf = ({
  betrieb_nr,
  firma_nr,
  konzern_nr,
  anlagen_nr,
  lieferschein_nr,
  referenz_nr,
  name,
  type,
}: {
  anlagen_nr?: string;
  betrieb_nr?: string;
  firma_nr?: string;
  konzern_nr?: string;
  lieferschein_nr?: string;
  referenz_nr?: string;
  name?: string;
  type: 'runningSheet' | 'provisionalRunningSheet';
}) => {
  return adminInstance
    .get(`api/v1/storage-item/pdf`, {
      params: {
        betrieb_nr,
        firma_nr,
        konzern_nr,
        anlagen_nr,
        lieferschein_nr,
        referenz_nr,
        type,
        apikey: process.env.REACT_APP_API_KEY,
      },
      responseType: 'blob',
    })
    .then((res) => {
      const href = URL.createObjectURL(res.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${name}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

const getStorageItemDocuments = async ({
  id,
  limit,
  page,
  sortBy,
  sortOrder,
}: {
  id: string;
  page?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc' | '';
}) => {
  return adminInstance
    .get<{ data: IStorageItemDocument[]; totalCount: number }>(`api/v1/storage-item/${id}/documents`, {
      params: {
        limit,
        page,
        sortBy,
        sortOrder,
      },
    })
    .then((res) => res.data);
};

const getStorageItemPdfById = ({ id, documentId, name }: { id: string; documentId: string; name?: string }) => {
  return adminInstance
    .get(`api/v1/storage-item/${id}/documents/${documentId}`, {
      responseType: 'blob',
    })
    .then((res) => {
      const href = URL.createObjectURL(res.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${name}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

const StorageItem = {
  get: {
    getStorageItems,
    getStorageItemById,
    getBookingHistory,
    getTargetFacilityList,
    generatePdf,
    getStorageItemDocuments,
    getStorageItemPdfById,
  },
  post: {
    bookStorageArea,
    createStorageItem,
  },
  put: {
    updateStorageItem,
    updateStorageItemsDeterminationClass,
    updateStorageItemsDeterminationUnit,
  },
  delete: {
    deleteStorageItemById,
  },
};

export default StorageItem;
