import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import ReactRouterPrompt from "react-router-prompt";
import { useTranslation } from "react-i18next";
import { ModalWrapper } from "../ModalWrapper";
import style from './style.module.scss';

const ExitConfirmationModal = ({ when }: { when: boolean }) => {
  const { t } = useTranslation();

  return (
    <ReactRouterPrompt when={when}>
      {({ isActive, onConfirm, onCancel }) =>
        isActive && (
          <ModalWrapper modalCloseHandler={onCancel}>
            <div className={style.wrapper}>
              <div className={style.title}>{t('Do you really want to leave?')}</div>
              <div className={style.hintText}>{t('Your changes have not been saved so as not to lose them, please save before exiting.')}</div>
              <div className={style.buttonWrapper}>
                <Button onClick={onCancel} className={style.cancelBtn}>
                  {t('Cancel')}
                </Button>
                <Button onClick={onConfirm} className={style.deleteBtn}>
                  {t('Ok')}
                </Button>
              </div>
            </div>
          </ModalWrapper>
        )
      }
    </ReactRouterPrompt>
  );
};

export default ExitConfirmationModal;
