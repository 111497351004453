import React, { FC } from 'react';
import style from './style.module.scss';

interface IButtonSpinner {
  isSmall?: boolean;
  isUpdaterSpinner?: boolean;
  className?: string;
}

export const ButtonSpinner: FC<IButtonSpinner> = ({ className = '', isSmall, isUpdaterSpinner }) => {
  return <div className={`${isSmall ? style.smallSpinner : style.spinner} ${isUpdaterSpinner ? style.updaterSpinner : ''} ${className}`}></div>;
};
