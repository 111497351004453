import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.scss';
import { Button } from '@progress/kendo-react-buttons';
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import { Svg, TableGrid } from '../../../../components';
import { GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { IStorageBody } from '../../../../store/slices/storageArea.slice';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import DetailButton from '../../../../components/DetailButton';

export type ComponentType = 'criteria' | 'class' | 'location' | 'loadingList';

export type ComponentTypeWithoutLoadingList = 'criteria' | 'class' | 'location';

interface IStorageSettingsTable {
  data: any[];
  headerTitle: string;
  btnHandler: (type?: ComponentTypeWithoutLoadingList) => void;
  btnText: string;
  type: ComponentType;
  items: {
    field: string;
    title: string;
    width?: string | undefined;
    headerCell?: any;
    cell?: any;
  }[];
  deleteHandler?: (data: IStorageBody) => void;
  updateHandler?: (data: IStorageBody) => void;
  detailsHandler?: (id: string) => void;
  deleteLoadingListHandler?: (id: string) => void;
  deleteStorageLocationHandler?: (id: string) => void;
  disableActions?: boolean;
  changeIsLocationRequiredValue?: (event: CheckboxChangeEvent) => void;
  isLocationRequired?: boolean;
  cellRender?: (
    defaultRendering: React.ReactElement<HTMLTableCellElement> | null,
    props: GridCellProps,
  ) => null | React.ReactElement<HTMLTableCellElement> | React.ReactElement<HTMLTableCellElement>[];
  isUpdatingDisabled?: boolean;
}

const StorageSettingsTable: FC<IStorageSettingsTable> = ({
  btnHandler,
  btnText,
  data,
  headerTitle,
  items,
  deleteHandler,
  updateHandler,
  detailsHandler,
  type,
  deleteLoadingListHandler,
  deleteStorageLocationHandler,
  disableActions,
  changeIsLocationRequiredValue,
  isLocationRequired,
  cellRender,
  isUpdatingDisabled = false,
}) => {
  const { t } = useTranslation();
  const [sort, setSort] = useState<SortDescriptor[]>([]);
  const isLoadingListType = type === 'loadingList';

  const buttonHandler = () => {
    if (isLoadingListType) {
      btnHandler();
    } else {
      btnHandler(type);
    }
  };

  const LastItem = (props: GridCellProps) => {
    return (
      <td
        className={`k-table-td ${style.linkCell}`}
        style={{
          border: 'none',
          ...props.style,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {!disableActions && (
            <>
              {!isLoadingListType && updateHandler && (
                <Svg
                  icon='updateIcon'
                  style={{
                    marginRight: '26px',
                    cursor: !props.dataItem.isAllowedToRemove ? '' : 'pointer',
                    opacity: !props.dataItem.isAllowedToRemove ? 0.4 : 1,
                  }}
                  onClick={() => {
                    if (!props.dataItem.isAllowedToRemove) {
                      return;
                    }
                    updateHandler({
                      storage: props.dataItem.storage,
                      maximumCapacity: props.dataItem.maximumCapacity,
                      name: props.dataItem.name,
                      number: props.dataItem.number,
                    });
                  }}
                />
              )}
              <Svg
                icon='redDeleteIcon'
                onClick={() => {
                  if (!props.dataItem.isAllowedToRemove) {
                    return;
                  }
                  if (isLoadingListType && deleteLoadingListHandler) {
                    deleteLoadingListHandler(props.dataItem.id);
                  } else if (deleteHandler) {
                    deleteHandler({
                      maximumCapacity: props.dataItem.maximumCapacity,
                      storage: props.dataItem.storage,
                    });
                  } else if (deleteStorageLocationHandler) {
                    deleteStorageLocationHandler(props.dataItem.id);
                  }
                }}
                style={{
                  cursor: !props.dataItem.isAllowedToRemove ? '' : 'pointer',
                  opacity: !props.dataItem.isAllowedToRemove ? 0.4 : 1,
                }}
              />
            </>
          )}
          {isLoadingListType && detailsHandler && (
            <div style={{ marginLeft: '26px' }}>
              <DetailButton actionHandle={() => detailsHandler(props.dataItem.id)} />
            </div>
          )}
        </div>
      </td>
    );
  };

  return (
    <div className={style.classSettingsWrapper}>
      <div className={style.headerWrapper}>
        <div className={style.headerTitle}>{t(headerTitle)}</div>
        {!disableActions && !isUpdatingDisabled ? (
          <Button
            className={style.newModelBtn}
            onClick={buttonHandler}
            type='button'
            style={{
              height: '40px !important',
            }}
          >
            <Svg icon='orangePlusIcon' />
            {t(btnText)}
          </Button>
        ) : null}
      </div>
      {type === 'location' && (
        <div style={{ marginBottom: '20px' }}>
          <Checkbox
            value={isLocationRequired}
            onChange={changeIsLocationRequiredValue}
            label={t('Location is required for booking') ?? ''}
            style={{
              width: '20px',
              height: '20px',
            }}
            className={isLocationRequired ? style.checkedCheckbox : ''}
          />
        </div>
      )}
      <TableGrid
        data={orderBy(data, sort)}
        items={items}
        withPager={false}
        tableSortChangeHandler={(e: Array<SortDescriptor>) => {
          e = e.map((sortObject) => {
            return data.length && Object.keys(data[0])?.includes(`${sortObject.field}Value`)
              ? { field: `${sortObject.field}Value`, dir: sortObject.dir }
              : sortObject;
          });
          setSort(e);
        }}
        customLastItem={
          <GridColumn
            cell={(props) => (
              <LastItem
                {...props}
                locked={true}
                style={{
                  backgroundColor: 'rgba(218, 218, 218, 0.5) !important',
                  borderLeft: '1px solid rgba(0, 0, 0, 0.08) !important',
                }}
              />
            )}
            width='100px'
            locked={true}
            headerClassName={style.headerClassName}
          />
        }
        customFirstItem={null}
        customTableHeight='inherit'
        cellRender={cellRender}
      />
    </div>
  );
};

export default StorageSettingsTable;
