import { ILoadingListItemRequestBody, ILoadingListItemResponseBody, ILoadingListResponse, StatusesType } from '../../store/slices/loadingList.slice';
import { adminInstance } from '../api';

const getLoadingList = ({
  page,
  limit,
  search,
  sortBy,
  sortOrder,
  statuses,
}: {
  page?: number;
  limit?: number;
  search?: string;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc' | '';
  statuses?: string[];
}) => {
  return adminInstance
    .get<ILoadingListResponse>(`api/v1/loading-list`, {
      params: {
        page,
        limit,
        search,
        sortBy,
        sortOrder,
        statuses: statuses?.length ?  statuses?.join(',') : undefined,
      },
    })
    .then((res) => res.data);
};

const getLoadingListById = (id: string) => {
  return adminInstance.get<ILoadingListItemResponseBody>(`api/v1/loading-list/${id}`).then((res) => res.data);
};

const createLoadingList = (data: ILoadingListItemRequestBody) => {
  return adminInstance.post<ILoadingListItemResponseBody>('api/v1/loading-list', data).then((res) => res.data);
};

const updateLoadingListById = (id: string, data: ILoadingListItemRequestBody) => {
  return adminInstance.put<ILoadingListItemResponseBody>(`api/v1/loading-list/${id}`, data).then((res) => res.data);
};

const deleteLoadingList = (ids: string[]) => {
  return adminInstance.delete(`api/v1/loading-list?ids=${ids.join(',')}`).then((res) => res.data);
};

const updateLoadingListStatus = (id: string, data: { status: StatusesType }) => {
  return adminInstance.put<ILoadingListItemResponseBody>(`api/v1/loading-list/status/${id}`, data).then((res) => res.data);
};

const loadItemList = (id: string, data: { loadedItemList: string[] }) => {
  return adminInstance.put<ILoadingListItemResponseBody>(`api/v1/loading-list/load/${id}`, data).then((res) => res.data);
};

const LoadingList = {
  get: {
    getLoadingList,
    getLoadingListById,
  },
  post: {
    createLoadingList,
  },
  put: {
    updateLoadingListById,
    updateLoadingListStatus,
    loadItemList,
  },
  delete: {
    deleteLoadingList,
  },
};

export default LoadingList;
