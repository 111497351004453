import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import API from '../../api/api';
import { warehouse } from '../../util/warehouse';
import { setIsModalActive } from './app.slice';
import { showNotification } from './notification.slice';
import { showErrorNotification } from '../../util/showErrorNotification';
import { reset } from '..';

export interface IWarehouseBody {
  _id?: string;
  name: string;
  description: string;
  konzernNr: string;
  firmaNr: string;
  betriebNr: string;
  isStorageItemCreatingAllowed?: boolean;
  connectedWarehouseId?: string;
}

export interface IWarehouse extends IWarehouseBody {
  id: string;
  connectedWarehouse?: IWarehouse;
}

interface IWarehouseState {
  warehousesData: IWarehouse[];
  warehouse: IWarehouse | null;
  isWarehousesDataLoading: boolean;
  isWarehousesDataGlobalLoading: boolean;
  isWarehousesDeleted: boolean;
  isWarehouseFirstLoading: boolean;
}

const initialState: IWarehouseState = {
  warehousesData: [],
  warehouse: null,
  isWarehousesDataLoading: false,
  isWarehousesDataGlobalLoading: false,
  isWarehousesDeleted: false,
  isWarehouseFirstLoading: true,
};

export const getWarehouseData = createAsyncThunk('warehouse/getWarehouseData', async (inFirst: boolean, thunkApi) => {
  try {
    if (inFirst) {
      thunkApi.dispatch(setIsWarehousesDataGlobalLoading(true));
    }
    const warehouseData = await API.warehouse.get.getWarehouseData();

    return warehouseData;
  } catch (error) {
    thunkApi.dispatch(showErrorNotification(error));
  } finally {
    if (inFirst) {
      thunkApi.dispatch(setIsWarehousesDataGlobalLoading(false));
    }
  }
});

export const createWarehouse = createAsyncThunk(
  'warehouse/createWarehouse',
  async ({ data, successMessage }: { data: IWarehouseBody; successMessage: string }, thunkApi) => {
    try {
      const warehouseData = await API.warehouse.post.createWarehouse(data);

      return warehouseData;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    } finally {
      thunkApi.dispatch(setIsModalActive(false));

      thunkApi.dispatch(
        showNotification({
          text: successMessage,
          type: 'success',
        }),
      );
    }
  },
);

export const getWarehouseById = createAsyncThunk('warehouse/getWarehouseById', async (id: string, thunkApi) => {
  try {
    const warehouse = await API.warehouse.get.getWarehouseById(id);

    return warehouse;
  } catch (error) {
    thunkApi.dispatch(showErrorNotification(error));
  }
});

export const deleteWarehouseById = createAsyncThunk(
  'warehouse/deleteWarehouseById',
  async ({ id, navigate, hide, successMessage }: { id: string; navigate: () => void; hide: () => void; successMessage: string }, thunkApi) => {
    try {
      await API.warehouse.delete.deleteWarehouseById(id);

      hide();
      setTimeout(() => {
        navigate();
      }, 1);

      thunkApi.dispatch(
        showNotification({
          text: successMessage,
          type: 'success',
        }),
      );

      return id;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const updateWarehouseById = createAsyncThunk(
  'warehouse/updateWarehouseById',
  async ({ id, data, successMessage }: { id: string; data: IWarehouseBody; successMessage: string }, thunkApi) => {
    try {
      const warehouseData = await API.warehouse.put.updateWarehouseById(id, data);

      thunkApi.dispatch(setIsModalActive(false));

      thunkApi.dispatch(
        showNotification({
          text: successMessage,
          type: 'success',
        }),
      );

      return warehouseData;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {
    setIsWarehousesDataGlobalLoading: (state, action: PayloadAction<boolean>) => {
      state.isWarehousesDataGlobalLoading = action.payload;
    },
    changeCurrentWarehouse: (state, action) => {
      const newSelectedWarehouse = state.warehousesData.find(({ id }) => id === action.payload.warehouseId);
      if (newSelectedWarehouse) {
        state.warehouse = newSelectedWarehouse;
        warehouse.set(newSelectedWarehouse.id);
      }
    },
    setIsWarehouseDataLoading: (state, action: PayloadAction<boolean>) => {
      state.isWarehousesDataLoading = action.payload;
    },
    setIsWarehouseDeleted: (state, action: PayloadAction<boolean>) => {
      state.isWarehousesDeleted = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWarehouseData.pending, (state) => {
      state.isWarehousesDataLoading = true;
    });
    builder.addCase(getWarehouseData.fulfilled, (state, action) => {
      if (action.payload?.length) {
        state.warehousesData = [...action.payload];
        const warehouseId = warehouse.get();
        if (warehouseId) {
          const currentWarehouse = action.payload.find(({ id }) => id === warehouseId);
          if (currentWarehouse) {
            state.warehouse = currentWarehouse;
          } else {
            state.warehouse = action.payload[0];
            warehouse.set(action.payload[0].id);
          }
        } else {
          state.warehouse = action.payload[0];
          warehouse.set(action.payload[0].id);
        }
      } else {
        warehouse.remove();
      }
      state.isWarehousesDataLoading = false;
    });
    builder.addCase(getWarehouseData.rejected, (state) => {
      state.isWarehousesDataLoading = false;
    });
    builder.addCase(createWarehouse.pending, (state) => {
      state.isWarehousesDataLoading = true;
    });
    builder.addCase(createWarehouse.fulfilled, (state, action) => {
      if (action.payload) {
        state.warehousesData = [...state.warehousesData, action.payload];
        if (!state.warehouse) {
          state.warehouse = action.payload;
          warehouse.set(action.payload.id);
        }
      }
      state.isWarehousesDataLoading = false;
    });
    builder.addCase(createWarehouse.rejected, (state) => {
      state.isWarehousesDataLoading = false;
    });
    builder.addCase(getWarehouseById.pending, (state) => {
      state.isWarehousesDataLoading = true;
    });
    builder.addCase(getWarehouseById.fulfilled, (state, action) => {
      if (action.payload) {
        state.warehouse = action.payload;
      }
      state.isWarehouseFirstLoading = false;
      state.isWarehousesDataLoading = false;
    });
    builder.addCase(getWarehouseById.rejected, (state) => {
      state.isWarehousesDataLoading = false;
    });
    builder.addCase(deleteWarehouseById.fulfilled, (state, action) => {
      state.warehousesData = state.warehousesData.filter(({ id }) => id !== action.payload);
      state.warehouse = state.warehousesData.length ? state.warehousesData[0] : null;
      state.isWarehousesDeleted = true;
      warehouse.remove();
    });
    builder.addCase(updateWarehouseById.pending, (state) => {
      state.isWarehousesDataLoading = true;
    });
    builder.addCase(updateWarehouseById.fulfilled, (state, action) => {
      if (action.payload) {
        state.warehousesData = [
          ...state.warehousesData.map((el) => {
            if (el.id === action.payload?.id) {
              return action.payload;
            }
            return el;
          }),
        ];
        state.warehouse = action.payload;
      }
      state.isWarehousesDataLoading = false;
    });
    builder.addCase(updateWarehouseById.rejected, (state) => {
      state.isWarehousesDataLoading = false;
    });
    builder.addCase(reset, () => {
      return initialState;
    });
  },
});

export const { changeCurrentWarehouse, setIsWarehousesDataGlobalLoading, setIsWarehouseDataLoading, setIsWarehouseDeleted } = warehouseSlice.actions;

export const warehouseReducer = warehouseSlice.reducer;
