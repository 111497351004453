import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../../api/api';
import { showErrorNotification } from '../../util/showErrorNotification';
import { showNotification } from './notification.slice';
import { reset } from '..';

export interface IUnitBody {
  name: string;
  description: string;
  abbreviation: string;
}

export interface IUnit extends IUnitBody {
  id: string;
}

export interface IUnitDataResponse {
  data: IUnit[];
  totalCount: number;
}

interface IUnitState {
  unit: IUnit | null;
  unitsData: IUnit[];
  unitsDataCount: number;
  isUnitDataLoading: boolean;
  isUnitDataFirstLoading: boolean;
  isUnitDeleted: boolean;
  isEmptyUnitDataList: boolean;
}

const initialState: IUnitState = {
  unit: null,
  unitsData: [],
  unitsDataCount: 0,
  isUnitDataLoading: false,
  isUnitDataFirstLoading: true,
  isUnitDeleted: false,
  isEmptyUnitDataList: false,
};

export const getUnits = async () => {
  try {
    return await API.unit.get.getUnits();
  } catch (error) {
    return [];
  }
};

export const getUnitsData = createAsyncThunk(
  'unit/getUnitsData',
  async (
    {
      page,
      limit,
      search,
      sortBy,
      sortOrder,
      isFirstTime,
    }: {
      page?: number;
      limit?: number;
      search?: string;
      sortBy?: string;
      sortOrder?: 'asc' | 'desc' | '';
      isFirstTime?: boolean;
    },
    thunkApi,
  ) => {
    try {
      const unitData = await API.unit.get.getUnits(page, limit, search, sortBy, sortOrder);

      return { unitData, isFirstTime };
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const getUnitsAreaData = createAsyncThunk('unit/getUnitsAreaData', async (_, thunkApi) => {
  try {
    const unitData = await API.unit.get.getUnits();

    return unitData;
  } catch (error) {
    thunkApi.dispatch(showErrorNotification(error));
  }
});

export const createUnit = createAsyncThunk(
  'unit/createUnit',
  async ({ data, navigate, successMessage }: { data: IUnitBody; navigate: () => void; successMessage: string }, thunkApi) => {
    try {
      await API.unit.post.createUnit(data);

      thunkApi.dispatch(
        showNotification({
          text: successMessage,
          type: 'success',
        }),
      );

      return navigate;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const updateUnit = createAsyncThunk(
  'unit/updateUnit',
  async ({ id, data, navigate, successMessage }: { id: string; data: IUnitBody; navigate: () => void; successMessage: string }, thunkApi) => {
    try {
      const storageClass = await API.unit.put.updateUnitById(id, data);

      thunkApi.dispatch(
        showNotification({
          text: successMessage,
          type: 'success',
        }),
      );

      navigate();

      return storageClass;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const getUnitById = createAsyncThunk('unit/getUnitById', async (id: string, thunkApi) => {
  try {
    const unitData = await API.unit.get.getUnitById(id);

    return unitData;
  } catch (error) {
    thunkApi.dispatch(showErrorNotification(error));
  }
});

export const deleteUnit = createAsyncThunk(
  'unit/deleteUnit',
  async (
    {
      ids,
      text,
      navigate,
      hide,
    }: {
      ids: string[];
      text: string;
      navigate?: () => void;
      hide?: () => void;
    },
    thunkApi,
  ) => {
    try {
      await API.unit.delete.deleteUnitById(ids);

      if (navigate) {
        navigate();
      }

      if (hide) {
        hide();
      }

      thunkApi.dispatch(
        showNotification({
          text,
          type: 'success',
        }),
      );

      return ids;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

const unitSlice = createSlice({
  name: 'unit',
  initialState,
  reducers: {
    clearUnitsData: (state) => {
      state.unitsData = [];
      state.unitsDataCount = 0;
    },
    clearUnit: (state) => {
      state.unit = null;
    },
    setIsUnitDataFirstLoading: (state, action: PayloadAction<boolean>) => {
      state.isUnitDataFirstLoading = action.payload;
    },
    setIsUnitDeleted: (state, action: PayloadAction<boolean>) => {
      state.isUnitDeleted = action.payload;
    },
    setIsEmptyUnitDataList: (state, action: PayloadAction<boolean>) => {
      state.isEmptyUnitDataList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUnitsData.pending, (state) => {
      state.isUnitDataLoading = true;
    });
    builder.addCase(getUnitsData.fulfilled, (state, action) => {
      if (action.payload?.unitData) {
        state.unitsData = [...(action.payload?.unitData as IUnitDataResponse).data];
        state.unitsDataCount = (action.payload?.unitData as IUnitDataResponse).totalCount;
      }
      if (action.payload?.isFirstTime) {
        state.isEmptyUnitDataList = (action.payload?.unitData as IUnitDataResponse).data.length ? false : true;
      }
      state.isUnitDataLoading = false;
      state.isUnitDataFirstLoading = false;
    });
    builder.addCase(getUnitsData.rejected, (state) => {
      state.isUnitDataLoading = false;
      state.isUnitDataFirstLoading = false;
    });
    builder.addCase(createUnit.pending, (state) => {
      state.isUnitDataLoading = true;
    });
    builder.addCase(createUnit.fulfilled, (state, action) => {
      state.isUnitDataLoading = false;
      if (action.payload) {
        action.payload();
      }
    });
    builder.addCase(createUnit.rejected, (state) => {
      state.isUnitDataLoading = false;
    });
    builder.addCase(updateUnit.pending, (state) => {
      state.isUnitDataLoading = true;
    });
    builder.addCase(updateUnit.fulfilled, (state, action) => {
      if (action.payload) {
        state.unit = action.payload;
      }
      state.isUnitDataLoading = false;
    });
    builder.addCase(updateUnit.rejected, (state) => {
      state.isUnitDataLoading = false;
    });
    builder.addCase(getUnitById.pending, (state) => {
      state.isUnitDataLoading = true;
    });
    builder.addCase(getUnitById.fulfilled, (state, action) => {
      if (action.payload) {
        state.unit = action.payload;
      }
      state.isUnitDataLoading = false;
    });
    builder.addCase(getUnitById.rejected, (state) => {
      state.isUnitDataLoading = false;
    });
    builder.addCase(deleteUnit.pending, (state) => {
      state.isUnitDataLoading = true;
    });
    builder.addCase(deleteUnit.fulfilled, (state, action) => {
      if (action.payload) {
        state.unitsData = [...state.unitsData.filter((i) => !action.payload?.includes(i.id) && i)];
      }
      state.isUnitDataLoading = false;
    });
    builder.addCase(deleteUnit.rejected, (state) => {
      state.isUnitDataLoading = false;
    });
    builder.addCase(getUnitsAreaData.fulfilled, (state, action) => {
      if (action.payload) {
        state.unitsData = [...(action.payload as IUnit[])];
      }
    });
    builder.addCase(reset, () => {
      return initialState;
    });
  },
});

export const { clearUnit, clearUnitsData, setIsUnitDataFirstLoading, setIsUnitDeleted, setIsEmptyUnitDataList } = unitSlice.actions;

export const unitReducer = unitSlice.reducer;
