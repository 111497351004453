import React from 'react';
import style from './style.module.scss';

export const Loader = () => {
  return (
    <div
      className={style.wrapper}
      style={{
        top: `${window.pageYOffset}px`,
      }}
    >
      <div className={style.spinner}>
        <div className={style.spinner__item1}></div>
        <div className={style.spinner__item2}></div>
        <div className={style.spinner__item3}></div>
        <div className={style.spinner__item4}></div>
      </div>
    </div>
  );
};

export const SmallLoader = () => {
  return (
    <div className={style.ldsEllipsis}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
