import React, { CSSProperties, FC } from 'react';
import svgRegister from '../../util/svgRegister';
interface ISvg {
  icon: string;
  width?: number;
  height?: number;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}
export const Svg: FC<ISvg> = ({ icon, className, height, style, width, onClick }) => {
  const params: any = {};

  if (className) {
    params.className = className;
  }
  if (height) {
    params.height = height;
  }
  if (width) {
    params.width = width;
  }
  if (style) {
    params.style = style;
  }

  const Component = svgRegister[`${icon}`] as any;

  if (!Component) {
    return <div>No icon</div>;
  }

  return (
    <Component
      {...params}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    />
  );
};
