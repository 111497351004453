import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.scss';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { Svg } from '..';

interface ISearchHeaderComponent {
  inputValue: string;
  inputChangeHandler: (event: InputChangeEvent) => void;
  selectedItems: string[];
  openModalHandler: () => void;
  navigate: () => void;
  addButtonText: string;
  withHeaderSearchButton?: boolean;
}

export const SearchHeaderComponent: FC<ISearchHeaderComponent> = ({
  addButtonText,
  inputChangeHandler,
  inputValue,
  navigate,
  openModalHandler,
  selectedItems,
  withHeaderSearchButton = true,
}) => {
  const { t } = useTranslation();

  return (
    <div className={style.wrapper}>
      <div className={style.inputWrapper}>
        <Input
          type='text'
          value={inputValue}
          onChange={inputChangeHandler}
          style={{
            padding: '7px 16px',
            outline: 'none',
            border: '1px solid #C8CED9',
            borderRadius: '6px',
          }}
          placeholder={t('Suchen..') || ''}
        />
        <Svg icon='searchIcon' className={style.icon} />
      </div>
      {withHeaderSearchButton ? (
        <div>
          {selectedItems.length ? (
            <Button className={style.deleteBtn} onClick={openModalHandler}>
              <Svg
                icon='deleteIcon'
                style={{
                  width: '16px',
                  height: '16px',
                }}
                className={style.img}
              />
              {t('Delete')}
              {` (${selectedItems.length})`}
            </Button>
          ) : null}
          <Button className={style.newModelBtn} onClick={navigate}>
            <Svg icon='plusIcon' />
            {t(addButtonText)}
          </Button>
        </div>
      ) : null}
    </div>
  );
};
