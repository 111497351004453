export const warehouse = {
  set: (id: string) => {
    localStorage.setItem('warehouse', id);
  },
  get: () => {
    return localStorage.getItem('warehouse');
  },
  remove: () => {
    localStorage.removeItem('warehouse');
  },
};
