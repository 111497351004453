import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalHeader, DropDown } from '../../../../components';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import style from './style.module.scss';
import { useAppSelector } from '../../../../store';
import { Button } from '@progress/kendo-react-buttons';
import { IStorageLocation } from '../../../../store/slices/storageLocation.slice';

interface ILocationModal {
  closeModalHandler: () => void;
  locationAddHandler: (data: Omit<IStorageLocation, 'storageAreaList'>) => void;
  selectedStorageLocationList: string[];
}

const LocationModal: FC<ILocationModal> = ({ closeModalHandler, locationAddHandler, selectedStorageLocationList }) => {
  const { t } = useTranslation();
  const locationList = useAppSelector((state) => state.storageLocation.storageLocationData);

  return (
    <div>
      <ModalHeader modalCloseHandler={closeModalHandler} title='Add Storage Location' />
      <Form
        onSubmitClick={({ values }) => {
          locationAddHandler({ ...values.selectedLocation, isAllowedToRemove: true });
          closeModalHandler();
        }}
        render={(props) => {
          const isDisabled = !props.valueGetter('selectedLocation')?.id;

          return (
            <FormElement>
              <fieldset
                style={{
                  border: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ padding: '0 20px' }}>
                  <div style={{ marginBottom: '20px' }}>
                    <div className={style.fieldTitle}>{t('Storage Location Number')}</div>
                    <Field
                      name='selectedLocation'
                      component={DropDown}
                      customProp={{
                        placeholder: 'Select Storage Location Number',
                        data: locationList.filter((currentLocation) => !selectedStorageLocationList.includes(currentLocation.id)),
                        textField: 'number',
                        dataItemKey: 'id',
                      }}
                    />
                  </div>
                </div>
                <div className={style.btnWrapper}>
                  <Button
                    type={'button'}
                    onClick={() => {
                      closeModalHandler();
                      props.onFormReset();
                    }}
                    className={style.cancelBtn}
                  >
                    {t('Abbrechen')}
                  </Button>
                  <Button type='submit' disabled={isDisabled} className={style.createBtn}>
                    {t('Add Storage Location')}
                  </Button>
                </div>
              </fieldset>
            </FormElement>
          );
        }}
      />
    </div>
  );
};

export default LocationModal;
