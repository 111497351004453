import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Svg } from '../../components';
import style from './style.module.scss';
import { userToken } from '../../util/userToken';

import { login, getUser } from '../../services/auth';
import { useAppDispatch } from '../../store';
import { showNotification } from '../../store/slices/notification.slice';
import { getUserInfo } from '../../store/slices/user.slice';

const Login = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const token = userToken.get();
      if (!token) {
        const user = await getUser();
        if (user?.profile?.name) {
          userToken.set(user.access_token);
          navigate('/warehouse');
        }
      } else {
        navigate('/warehouse');
      }
    })();
  }, []);

  return (
    <div className={style.formWrapper}>
      <div className={style.loginFormWrapper}>
        <div className={style.loginFormBlock}>
          <Svg icon='sensisLogo' className={style.loginFormImage} />
          <p className={style.loginFormHeader}>{t('Anmeldung')}</p>
          <p className={style.loginFormDescription}>{t('Willkommen zurück! Bitte geben Sie Ihre Daten ein.')}</p>
          <div className={style.loginFormAction}>
            <Button
              btnText={'Anmelden'}
              width={'100%'}
              onClick={async () => {
                try {
                  setLoading(true);
                  await login(navigate);
                  setLoading(false);
                } catch (e: any) {
                  dispatch(showNotification({ text: e.message, type: 'error' }));
                } finally {
                  setLoading(false);
                }
              }}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <div className={style.loginImageBlock}>
        <Svg icon='logo' className={style.loginImage} />
      </div>
    </div>
  );
};

export default Login;
