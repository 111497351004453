import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { WarehouseDataWrapper } from './components/WarehouseDataWrapper';
import { useAppDispatch, useAppSelector } from '../../store';
import { getWarehouseById } from '../../store/slices/warehouse.slice';
import { warehouse } from '../../util/warehouse';
import { HeaderComponent } from '../../components';

const WarehouseContent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const currentWarehouse = useAppSelector((state) => state.warehouse.warehouse);

  useEffect(() => {
    if (id) {
      dispatch(getWarehouseById(id));
      warehouse.set(id);
    }
  }, []);

  useEffect(() => {
    if (currentWarehouse?.id && id && currentWarehouse.id !== id) {
      navigate(`/warehouse/${currentWarehouse.id}/details`, { replace: false });
    }
  }, [currentWarehouse?.id]);

  return (
    <>
      <HeaderComponent />
      <WarehouseDataWrapper>
        <Outlet />
      </WarehouseDataWrapper>
    </>
  );
};

export default WarehouseContent;
