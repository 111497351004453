import { adminInstance, instance } from '../api';

const login = (email: string, password: string) => {
  return instance
    .post<string>('api/v1/signin', {
      email,
      password,
    })
    .then((res) => res.data);
};

const checkToken = (token: string) => {
  return adminInstance
    .post<string>('api/v1/signin/token', {
      token,
    })
    .then((res) => res.data);
};

const forgotPassword = (email: string) => {
  return instance.post(`api/v1/forgot/password`, { email }).then((res) => res.data);
};

const resetPassword = (token: string, password: string) => {
  return instance.post(`api/v1/forgot?token=${token}`, { password }).then((res) => res.data);
};

const Auth = {
  post: {
    login,
    checkToken,
    forgotPassword,
    resetPassword,
  },
};

export default Auth;
