import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { showErrorNotification } from '../../util/showErrorNotification';
import API from '../../api/api';
import { setIsModalActive } from './app.slice';
import { showNotification } from './notification.slice';
import { IStorageClass } from './storageClass.slice';
import { IStorageCriteria } from './storageCriteria.slice';
import { AxiosRequestConfig } from 'axios';
import { reset } from '..';

export interface IStorageItem {
  updatedAt: string;
  contractPartialNumber: string;
  contractNumber: string;
  kein_gefahrstoff_jn: boolean;
  postProcessingNecessary: boolean;
  probenahme_jn: boolean;
  sicherstellung_jn: boolean;
  umpacken_jn: boolean;
  su_jn: boolean;
  kommt_aus_su_jn: boolean;
  determinationUnitErrorMessage: string;
  determinationUnitError: boolean;
  storageLocationDetails: any;
  id: string;
  comment?: string;
  commentBookingProcess: string;
  commentStorageProcess: string;
  containerName: string;
  containerNumber: string;
  containerTara: string;
  containerType: string;
  customerName: string;
  date: string;
  number: string;
  quantity: string;
  storageArea: {
    id: string;
    name: string;
  };
  loadingList: null | string;
  storageClass: IStorageClass;
  storageCriteria: IStorageCriteria[];
  storageName: string;
  storageNumber: string;
  unit: string;
  user: string;
  wasteName: string;
  wasteNumber: string;
  targetFacility?: string;
  determinationClassError: boolean;
  determinationClassErrorMessage: string;
  anlagen_nr?: string;
  betrieb_nr?: string;
  firma_nr?: string;
  konzern_nr?: string;
  lieferschein_nr?: string;
  referenz_nr?: string;
  texture?: string;
  flashPoint?: string;
}

export interface IBookingHistory {
  updatedAt: string;
  storageArea: string;
  id: string;
  date: string;
  user: string;
  bookingType: string;
  bookingComment?: string;
  name?: string;
}

export interface IStorageItemDocument {
  updatedAt: string;
  id: string;
  date: string;
  name: string;
  user: string;
}

interface IStorageItemState {
  storageItemsData: IStorageItem[];
  storageItem: IStorageItem | null;
  storageItemsDataCount: number;
  isStorageItemDataLoading: boolean;
  isStorageItemDocumentsDataLoading: boolean;
  isFirstTimeStorageItemDataLoading: boolean;
  bookingHistory: IBookingHistory[];
  targetFacilityList: string[];
  isStorageItemUpdating: boolean;
  itemUpdaterKey: string;
  storageItemBookingHistoryCount: number;
  storageItemDocuments: IStorageItemDocument[];
  storageItemDocumentsCount: number;
  error?: string | null;
}

export interface IStorageItemConfig extends AxiosRequestConfig {
  id: string;
  successMessage: string;
  updaterKey?: string;
}

const initialState: IStorageItemState = {
  isStorageItemDataLoading: false,
  storageItem: null,
  storageItemsDataCount: 0,
  storageItemsData: [],
  isFirstTimeStorageItemDataLoading: true,
  bookingHistory: [],
  targetFacilityList: [],
  isStorageItemUpdating: false,
  itemUpdaterKey: '',
  storageItemBookingHistoryCount: 0,
  storageItemDocuments: [],
  isStorageItemDocumentsDataLoading: false,
  storageItemDocumentsCount: 0,
};

export const getStorageItems = createAsyncThunk(
  'storageItem/getStorageItems',
  async (
    {
      page,
      limit,
      search,
      sortBy,
      sortOrder,
      endDate,
      startDate,
      storageArea,
      storageObjectNumber,
      user,
      withStorageArea,
      withLoadingList,
      targetFacility,
    }: {
      page?: number;
      limit?: number;
      search?: string;
      sortBy?: string;
      sortOrder?: 'asc' | 'desc' | '';
      startDate?: string;
      endDate?: string;
      user?: string;
      storageObjectNumber?: string;
      storageArea?: string[];
      withStorageArea?: boolean;
      withLoadingList?: string | boolean;
      targetFacility?: string;
    },
    thunkApi,
  ) => {
    try {
      const storageItems = await API.storageItem.get.getStorageItems({
        page,
        limit,
        search,
        sortBy,
        sortOrder,
        endDate,
        startDate,
        storageArea,
        storageObjectNumber,
        user,
        withStorageArea,
        withLoadingList,
        targetFacility,
      });

      return storageItems;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const getStorageItemById = createAsyncThunk('storageItem/getStorageItemById', async (id: string, thunkApi) => {
  return await API.storageItem.get.getStorageItemById(id);
});

export const bookStorageArea = createAsyncThunk(
  'storageItem/bookStorageArea',
  async (
    {
      id,
      storageArea,
      storageLocation,
      commentBookingProcess,
      commentStorageProcess,
      navigate,
      removeItemFromList = true,
      successMessage,
      returnNewItem = false,
      updaterDataFunc,
    }: {
      id: string;
      successMessage: string;
      commentBookingProcess?: string;
      commentStorageProcess?: string;
      storageArea: string;
      storageLocation?: string;
      navigate?: () => void;
      removeItemFromList?: boolean;
      returnNewItem?: boolean;
      updaterDataFunc?: () => void;
    },
    thunkApi,
  ) => {
    try {
      const item = await API.storageItem.post.bookStorageArea({
        id,
        storageArea,
        storageLocation,
        commentBookingProcess,
        commentStorageProcess,
      });

      thunkApi.dispatch(
        showNotification({
          text: successMessage,
          type: 'success',
        }),
      );

      if (navigate) {
        navigate();
      }

      if (updaterDataFunc) {
        updaterDataFunc();
      }

      thunkApi.dispatch(setIsModalActive(false));

      if (returnNewItem) {
        return item;
      }

      if (removeItemFromList) {
        return id;
      }
    } catch (error) {
      console.log(error, 'error');
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const updateStorageItem = createAsyncThunk(
  'storageItem/updateStorageItem',
  async ({ data, config, updaterKey }: { data: IStorageItem; config: IStorageItemConfig; updaterKey?: string }, thunkApi) => {
    try {
      const storageItem = await API.storageItem.put.updateStorageItem(data, config.id);

      thunkApi.dispatch(
        showNotification({
          text: config.successMessage,
          type: 'success',
        }),
      );

      return { storageItem, updaterKey: config.updaterKey };
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const deleteStorageItemById = createAsyncThunk(
  'storageItem/deleteStorageItemById',
  async ({ id, text, navigate, hide }: { id: string; text: string; navigate?: () => void; hide?: () => void }, thunkApi) => {
    try {
      await API.storageItem.delete.deleteStorageItemById(id);

      if (hide) {
        hide();
      }

      if (navigate) {
        navigate();
      }
      thunkApi.dispatch(setIsModalActive(false));
      thunkApi.dispatch(
        showNotification({
          text,
          type: 'success',
        }),
      );
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const getBookingHistory = createAsyncThunk(
  'storageItem/getBookingHistory',
  async (
    {
      id,
      isFirstTime,
      limit,
      page,
      sortBy,
      sortOrder,
    }: { id: string; page?: number; limit?: number; sortBy?: string; sortOrder?: 'asc' | 'desc' | ''; isFirstTime?: boolean },
    thunkApi,
  ) => {
    try {
      const bookingHistory = await API.storageItem.get.getBookingHistory({ id, limit, page, sortBy, sortOrder });

      return bookingHistory;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const getTargetFacilityList = createAsyncThunk('storageItem/getTargetFacilityList', async (_, thunkApi) => {
  try {
    const targetFacilityList = await API.storageItem.get.getTargetFacilityList();

    return targetFacilityList;
  } catch (error) {
    thunkApi.dispatch(showErrorNotification(error));
  }
});

export const updateStorageItemsDeterminationClass = createAsyncThunk(
  'storageItem/updateStorageItemsDeterminationClass',
  async ({ id, determinationClassError }: { id: string; determinationClassError: boolean }, thunkApi) => {
    try {
      const storageItem = await API.storageItem.put.updateStorageItemsDeterminationClass({
        id,
        determinationClassError,
      });

      return storageItem;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const createStorageItem = createAsyncThunk(
  'storageItem/createStorageItem',
  async (
    {
      data,
    }: {
      data: {
        customerName: string;
        wasteNumber: string;
        wasteName: string;
        number: string;
        quantity: number;
        unit: string;
        containerNumber: string;
        containerType: string;
        containerTara: string;
        storageClass: string;
        storageCriteria: string[];
        targetFacility: string;
      };
      navigate: () => void;
    },
    thunkApi,
  ) => {
    try {
      const item = await API.storageItem.post.createStorageItem(data);

      return item;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const updateStorageItemsDeterminationUnit = createAsyncThunk(
  'storageItem/updateStorageItemsDeterminationUnit',
  async ({ id, determinationUnitError }: { id: string; determinationUnitError: boolean }, thunkApi) => {
    try {
      const storageItem = await API.storageItem.put.updateStorageItemsDeterminationUnit({
        id,
        determinationUnitError,
      });

      return storageItem;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const getStorageItemDocuments = createAsyncThunk(
  'storageItem/getStorageItemDocuments',
  async (
    {
      id,
      isFirstTime,
      limit,
      page,
      sortBy,
      sortOrder,
    }: { id: string; isFirstTime?: boolean; page?: number; limit?: number; sortBy?: string; sortOrder?: 'asc' | 'desc' | '' },
    thunkApi,
  ) => {
    try {
      const documents = await API.storageItem.get.getStorageItemDocuments({
        id,
        limit,
        page,
        sortBy,
        sortOrder,
      });

      return documents;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

const storageItemSlice = createSlice({
  name: 'storageItem',
  initialState,
  reducers: {
    clearStorageItemsData: (state) => {
      state.storageItemsData = [];
    },
    clearStorageItem: (state) => {
      state.storageItem = null;
    },
    setIsStorageItemLoading: (state, action: PayloadAction<boolean>) => {
      state.isStorageItemDataLoading = action.payload;
    },
    setIsFirstTimeStorageItemLoading: (state, action: PayloadAction<boolean>) => {
      state.isFirstTimeStorageItemDataLoading = action.payload;
    },
    clearBookingHistory: (state) => {
      state.bookingHistory = [];
      state.storageItemBookingHistoryCount = 0;
    },
    resetTargetFacilityList: (state) => {
      state.targetFacilityList = initialState.targetFacilityList;
    },
    setSelectedStorageItem: (state, action: PayloadAction<string>) => {
      state.storageItem = state.storageItemsData.find((el) => el.id === action.payload) as IStorageItem;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStorageItems.pending, (state) => {
      state.isStorageItemDataLoading = true;
    });
    builder.addCase(getStorageItems.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageItemsData = [...action.payload.data];
        state.storageItemsDataCount = action.payload.totalCount;
      }
      state.isStorageItemDataLoading = false;
      state.isFirstTimeStorageItemDataLoading = false;
    });
    builder.addCase(getStorageItems.rejected, (state) => {
      state.isStorageItemDataLoading = false;
      state.isFirstTimeStorageItemDataLoading = false;
    });
    builder.addCase(getStorageItemById.pending, (state) => {
      state.isStorageItemDataLoading = true;
      state.error = null;
    });
    builder.addCase(getStorageItemById.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageItem = action.payload;
      }
      state.isStorageItemDataLoading = false;
      state.error = null;
    });
    builder.addCase(getStorageItemById.rejected, (state, action) => {
      state.error = action.error.code;
      state.isStorageItemDataLoading = false;
    });
    builder.addCase(bookStorageArea.pending, (state) => {
      state.isStorageItemDataLoading = true;
    });
    builder.addCase(bookStorageArea.fulfilled, (state, action) => {
      if (typeof action.payload === 'string') {
        state.storageItemsData = [...state.storageItemsData.filter((el) => el.id !== action.payload)];
      } else if (action.payload) {
        state.storageItem = { ...action.payload };
      }
      state.isStorageItemDataLoading = false;
    });
    builder.addCase(bookStorageArea.rejected, (state) => {
      state.isStorageItemDataLoading = false;
    });
    builder.addCase(updateStorageItem.pending, (state, action) => {
      state.isStorageItemUpdating = true;
      if (action.meta.arg.updaterKey) {
        state.itemUpdaterKey = action.meta.arg.updaterKey;
      }
    });
    builder.addCase(updateStorageItem.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageItem = action.payload.storageItem;
      }
      state.isStorageItemUpdating = false;
      state.itemUpdaterKey = '';
    });
    builder.addCase(updateStorageItem.rejected, (state) => {
      state.isStorageItemUpdating = false;
      state.itemUpdaterKey = '';
    });
    builder.addCase(deleteStorageItemById.pending, (state) => {
      state.isStorageItemDataLoading = true;
    });
    builder.addCase(deleteStorageItemById.fulfilled, (state) => {
      state.isStorageItemDataLoading = false;
    });
    builder.addCase(deleteStorageItemById.rejected, (state) => {
      state.isStorageItemDataLoading = false;
    });
    builder.addCase(getBookingHistory.pending, (state, action) => {
      if (action.meta.arg.isFirstTime) {
        state.isStorageItemDataLoading = true;
      }
    });
    builder.addCase(getBookingHistory.fulfilled, (state, action) => {
      if (action.payload) {
        state.bookingHistory = [...action.payload.data];
        state.storageItemBookingHistoryCount = action.payload.totalCount;
      }
      state.isStorageItemDataLoading = false;
    });
    builder.addCase(getBookingHistory.rejected, (state) => {
      state.isStorageItemDataLoading = false;
    });
    builder.addCase(getTargetFacilityList.fulfilled, (state, action) => {
      if (action.payload?.length) {
        state.targetFacilityList = action.payload;
      }
    });
    builder.addCase(updateStorageItemsDeterminationClass.pending, (state) => {
      state.isStorageItemUpdating = true;
    });
    builder.addCase(updateStorageItemsDeterminationClass.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageItem = action.payload;
      }
      state.isStorageItemUpdating = false;
    });
    builder.addCase(updateStorageItemsDeterminationClass.rejected, (state) => {
      state.isStorageItemUpdating = false;
    });
    builder.addCase(createStorageItem.pending, (state) => {
      state.isStorageItemUpdating = true;
    });
    builder.addCase(createStorageItem.fulfilled, (state, action) => {
      state.isStorageItemUpdating = false;
      if (action.payload) {
        action.meta.arg.navigate();
      }
    });
    builder.addCase(createStorageItem.rejected, (state) => {
      state.isStorageItemUpdating = false;
    });
    builder.addCase(updateStorageItemsDeterminationUnit.pending, (state) => {
      state.isStorageItemUpdating = true;
    });
    builder.addCase(updateStorageItemsDeterminationUnit.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageItem = action.payload;
      }
      state.isStorageItemUpdating = false;
    });
    builder.addCase(updateStorageItemsDeterminationUnit.rejected, (state) => {
      state.isStorageItemUpdating = false;
    });
    builder.addCase(getStorageItemDocuments.pending, (state, action) => {
      if (action.meta.arg.isFirstTime) {
        state.isStorageItemDocumentsDataLoading = true;
      }
    });
    builder.addCase(getStorageItemDocuments.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageItemDocuments = action.payload.data;
        state.storageItemDocumentsCount = action.payload.totalCount;
      }
      state.isStorageItemDocumentsDataLoading = false;
    });
    builder.addCase(getStorageItemDocuments.rejected, (state) => {
      state.isStorageItemDocumentsDataLoading = true;
    });
    builder.addCase(reset, () => {
      return initialState;
    });
  },
});

export const {
  clearStorageItem,
  clearStorageItemsData,
  setIsStorageItemLoading,
  setIsFirstTimeStorageItemLoading,
  clearBookingHistory,
  resetTargetFacilityList,
  setSelectedStorageItem,
} = storageItemSlice.actions;

export const storageItemReducer = storageItemSlice.reducer;
