import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { LinkNavigationComponent, ModalWrapper, PulsLoader, Svg, TableGrid } from '../../components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { Button } from '@progress/kendo-react-buttons';
import { getStorageAreaBookingHistory } from '../../store/slices/storageAreaBookingHistory';
import { SortDescriptor } from '@progress/kendo-data-query';
import { format } from 'date-fns';
import { clearStorageArea, getStorageAreaById } from '../../store/slices/storageArea.slice';
import Modal from './components/Modal';
import { GridCellProps } from '@progress/kendo-react-grid';

const StorageAreaBookingHistory = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { areaId, id } = useParams();
  const navigate = useNavigate();
  const { storageAreaBookingHistory, storageAreaBookingHistoryTotalCount, isFirstTimeStorageAreaBookingHistoryLoading } = useAppSelector(
    (state) => state.storageAreaBookingHistory,
  );
  const { storageArea, isStorageAreaDataLoading } = useAppSelector((state) => state.storageArea);
  const [isModalActive, setIsModalActive] = useState(false);
  const [updater, setUpdater] = useState<any>(null);

  const dataHandler = ({
    sort,
    currentPage,
    itemsPerPage,
    isFirstTime,
  }: {
    sort?: Array<SortDescriptor>;
    itemsPerPage?: number;
    currentPage?: number;
    isFirstTime?: boolean;
  }) => {
    dispatch(
      getStorageAreaBookingHistory({
        limit: itemsPerPage,
        page: currentPage,
        sortBy: sort?.[0]?.field || '',
        sortOrder: sort?.[0]?.dir || '',
        storageArea: areaId,
        isFirstTime,
      }),
    );
  };

  const updaterHandler = (data: any) => {
    setUpdater(data);
  };

  useEffect(() => {
    if (areaId) {
      Promise.all([
        dispatch(getStorageAreaById(areaId)),
        dataHandler({
          currentPage: 1,
          itemsPerPage: 25,
          isFirstTime: true,
        }),
      ]);
    }

    return () => {
      dispatch(clearStorageArea());
    };
  }, []);

  useEffect(() => {
    if (storageArea && !storageArea?.storageAreaIsDissolveType) {
      navigate(`/warehouse/${id}/area`);
    }
  }, [storageArea]);

  return (
    <div>
      <LinkNavigationComponent
        navigate={() => {
          navigate(`/warehouse/${id}/area`, {
            replace: false,
          });
        }}
        listText='Storage Area List'
        detailText={`${t('Booking History')} - ${storageArea?.name}`}
        customStyle={{
          marginBottom: '20px',
          position: 'relative',
          zIndex: 2,
        }}
      />
      <div className={style.infoWrapper}>
        <div>
          <span className={style.currentQuantityText}>{t('Current Quantity')}: </span>
          <span className={style.quantityText}>
            {storageArea ? Number((storageArea?.maximumCapacity - storageArea?.freeCapacitySpace).toFixed(3)) : ''} t
          </span>
        </div>
        <Button
          className={style.newModelBtn}
          onClick={() => {
            setIsModalActive(true);
          }}
          type='button'
          style={{
            height: '32px !important',
          }}
        >
          <Svg icon='orangePlusIcon' />
          {t('Correcting Entry')}
        </Button>
      </div>
      <div>
        {isFirstTimeStorageAreaBookingHistoryLoading && isStorageAreaDataLoading? (
          <PulsLoader />
        ) : (
          <TableGrid
            data={storageAreaBookingHistory.map((el) => {
              return {
                ...el,
                date: format(new Date(el.date), 'dd.MM.yyyy HH:mm'),
                quantityAndUnit: el.storageItem
                  ? `${el.storageItem.quantity} ${el.storageItem.unit}`
                  : el.quantity && el.unit
                  ? `${el.quantity} ${el.unit?.toLowerCase()}`
                  : '',
                storageObject: el.storageItem ? el.storageItem.number : '-',
                bookingType: t(el.bookingType)
              };
            })}
            items={[
              {
                title: 'Booking Type',
                field: 'bookingType',
                width: '160px',
              },
              {
                title: 'Quantity & Unit',
                field: 'quantityAndUnit',
                width: '180px',
                cell: (props: GridCellProps) => {
                  return (
                    <td className={`k-table-td ${style.item} ${props.dataItem.bookingType === 'In' || props.dataItem.bookingType === 'Eingang' ? style.in : style.out}`}>
                      {props.dataItem.quantityAndUnit}
                    </td>
                  );
                },
              },
              {
                title: 'Storage Object',
                field: 'storageObject',
                width: '185px',
              },
              {
                title: 'Date&Time',
                field: 'date',
                width: '180px',
              },
              {
                title: 'User',
                field: 'user',
                width: '120px',
              },
              {
                title: 'Waste No.',
                field: 'storageItem.wasteNumber',
              },
              {
                title: 'Booking Comment',
                field: 'bookingComment',
              },
            ]}
            customFirstItem={null}
            customLastItem={null}
            customTableHeight={'calc(100vh - 358px)'}
            dataHandler={dataHandler}
            sortingKey='bookingHistory'
            saveSorting
            totalCount={storageAreaBookingHistoryTotalCount}
            dataUpdaterObject={updater}
          />
        )}
      </div>
      {isModalActive ? (
        <ModalWrapper
          modalCloseHandler={() => {
            setIsModalActive(false);
          }}
        >
          <Modal
            closeHandler={() => {
              setIsModalActive(false);
            }}
            updaterHandler={updaterHandler}
          />
        </ModalWrapper>
      ) : null}
    </div>
  );
};

export default StorageAreaBookingHistory;
