import React from 'react';
import { MultiViewCalendar } from '@progress/kendo-react-dateinputs';
import { DatePicker as KendoDatePicker } from '@progress/kendo-react-dateinputs';
import { ToggleButton, ToggleButtonProps } from "@progress/kendo-react-dateinputs";
import storageSearchCalendarIcon from '../../img/storageSearchCalendarIcon.svg';
import style from './style.module.scss';

export const DatePicker = (props: any) => {
  return (
    <KendoDatePicker
      {...props}
      placeholder="DD.MM.YYYY"
      className={style.dataPicker}
      toggleButton={(props: ToggleButtonProps) => {
        return <ToggleButton {...props} className={style.toggleButton} imageUrl={storageSearchCalendarIcon} />;
      }}
      format="dd.MM.yyyy"
      formatPlaceholder={{ year: "YYYY", month: "MM", day: "DD" }}
      calendar={(props: any) => {
        return <MultiViewCalendar {...props} views={1} />;
      }}
    />
  );
};
