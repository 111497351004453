import { lazy } from 'react';
import WarehouseContent from '../content/WarehouseContent';
import WarehouseDetails from '../pages/WarehouseDetails';
import StorageAreaBookingHistory from '../pages/StorageAreaBookingHistory';
import StorageAreaHandlerPage from '../pages/StorageAreaHandlerPage';
import StorageAreas from '../pages/StorageAreas';
import StorageClassDetails from '../pages/StorageClassDetails';
import StorageClasses from '../pages/StorageClasses';
import StorageCriteriaDetails from '../pages/StorageCriteriaDetails';
import StorageCriterias from '../pages/StorageCriterias';
import StorageLocation from '../pages/StorageLocation';
import StorageLocationDetails from '../pages/StorageLocationDetails';
import UnitDetails from '../pages/UnitDetails';
import Units from '../pages/Units';
import StorageAreaBookingHistoryOperations from '../pages/StorageAreaBookingHistoryOperations';
import { ROLES } from '../helpers/constants';

const Warehouses = lazy(() => import('../pages/Warehouses'));
const LoadingList = lazy(() => import('../pages/LoadingList'));
const LoadingListDetails = lazy(() => import('../pages/LoadingListDetails'));
const LoadingListDetailsFinished = lazy(() => import('../pages/LoadingListDetailsFinished'));
const StorageSearch = lazy(() => import('../pages/StorageSearch'));
const StorageSearchDetails = lazy(() => import('../pages/StorageSearchDetails'));
const ReceivingArea = lazy(() => import('../pages/ReceivingArea'));
const ReceivingAreaDetail = lazy(() => import('../pages/ReceivingAreaDetail'));
const FireBrigadeReport = lazy(() => import('../pages/FireBrigadeReport'));
const ReceivingAreaCreateItem = lazy(() => import('../pages/ReceivingAreaCreateItem'));
const StorageAreaOperations = lazy(() => import('../pages/StorageAreaOperations'));
const ActivityProtocol = lazy(() => import('../pages/ActivityProtocol'));

export interface RouteConfig {
  name: string;
  path: string;
  fallbackPath: string;
  element: React.ComponentType;
  roles?: string[];
  children?: RouteConfig[];
}

export const routerConfig: RouteConfig[] = [
  {
    name: 'Warehouse',
    path: 'warehouse',
    fallbackPath: 'receiving-area',
    element: Warehouses,
    roles: [ROLES.ADMIN, ROLES.MANAGEMENT],
  },
  {
    name: 'Warehouse Content',
    path: 'warehouse/:id',
    fallbackPath: 'receiving-area',
    element: WarehouseContent,
    roles: [ROLES.ADMIN, ROLES.MANAGEMENT],
    children: [
      {
        name: 'Root',
        path: '',
        fallbackPath: '',
        element: WarehouseDetails,
      },
      {
        name: 'Details',
        path: 'details',
        fallbackPath: 'details',
        element: WarehouseDetails,
      },
      {
        name: 'Units',
        path: 'units',
        fallbackPath: 'units',
        element: Units,
      },
      {
        name: 'New Unit',
        path: 'units/new-unit',
        fallbackPath: 'units/new-unit',
        element: UnitDetails,
      },
      {
        name: 'Unit',
        path: 'units/:unitId',
        fallbackPath: 'units/:unitId',
        element: UnitDetails,
      },
      {
        name: 'Classes',
        path: 'classes',
        fallbackPath: 'classes',
        element: StorageClasses,
      },
      {
        name: 'New Class',
        path: 'classes/new-class',
        fallbackPath: 'classes/new-class',
        element: StorageClassDetails,
      },
      {
        name: 'Class',
        path: 'classes/:classId',
        fallbackPath: 'classes/:classId',
        element: StorageClassDetails,
      },
      {
        name: 'Criterias',
        path: 'criteria',
        fallbackPath: 'criteria',
        element: StorageCriterias,
      },
      {
        name: 'New Criteria',
        path: 'criteria/new-criteria',
        fallbackPath: 'criteria/new-criteria',
        element: StorageCriteriaDetails,
      },
      {
        name: 'Criteria',
        path: 'criteria/:criteriaId',
        fallbackPath: 'criteria/:criteriaId',
        element: StorageCriteriaDetails,
      },
      {
        name: 'Location',
        path: 'location',
        fallbackPath: 'location',
        element: StorageLocation,
      },
      {
        name: 'New Location',
        path: 'location/new-location',
        fallbackPath: 'location/new-location',
        element: StorageLocationDetails,
      },
      {
        name: 'Location',
        path: 'location/:locationId',
        fallbackPath: 'location/:locationId',
        element: StorageLocationDetails,
      },
      {
        name: 'Area',
        path: 'area',
        fallbackPath: 'area',
        element: StorageAreas,
      },
      {
        name: 'New Area',
        path: 'area/new-area',
        fallbackPath: 'area/new-area',
        element: StorageAreaHandlerPage,
      },
      {
        name: 'Area',
        path: 'area/:areaId',
        fallbackPath: 'area/:areaId',
        element: StorageAreaHandlerPage,
      },
      {
        name: 'Booking History',
        path: 'booking-history',
        fallbackPath: 'booking-history',
        element: StorageAreaBookingHistory,
      },
    ],
  },
  {
    name: 'Receiving Area',
    path: 'receiving-area',
    fallbackPath: 'receiving-area',
    element: ReceivingArea,
  },
  {
    name: 'Receiving Area - New Item',
    path: 'receiving-area/new-item',
    fallbackPath: 'receiving-area/new-item',
    element: ReceivingAreaCreateItem,
  },
  {
    name: 'Receiving Area - Storage Item Detail',
    path: 'receiving-area/:storageItemId/*',
    fallbackPath: 'receiving-area/:storageItemId/*',
    element: ReceivingAreaDetail,
  },
  {
    name: 'Storage Search',
    path: 'storage-search',
    fallbackPath: 'storage-search',
    element: StorageSearch,
  },
  {
    name: 'Storage Search - Storage Item Detail',
    path: 'storage-search/:storageItemId/*',
    fallbackPath: 'storage-search/:storageItemId/*',
    element: StorageSearchDetails,
  },
  {
    name: 'Loading List',
    path: 'loading-list',
    fallbackPath: 'loading-list',
    element: LoadingList,
  },
  {
    name: 'Loading List - New Item',
    path: 'loading-list/new-item',
    fallbackPath: 'loading-list/new-item',
    element: LoadingListDetails,
  },
  {
    name: 'Loading List - Item Detail',
    path: 'loading-list/:itemId',
    fallbackPath: 'loading-list/:itemId',
    element: LoadingListDetails,
  },
  {
    name: 'Loading List - Finished',
    path: 'loading-list/:itemId/finished',
    fallbackPath: 'loading-list/:itemId/finished',
    element: LoadingListDetailsFinished,
  },
  {
    name: 'Storage Area',
    path: 'storage-area',
    fallbackPath: 'storage-area',
    element: StorageAreaOperations,
  },
  {
    name: 'Storage Area - Booking History',
    path: 'storage-area/:areaId/booking-history',
    fallbackPath: 'storage-area/:areaId/booking-history',
    element: StorageAreaBookingHistoryOperations,
  },
  {
    name: 'Brigade Report',
    path: 'brigade-report',
    fallbackPath: 'brigade-report',
    element: FireBrigadeReport,
  },
  {
    name: 'Activity Protocol',
    path: 'activity-protocol',
    fallbackPath: 'receiving-area',
    element: ActivityProtocol,
    roles: [ROLES.ADMIN, ROLES.MANAGEMENT],
  },
];
