import React, { CSSProperties, FC, MouseEvent } from 'react';
import style from './style.module.scss';

interface IModalWrapper {
  children: React.ReactNode;
  modalCloseHandler: () => void;
  customModalStyle?: CSSProperties;
}

export const ModalWrapper: FC<IModalWrapper> = ({
  children,
  modalCloseHandler,
  customModalStyle,
}) => {
  return (
    <div className={style.wrapper} onClick={modalCloseHandler}>
      <div
        style={{
          width: '100%',
          height: '100vh',
          position: 'relative',
        }}
      >
        <div
          className={style.modalWrapper}
          onClick={(e: MouseEvent<HTMLDivElement>) => e.stopPropagation()}
          style={{
            ...customModalStyle,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
