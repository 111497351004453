import axios, { InternalAxiosRequestConfig } from 'axios';
import { userToken } from '../util/userToken';
import Auth from './auth/auth.api';
import User from './user/user.api';
import Warehouse from './warehouse/warehouse.api';
import StorageClasses from './storageClasses/storageClasses.api';
import StorageCriteria from './storageCriteria/storageCriteria.api';
import StorageArea from './storageArea/storageArea.api';
import { warehouse } from '../util/warehouse';
import Unit from './unit/unit.api';
import StorageItem from './storageItem/storageItem.api';
import LoadingList from './loadingList/loadingList.api';
import StorageLocation from './storageLocation/storageLocation.api';
import BookingHistory from './bookingHistory/bookingHistory';
import { logout } from '../services/auth';
import { ActivityProtocol } from './activityProtocol/activityProtocol.api';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || window.location.origin,
});

export const adminInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || window.location.origin,
});

adminInstance.interceptors.request.use((config) => {
  const token = userToken.get();
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`,
      warehouse: warehouse.get(),
    },
  } as unknown as InternalAxiosRequestConfig;
});

adminInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      userToken.remove();
      if (process.env.REACT_APP_ENV === 'dev') {
        window.location.replace('/login');
        return;
      }
      await logout();
    }
    return Promise.reject(error);
  },
);

const API = {
  auth: Auth,
  user: User,
  warehouse: Warehouse,
  storageClasses: StorageClasses,
  storageCriteria: StorageCriteria,
  storageLocation: StorageLocation,
  storageArea: StorageArea,
  unit: Unit,
  storageItem: StorageItem,
  loadingList: LoadingList,
  bookingHistory: BookingHistory,
  activityProtocol: ActivityProtocol,
};

export default API;
