import React, { FC, ComponentType, CSSProperties, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridSortChangeEvent,
  GridRowProps,
  GridHeaderCellProps,
  GridDetailRowProps,
} from '@progress/kendo-react-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import style from './style.module.scss';
import { CustomTableGridPager, SearchHeaderComponent } from '..';
import { ReactComponent as AscArrow } from '../../img/ascArrow.svg';
import { ReactComponent as DescArrow } from '../../img/descArrow.svg';
import { Checkbox, InputChangeEvent } from '@progress/kendo-react-inputs';
import { DeletionModal } from '../../helpers/modals';
import { useNavigate } from 'react-router-dom';
import DetailBtnTableCell from '../DetailBtnTableCell';
import { LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import deMessages from './de.json';
import enMessages from './en.json';
import { IKey, storageSorting } from '../../util/localStorageSorting';

loadMessages(deMessages, 'de');
loadMessages(enMessages, 'en');

interface IData {
  data: any[];
  items: {
    field: string;
    title: string;
    width?: string;
    headerCell?: any;
    cell?: any;
  }[];
  customFirstItem?: JSX.Element | null;
  customLastItem?: JSX.Element | null;
  sortable?: boolean;
  tableSortChangeHandler?: (sort: Array<SortDescriptor>) => void; //tableSortChangeHandler is a method for using kendo ui default sort without api call
  totalCount?: number;
  withPager?: boolean;
  expandField?: string;
  detail?: ComponentType<GridDetailRowProps> | null;
  cellRender?: (
    defaultRendering: React.ReactElement<HTMLTableCellElement> | null,
    props: GridCellProps,
  ) => null | React.ReactElement<HTMLTableCellElement> | React.ReactElement<HTMLTableCellElement>[];
  isNested?: boolean;
  customStyle?: CSSProperties;
  customTableHeight?: string;
  dataHandler?: (data: { sort: Array<SortDescriptor>; itemsPerPage?: number; currentPage?: number; inputValue?: string }) => void;
  dataUpdaterObject?: {
    [key: string]: any;
  } | null;
  withHeaderSearch?: boolean;
  navigate?: () => void;
  addButtonText?: string;
  deleteFunc?: (hide: () => void, ids: string[]) => void;
  deleteText?: string;
  deleteTitle?: string;
  customSelectedItems?: string[];
  customSelectItemHandler?: (id: string) => void;
  selectAreaItemHandler?: (dataItem: any) => void;
  customAllItemsSelected?: boolean;
  customAllItemsSelectHandler?: () => void;
  withHeaderSearchButton?: boolean;
  customInputValue?: string;
  saveSorting?: boolean;
  sortingKey?: IKey;
  customDetail?: any;
  isKendoSortActive?: boolean;
  disabled?: boolean;
}

export const TableGrid: FC<IData> = ({
  data,
  items,
  customFirstItem,
  customLastItem,
  tableSortChangeHandler,
  sortable = false,
  totalCount,
  withPager = true,
  expandField,
  detail,
  cellRender,
  isNested,
  customStyle,
  customTableHeight,
  dataHandler,
  dataUpdaterObject = null,
  withHeaderSearch = false,
  navigate,
  addButtonText = '',
  deleteFunc,
  deleteText = '',
  deleteTitle = '',
  customAllItemsSelectHandler,
  customAllItemsSelected,
  customSelectItemHandler,
  customSelectedItems,
  selectAreaItemHandler,
  withHeaderSearchButton,
  customInputValue,
  saveSorting,
  sortingKey,
  customDetail,
  isKendoSortActive,
  disabled,
}) => {
  const navigateFunc = useNavigate();
  const { t, i18n } = useTranslation();
  const sortingData = sortingKey ? storageSorting.get(sortingKey) : null;
  const [inputValue, setInputValue] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState<Array<SortDescriptor>>(sortingData?.sort ?? []);
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [allItemsSelected, setAllItemsSelected] = useState(false);

  const allItemsSelectHandler = () => {
    if (customAllItemsSelectHandler) {
      customAllItemsSelectHandler();
      return;
    }
    if (!allItemsSelected) {
      setAllItemsSelected(true);
      const ids = data.map((i) => i.id);
      setSelectedItems([...ids]);
      return;
    }
    if (allItemsSelected) {
      setAllItemsSelected(false);
      setSelectedItems([]);
      return;
    }
  };

  const selectItemHandler = (id: string) => {
    if (customSelectItemHandler) {
      customSelectItemHandler(id);
      return;
    }
    if (selectedItems.includes(id)) {
      const t = selectedItems.filter((i) => i !== id);
      return setSelectedItems([...t]);
    }
    if (!selectedItems.includes(id)) {
      return setSelectedItems((prev) => [...prev, id]);
    }
  };

  const inputChangeHandler = (e: InputChangeEvent) => {
    setInputValue(e.target.value as string);
  };

  const onSortChange = (sort: SortDescriptor[]) => {
    if (saveSorting && sortingKey) {
      if (!sort[0].dir) {
        storageSorting.remove(sortingKey);
      } else {
        storageSorting.set({ key: sortingKey, data: { ...sortingData, sort } });
      }
    }
    setSort(sort);
  };

  const itemsPerPageHandler = (val: number) => {
    setItemsPerPage(val);
    setCurrentPage(1);
  };

  const currentPageHandler = (val: number) => {
    setCurrentPage(val);
  };

  const deleteHandler = (hide: () => void, ids: string[]) => {
    if (deleteFunc) {
      deleteFunc(hide, ids);
      setSelectedItems([]);
    }
  };

  const openModalHandler = () => {
    DeletionModal({
      deleteHandler,
      deleteText,
      deleteTitle,
      ids: customSelectedItems?.length ? customSelectedItems : selectedItems,
    });
  };

  useEffect(() => {
    let t: NodeJS.Timeout;
    if (dataHandler && !isFirstLoading) {
      if (inputValue?.length || customInputValue) {
        t = setTimeout(() => {
          dataHandler({ sort, itemsPerPage, currentPage, inputValue: inputValue || customInputValue });
        }, 300);
      } else {
        dataHandler({ sort, itemsPerPage, currentPage, inputValue: inputValue || customInputValue });
      }
    }
    setIsFirstLoading(false);

    return () => {
      clearTimeout(t);
    };
  }, [sort, itemsPerPage, currentPage, inputValue, customInputValue]);

  useEffect(() => {
    if (dataHandler && dataUpdaterObject !== null) {
      dataHandler({ sort, itemsPerPage, currentPage, inputValue: inputValue || customInputValue });
    }
  }, [dataUpdaterObject]);

  useEffect(() => {
    return () => {
      setIsFirstLoading(true);
    };
  }, []);

  useEffect(() => {
    if (customSelectedItems) {
      return;
    }
    if (data.length && selectedItems.length === data.length) {
      setAllItemsSelected(true);
    } else {
      setAllItemsSelected(false);
    }
  }, [selectedItems]);

  useEffect(() => {
    document.querySelectorAll('[data-customtable]').forEach((el) => el.setAttribute('colspan', '9'));
  }, []);

  const FirstItem = (props: GridCellProps) => {
    let isForbidden = false;

    if (Object.hasOwn(props.dataItem, 'canBeChanged')) {
      isForbidden = !props.dataItem.canBeChanged;
    }

    return (
      <td className={style.checkboxCell}>
        <Checkbox
          onClick={() => {
            if (isForbidden || disabled) {
              return;
            }
            if (selectAreaItemHandler) {
              selectAreaItemHandler(props.dataItem);
            } else {
              selectItemHandler(props.dataItem.id);
            }
          }}
          checked={
            selectedItems.length || customSelectedItems?.length ? (customSelectedItems ?? selectedItems).includes(props.dataItem.id) : undefined
          }
          style={{
            width: '20px',
            height: '20px',
          }}
          className={`${
            (selectedItems || customSelectedItems) && (customSelectedItems ?? selectedItems).includes(props.dataItem.id) ? style.checkedCheckbox : ''
          } ${isForbidden ? style.forbiddenCheckbox : ''}`}
          value={false}
          title={isForbidden ? t("Storage Area with items can't be deleted") ?? '' : ''}
        />
      </td>
    );
  };

  const FirstHeaderItem = (props: GridHeaderCellProps) => {
    return (
      <>
        <Checkbox
          onClick={allItemsSelectHandler}
          checked={customAllItemsSelected ? customAllItemsSelected : allItemsSelected}
          style={{
            width: '20px',
            height: '20px',
          }}
          value={customSelectedItems?.length && customAllItemsSelected === false ? null : selectedItems.length && !allItemsSelected ? null : ''}
          className={`${
            customAllItemsSelected || customSelectedItems?.length
              ? style.checkedCheckbox
              : allItemsSelected || selectedItems?.length
              ? style.checkedCheckbox
              : ''
          }`}
        />
      </>
    );
  };

  return (
    <>
      {withHeaderSearch ? (
        <SearchHeaderComponent
          addButtonText={addButtonText}
          inputChangeHandler={inputChangeHandler}
          inputValue={inputValue}
          navigate={() => {
            if (navigate) {
              navigate();
            }
          }}
          openModalHandler={() => {
            if (openModalHandler) {
              openModalHandler();
            }
          }}
          selectedItems={customSelectedItems?.length ? customSelectedItems : selectedItems.length ? selectedItems : []}
          withHeaderSearchButton={withHeaderSearchButton}
        />
      ) : null}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          ...customStyle,
        }}
      >
        <LocalizationProvider language={i18n.language}>
          <Grid
            style={{
              borderRadius: '6px',
              border: 'none',
              height: customTableHeight ? customTableHeight : isNested ? '' : 'calc(100vh - 312px)',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
            data={
              isKendoSortActive
                ? orderBy(data, sort)
                : data.map((i) => {
                    return { ...i, isIncluded: (customSelectedItems ?? selectedItems)?.includes(i.id) };
                  })
            }
            detail={detail}
            sortable={sortable}
            sort={sort}
            expandField={expandField}
            onSortChange={(e: GridSortChangeEvent) => {
              if (onSortChange) {
                onSortChange(e.sort);
              }
            }}
            reorderable={true}
            rowRender={(row, props: GridRowProps) => {
              const isDisabled = props.dataItem.isSelectForbidden || props.dataItem.isSelectItemForbidden;
              const withDeterminationError = props.dataItem.withDeterminationError;
              const isLastItem = props.dataItem.isLastItem;

              return (
                <>
                  <tr
                    aria-rowindex={props.ariaRowIndex}
                    data-is-selected={props.dataItem.isIncluded ? 'selected' : ''}
                    className={`k-table-row k-master-row ${isDisabled ? style.disabledRow : ''} ${
                      withDeterminationError ? style.withDeterminationError : ''
                    } ${props.dataItem.withCustomDetail && customDetail ? style.nestedTableRow : ''}
                    ${isLastItem ? style.lastItem : ''}`}
                    role='row'
                    data-grid-row-index={props.dataIndex}
                  >
                    {props.children}
                  </tr>
                  {props.dataItem.withCustomDetail && customDetail ? (
                    <tr
                      style={{
                        width: '100%',
                      }}
                      className={style.nestedTableTr}
                    >
                      <td data-customtable className={style.nestedTableWrapper}>
                        {customDetail(props)}
                      </td>
                    </tr>
                  ) : null}
                </>
              );
            }}
            cellRender={cellRender}
          >
            {customFirstItem === null ? null : customFirstItem ? (
              customFirstItem
            ) : (
              <GridColumn
                cell={(props) => <FirstItem {...props} />}
                width='60px'
                headerCell={(props) => <FirstHeaderItem {...props} />}
                headerClassName={style.customHeaderCell}
              />
            )}
            {items.map((i, idx) => {
              return (
                <GridColumn
                  field={i.field}
                  title={i.title}
                  key={idx}
                  reorderable={false}
                  width={i.width ? i.width : ''}
                  headerClassName={style.titleText}
                  className={style.itemText}
                  cell={
                    i.cell
                      ? (props: GridCellProps) => {
                          return i.cell(props);
                        }
                      : undefined
                  }
                  headerCell={(props: GridHeaderCellProps) => {
                    const generateArrowColor = (arrowDir: 'asc' | 'desc') => {
                      switch (true) {
                        case sort?.length && sort[0].field === props.field && sort[0].dir === 'asc': {
                          return arrowDir === 'asc' ? '#56534D' : 'rgba(86, 83, 77, 0.2)';
                        }
                        case sort?.length && sort[0].field === props.field && sort[0].dir === 'desc': {
                          return arrowDir === 'desc' ? '#56534D' : 'rgba(86, 83, 77, 0.2)';
                        }
                        default: {
                          return '#56534D';
                        }
                      }
                    };

                    return (
                      <div
                        className={style.headerCell}
                        key={props.field || Math.floor(Math.random() * 1000000)}
                        onClick={() => {
                          const generateDirection = () => {
                            switch (true) {
                              case !sort?.length: {
                                return 'asc';
                              }
                              case sort?.length && sort[0].field !== props.field: {
                                return 'asc';
                              }
                              case sort?.length && sort[0].dir === 'asc': {
                                return 'desc';
                              }
                              case sort?.length && sort[0].dir === 'desc': {
                                return undefined;
                              }
                              case sort?.length && sort[0].dir === undefined: {
                                return 'asc';
                              }
                              default: {
                                return undefined;
                              }
                            }
                          };
                          if (tableSortChangeHandler) {
                            tableSortChangeHandler([
                              {
                                field: props.field ? props.field : '',
                                dir: generateDirection(),
                              },
                            ]);
                          }
                          onSortChange([
                            {
                              field: props.field ? props.field : '',
                              dir: generateDirection(),
                            },
                          ]);
                        }}
                      >
                        <span>{t(props.title || '')}</span>
                        <div className={style.arrowWrapper}>
                          <AscArrow color={generateArrowColor('asc')} height={'10px'} />
                          <DescArrow color={generateArrowColor('desc')} height={'10px'} />
                        </div>
                      </div>
                    );
                  }}
                />
              );
            })}
            {customLastItem === null ? null : customLastItem ? (
              customLastItem
            ) : (
              <GridColumn
                cell={(props) => (
                  <DetailBtnTableCell
                    {...props}
                    locked={true}
                    onClick={() => navigateFunc(props.dataItem.id)}
                    style={{
                      backgroundColor: 'rgba(218, 218, 218, 0.5) !important',
                      borderLeft: '1px solid rgba(0, 0, 0, 0.08) !important',
                    }}
                  />
                )}
                className={style.qwe}
                width='66px'
                locked={true}
                headerClassName={style.headerClassName}
              />
            )}
          </Grid>
        </LocalizationProvider>
        {withPager ? (
          <CustomTableGridPager
            itemsPerPage={itemsPerPage}
            itemsPerPageHandler={itemsPerPageHandler}
            page={currentPage}
            setPage={currentPageHandler}
            totalCount={totalCount}
            totalPages={Math.ceil((totalCount || 1) / (itemsPerPage || 1))}
          />
        ) : null}
      </div>
    </>
  );
};
