import React, { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar, PulsLoader, DeletionModal } from '../../components';
import ProtectedRoute from '../../hoc/ProtectedRoute';
import style from './style.module.scss';
import { useAppDispatch, useAppSelector } from '../../store';
import { getWarehouseData } from '../../store/slices/warehouse.slice';
import { userToken } from '../../util/userToken';

const MainContent = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.warehouse.isWarehousesDataGlobalLoading);

  useEffect(() => {
    const token = userToken.get();
    if (token) {
      dispatch(getWarehouseData(true));
    }
  }, []);

  return (
    <ProtectedRoute>
      <div className={style.contentWrapper}>
        <Sidebar />
        <div
          style={{
            width: 'calc(100vw - 234px)',
            position: 'relative',
          }}
        >
          <Suspense fallback={<PulsLoader />}>
            {isLoading ? <PulsLoader /> : <Outlet />}
            <DeletionModal ref={(ref) => ((global as any)['deletionModal'] = ref)} />
          </Suspense>
        </div>
      </div>
    </ProtectedRoute>
  );
};

export default MainContent;
