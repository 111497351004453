import React, { CSSProperties, FC } from 'react';
import style from './style.module.scss';

interface IPulsLoader {
  customStyle?: CSSProperties;
  circleCustomStyle?: CSSProperties;
}

export const PulsLoader: FC<IPulsLoader> = ({ customStyle, circleCustomStyle }) => {
  return (
    <div
      className={style.wrapper}
      style={{
        ...customStyle,
      }}
    >
      <div
        className={style.circle}
        style={{
          ...circleCustomStyle,
        }}
      >
        <div className={style.spinner}></div>
        <div className={style.pulse}></div>
      </div>
    </div>
  );
};
