import { IUnit, IUnitBody, IUnitDataResponse } from '../../store/slices/unit.slice';
import { adminInstance } from '../api';

const getUnits = (page?: number, limit?: number, search?: string, sortBy?: string, sortOrder?: 'asc' | 'desc' | '') => {
  return adminInstance
    .get<IUnitDataResponse | IUnit[]>(`api/v1/unit`, {
      params: {
        page,
        limit,
        search,
        sortBy,
        sortOrder,
      },
    })
    .then((res) => res.data);
};

const getUnitById = (id: string) => {
  return adminInstance.get<IUnit>(`api/v1/unit/${id}`).then((res) => res.data);
};

const createUnit = (data: IUnitBody) => {
  return adminInstance.post<IUnit>(`api/v1/unit`, data).then((res) => res.data);
};

const updateUnitById = (id: string, data: IUnitBody) => {
  return adminInstance.put<IUnit>(`api/v1/unit/${id}`, data).then((res) => res.data);
};

const deleteUnitById = (ids: string[]) => {
  return adminInstance.delete(`api/v1/unit`, { params: { ids } }).then((res) => res.data);
};

const Unit = {
  get: {
    getUnits,
    getUnitById,
  },
  post: {
    createUnit,
  },
  put: {
    updateUnitById,
  },
  delete: {
    deleteUnitById,
  },
};

export default Unit;
