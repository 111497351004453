import axios from 'axios';
import { t } from 'i18next';
import constants from '../helpers/constants';
import { showNotification } from '../store/slices/notification.slice';

export const showErrorNotification = (error: any) => {
  const defaultErrorMessage = t(constants.unexpectedError);
  const message = error?.response?.data?.message || (axios.isAxiosError(error) ? error.message : defaultErrorMessage);
  const details = error?.response?.data?.details || {};
  return showNotification({
    text: t(message, details) as any,
    type: 'error',
  });
};
