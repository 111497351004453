import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../../api/api';
import { IUnit } from './unit.slice';
import { setIsModalActive } from './app.slice';
import { showNotification } from './notification.slice';
import { showErrorNotification } from '../../util/showErrorNotification';
import { reset } from '..';

export interface IStorageClassBody {
  name: string;
  description: string;
  number: string;
  unit: IUnit[];
  maximumCapacity: number;
}

export interface IStorageClass extends IStorageClassBody {
  id: string;
}

export interface IStorageClassDataResponse {
  data: IStorageClass[];
  totalCount: number;
}

interface IStorageClassState {
  storageClassesData: IStorageClass[];
  storageClassesDataCount: number;
  storageClass: IStorageClass | null;
  isStorageClassDataLoading: boolean;
  isStorageClassModalActive: boolean;
  isStorageClassDataFirstLoading: boolean;
  isStorageClassDeleted: boolean;
  isEmptyStorageClassDataList: boolean;
}

const initialState: IStorageClassState = {
  storageClassesData: [],
  storageClassesDataCount: 0,
  storageClass: null,
  isStorageClassDataLoading: false,
  isStorageClassModalActive: false,
  isStorageClassDataFirstLoading: true,
  isStorageClassDeleted: false,
  isEmptyStorageClassDataList: false,
};

export const getStorageClassesData = createAsyncThunk(
  'storageClass/getStorageClassesData',
  async (
    {
      page,
      limit,
      search,
      sortBy,
      sortOrder,
      isFirstTime,
    }: {
      page?: number;
      limit?: number;
      search?: string;
      sortBy?: string;
      sortOrder?: 'asc' | 'desc' | '';
      isFirstTime?: boolean;
    },
    thunkApi,
  ) => {
    try {
      const storageClassesData = await API.storageClasses.get.getStorageClasses({ page, limit, search, sortBy, sortOrder });

      return { storageClassesData, isFirstTime };
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const getStorageClasses = async () => {
  try {
    return await API.storageClasses.get.getStorageClasses({});
  } catch (error) {
    return [];
  }
};

export const getStorageClassesAreaData = createAsyncThunk(
  'storageClass/getStorageClassesAreaData',
  async ({ sortBy, sortOrder }: { sortBy?: string; sortOrder?: 'asc' | 'desc' | undefined }, thunkApi) => {
    try {
      const storageClassesData = await API.storageClasses.get.getStorageClasses({ sortBy, sortOrder });

      return storageClassesData;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const createStorageClass = createAsyncThunk(
  'storageClass/createStorageClass',
  async ({ data, navigate, successMessage }: { data: IStorageClassBody; navigate: () => void; successMessage: string }, thunkApi) => {
    try {
      await API.storageClasses.post.createStorageClass(data);

      thunkApi.dispatch(
        showNotification({
          text: successMessage,
          type: 'success',
        }),
      );

      navigate();
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const updateStorageClass = createAsyncThunk(
  'storageClass/updateStorageClass',
  async ({ id, data, navigate, successMessage }: { id: string; data: IStorageClassBody; navigate: () => void; successMessage: string }, thunkApi) => {
    try {
      const storageClass = await API.storageClasses.put.updateStorageClassById(id, data);

      thunkApi.dispatch(
        showNotification({
          text: successMessage,
          type: 'success',
        }),
      );

      navigate();

      return storageClass;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const getStorageClassById = createAsyncThunk('storageClass/getStorageClassById', async (id: string, thunkApi) => {
  try {
    const storageClassesData = await API.storageClasses.get.getStorageClassById(id);

    return storageClassesData;
  } catch (error) {
    thunkApi.dispatch(showErrorNotification(error));
  }
});

export const deleteStorageClasses = createAsyncThunk(
  'storageClass/deleteStorageClasses',
  async ({ ids, text, navigate, hide }: { ids: string[]; text: string; navigate?: () => void; hide?: () => void }, thunkApi) => {
    try {
      await API.storageClasses.delete.deleteStorageClassById(ids);

      if (hide) {
        hide();
      }

      if (navigate) {
        navigate();
      }

      thunkApi.dispatch(setIsModalActive(false));
      thunkApi.dispatch(
        showNotification({
          text,
          type: 'success',
        }),
      );

      return ids;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

const storageClassSlice = createSlice({
  name: 'storageClass',
  initialState,
  reducers: {
    clearStorageClassesData: (state) => {
      state.storageClassesData = [];
    },
    setStorageClassModalActive: (state, action: PayloadAction<boolean>) => {
      state.isStorageClassModalActive = action.payload;
    },
    clearStorageClass: (state) => {
      state.storageClass = null;
    },
    setIsStorageClassDataFirstLoading: (state, action: PayloadAction<boolean>) => {
      state.isStorageClassDataFirstLoading = action.payload;
    },
    setIsStorageClassDeleted: (state, action: PayloadAction<boolean>) => {
      state.isStorageClassDeleted = action.payload;
    },
    setIsEmptyStorageClassDataList: (state, action: PayloadAction<boolean>) => {
      state.isEmptyStorageClassDataList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStorageClassesData.pending, (state) => {
      state.isStorageClassDataLoading = true;
    });
    builder.addCase(getStorageClassesData.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageClassesData = [...(action.payload.storageClassesData as IStorageClassDataResponse).data];
        state.storageClassesDataCount = (action.payload.storageClassesData as IStorageClassDataResponse).totalCount;
      }
      if (action.payload?.isFirstTime) {
        state.isEmptyStorageClassDataList = (action.payload?.storageClassesData as IStorageClassDataResponse).data.length ? false : true;
      }

      state.isStorageClassDataFirstLoading = false;
      state.isStorageClassDataLoading = false;
    });
    builder.addCase(getStorageClassesData.rejected, (state) => {
      state.isStorageClassDataLoading = false;
      state.isStorageClassDataFirstLoading = false;
    });
    builder.addCase(createStorageClass.pending, (state) => {
      state.isStorageClassDataLoading = true;
    });
    builder.addCase(createStorageClass.fulfilled, (state) => {
      state.isStorageClassDataLoading = false;
    });
    builder.addCase(createStorageClass.rejected, (state) => {
      state.isStorageClassDataLoading = false;
    });
    builder.addCase(updateStorageClass.pending, (state) => {
      state.isStorageClassDataLoading = true;
    });
    builder.addCase(updateStorageClass.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageClass = action.payload;
      }
      state.isStorageClassDataLoading = false;
    });
    builder.addCase(updateStorageClass.rejected, (state) => {
      state.isStorageClassDataLoading = false;
    });
    builder.addCase(getStorageClassById.pending, (state) => {
      state.isStorageClassDataLoading = true;
    });
    builder.addCase(getStorageClassById.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageClass = action.payload;
      }
      state.isStorageClassDataLoading = false;
    });
    builder.addCase(getStorageClassById.rejected, (state) => {
      state.isStorageClassDataLoading = false;
    });
    builder.addCase(deleteStorageClasses.pending, (state) => {
      state.isStorageClassDataLoading = true;
    });
    builder.addCase(deleteStorageClasses.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageClassesData = [...state.storageClassesData.filter((i) => !action.payload?.includes(i.id) && i)];
      }
      state.isStorageClassDataLoading = false;
    });
    builder.addCase(deleteStorageClasses.rejected, (state) => {
      state.isStorageClassDataLoading = false;
    });
    builder.addCase(getStorageClassesAreaData.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageClassesData = [...(action.payload as IStorageClass[])];
      }
    });
    builder.addCase(reset, () => {
      return initialState;
    });
  },
});

export const {
  clearStorageClassesData,
  setStorageClassModalActive,
  clearStorageClass,
  setIsStorageClassDeleted,
  setIsStorageClassDataFirstLoading,
  setIsEmptyStorageClassDataList,
} = storageClassSlice.actions;

export const storageClassReducer = storageClassSlice.reducer;
