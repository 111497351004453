import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.scss';
import { useAppSelector } from '../../../../store';
import { NavLink } from 'react-router-dom';

interface ILinkWrapper {
  tabs: { name: string; route: string; activeLinkPadding: string }[];
}

const LinkWrapper: FC<ILinkWrapper> = ({ tabs }) => {
  const { t } = useTranslation();
  const isModalActive = useAppSelector((state) => state.app.client.isModalActive);

  return (
    <div
      className={style.linkWrapper}
      style={{
        filter: isModalActive ? 'blur(2px)' : '',
      }}
    >
      {tabs.map((tab) => {
        return (
          <NavLink
            to={tab.route}
            key={tab.route}
            className={({ isActive }) => {
              return isActive ? style.activeLink : style.inactiveLink;
            }}
          >
            {({ isActive }) => {
              return (
                <>
                  <span
                    style={
                      {
                        padding: '0 10px',
                      }
                    }
                  >
                    {t(tab.name)}
                  </span>
                  {isActive ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div
                        className={style.activeBorder}
                        style={{
                          width: `calc(100% - ${+tab.activeLinkPadding * 2}px)`,
                        }}
                      ></div>
                    </div>
                  ) : null}
                </>
              );
            }}
          </NavLink>
        );
      })}
    </div>
  );
};

export default LinkWrapper;
