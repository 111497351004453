import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Svg, Input, TextArea, ButtonSpinner } from '../../../../components';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { useAppDispatch, useAppSelector } from '../../../../store';
import style from './style.module.scss';
import { Button } from '@progress/kendo-react-buttons';
import { updateStorageAreasCapacityById } from '../../../../store/slices/storageArea.slice';
import { useParams } from 'react-router-dom';

interface IModal {
  closeHandler: () => void;
  updaterHandler: (data: any) => void;
}

const Modal: FC<IModal> = ({ closeHandler, updaterHandler }) => {
  const dispatch = useAppDispatch();
  const { storageArea, isStorageAreaDataLoading } = useAppSelector((state) => state.storageArea);
  const { t } = useTranslation();
  const { areaId } = useParams();

  const valueValidation = (value: string) => {
    if (storageArea) {
      const currentValue = +storageArea?.maximumCapacity - storageArea?.freeCapacitySpace;
      if (+value > 0 && currentValue + +value > +storageArea?.maximumCapacity) {
        return t("Value can't be more than the maximum capacity");
      }
      if (+value <= 0 && currentValue * -1 > +value) {
        return t("Value can't be less than the current value");
      }
      return '';
    }
    return '';
  };

  return (
    <div>
      <div className={style.topWrapper}>
        <div className={style.enterCorrectionEntryText}>{t('Enter correction entry')}</div>
        <Svg
          icon='closeIcon'
          style={{
            width: '24px',
            height: '24px',
            cursor: 'pointer',
          }}
          onClick={closeHandler}
        />
      </div>
      <div>
        <Form
          initialValues={{
            value: '',
            comment: '',
          }}
          onSubmitClick={async ({ values }) => {
            await dispatch(
              updateStorageAreasCapacityById({
                comment: values.comment,
                storageAreaId: areaId ?? '',
                value: +values.value,
                closeHandler,
              }),
            );
            updaterHandler({ updated: true });
          }}
          render={(props) => {
            const isDisabled = !props.valueGetter('value') && !storageArea?.storageAreaIsDissolveType;
            return (
              <FormElement>
                <fieldset className={`${style.fieldset} ${style.formWrapper}`}>
                  <div
                    style={{
                      marginBottom: '20px',
                    }}
                  >
                    <div
                      style={{
                        marginBottom: '8px',
                      }}
                    >
                      <span className={style.title}>{t('Value')} </span>
                      <span className={style.smallText}>({t('positive or negative')})</span>
                    </div>
                    <div>
                      <Field
                        component={Input}
                        name='value'
                        type='number'
                        customProp={{
                          placeholder: t('Enter here'),
                        }}
                        validator={valueValidation}
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      className={style.title}
                      style={{
                        marginBottom: '8px',
                      }}
                    >
                      {t('Booking Comment')}
                    </div>
                    <div>
                      <Field
                        component={TextArea}
                        name='comment'
                        customProp={{
                          placeholder: t('Enter here'),
                        }}
                      />
                    </div>
                  </div>
                  <div></div>
                </fieldset>
                <div className={style.bottomWrapper}>
                  <Button
                    type={'button'}
                    onClick={() => {
                      closeHandler();
                      props.onFormReset();
                    }}
                    className={style.cancelBtn}
                  >
                    {t('Abbrechen')}
                  </Button>
                  <Button type={isDisabled ? 'button' : 'submit'} disabled={isDisabled} className={style.createBtn}>
                    {isStorageAreaDataLoading ? <ButtonSpinner /> : t('Save Entry')}
                  </Button>
                </div>
              </FormElement>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Modal;
