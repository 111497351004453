import { IBookingHistory } from '../../store/slices/storageAreaBookingHistory';
import { adminInstance } from '../api';

const getStorageAreaBookingHistory = ({
  limit,
  page,
  sortBy,
  sortOrder,
  storageArea,
  storageItem,
}: {
  page?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: string;
  storageItem?: string;
  storageArea?: string;
}) => {
  return adminInstance
    .get<{
      data: IBookingHistory[];
      totalCount: number;
    }>(`api/v1/booking-history`, {
      params: {
        limit,
        page,
        sortBy,
        sortOrder,
        storageArea,
        storageItem,
      },
    })
    .then((res) => res.data);
};

const BookingHistory = {
  get: {
    getStorageAreaBookingHistory,
  },
  post: {},
  put: {},
  delete: {},
};

export default BookingHistory;
