import React from 'react';
import style from './style.module.scss';
import { Svg } from '..';

const DetailButton = ({ actionHandle }: { actionHandle: () => void }) => {
  return (
    <div className={style.arrowWrapper} onClick={actionHandle}>
      <Svg
        icon='nextPageIcon'
        style={{
          border: '1px solid #EA7600',
          width: '28px',
          height: '28px',
          padding: '8px',
          borderRadius: '4px',
        }}
      />
    </div>
  );
};

export default DetailButton;
