import React, { FC, useEffect, useState } from 'react';
import style from './style.module.scss';
import { useAppDispatch, useAppSelector } from '../../store';
import { Button } from '@progress/kendo-react-buttons';
import { setIsModalActive } from '../../store/slices/app.slice';
import WarehouseSelect from './components/WarehouseSelect';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Svg } from '..';

interface IHeaderComponent {
  withBackArrow?: boolean;
}

export const HeaderComponent: FC<IHeaderComponent> = ({ withBackArrow = true }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isModalActive = useAppSelector((state) => state.app.client.isModalActive);
  const isWarehousesDataLoading = useAppSelector((state) => state.warehouse.isWarehousesDataLoading);
  const warehouse = useAppSelector((state) => state.warehouse.warehouse);
  const { ['*']: currentPath } = useParams();
  const [name, setName] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (location.pathname.split('/').length === 2 || location.pathname === '/lav/warehouse') {
      const defaultName = t('Warehouses');
      setName(defaultName);
    } else {
      setName(warehouse?.name ?? '');
    }
  }, [location, warehouse]);

  return (
    <div
      className={style.wrapper}
      style={{
        filter: isModalActive ? 'blur(2px)' : '',
      }}
    >
      <div className={style.titleWrapper}>
        {withBackArrow ? (
          <Svg
            icon='backHeaderArrow'
            width={19}
            height={14}
            className={style.back}
            onClick={() => {
              navigate('/warehouse', {
                replace: false,
              });
            }}
          />
        ) : null}
        <div className={style.title}>{isWarehousesDataLoading ? '' : name}</div>
      </div>
      {currentPath === 'warehouse' ? (
        <div>
          <Button onClick={() => dispatch(setIsModalActive(true))} className={style.button}>
            <Svg icon='plusIcon' />
            {t('Add New Warehouse')}
          </Button>
        </div>
      ) : (
        <WarehouseSelect />
      )}
    </div>
  );
};
