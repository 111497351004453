import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  clearStorageClass,
  createStorageClass,
  deleteStorageClasses,
  getStorageClassById,
  updateStorageClass,
} from '../../store/slices/storageClass.slice';
import { clearUnitsData, getUnitsAreaData } from '../../store/slices/unit.slice';
import { ButtonSpinner, PulsLoader, Input, TextArea, DropDown, LinkNavigationComponent, Svg } from '../../components';
import { DeletionModal } from '../../helpers/modals';
import ExitConfirmationModal from '../../components/ExitConfirmationModal';

const StorageClassDetails = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { classId, id } = useParams();
  const navigate = useNavigate();
  const storageClass = useAppSelector((state) => state.storageClass.storageClass);
  const isStorageClassDataLoading = useAppSelector((state) => state.storageClass.isStorageClassDataLoading);
  const units = useAppSelector((state) => state.unit.unitsData);

  const deleteHandler = (hide: () => void) => {
    if (classId) {
      dispatch(
        deleteStorageClasses({
          ids: [classId],
          text: t('Storage class has been successfully deleted.'),
          navigate: () => {
            navigate(`/warehouse/${id}/classes`);
          },
          hide,
        }),
      );
    }
  };

  const openModalHandler = () => {
    DeletionModal({
      deleteHandler,
      deleteText: t('Are you sure that you want to delete storage classes?'),
      deleteTitle: t('Delete Storage Classes?'),
    });
  };

  useEffect(() => {
    if (classId) {
      Promise.all([dispatch(getStorageClassById(classId)), dispatch(getUnitsAreaData())]);
    } else {
      dispatch(getUnitsAreaData());
    }

    return () => {
      dispatch(clearStorageClass());
      dispatch(clearUnitsData());
    };
  }, []);

  if (classId && !storageClass) {
    return <PulsLoader />;
  }

  return (
    <div
      style={{
        height: 'calc(100% - 55px)',
      }}
    >
      <LinkNavigationComponent
        navigate={() => {
          navigate(`/warehouse/${id}/classes`, {
            replace: false,
          });
        }}
        listText='Storage Classes List'
        detailText={classId ? t('Storage Class Details') : t('Add New Storage Class')}
      />
      <Form
        initialValues={{
          name: storageClass && classId ? storageClass.name : '',
          descr: storageClass && classId ? storageClass.description : '',
          number: storageClass && classId ? storageClass.number : '',
          unit: storageClass && classId ? storageClass.unit : '',
          maximumCapacity: storageClass && classId ? storageClass.maximumCapacity : '',
        }}
        onSubmit={(values) => {
          if (!classId) {
            dispatch(
              createStorageClass({
                data: {
                  description: values.descr,
                  maximumCapacity: values.maximumCapacity,
                  name: values.name,
                  number: values.number,
                  unit: values.unit.id,
                },
                navigate: () => {
                  navigate(`/warehouse/${id}/classes`, {
                    replace: true,
                  });
                },
                successMessage: t('Storage Class has been successfully created.'),
              }),
            );
          } else {
            dispatch(
              updateStorageClass({
                id: classId,
                data: {
                  description: values.descr,
                  maximumCapacity: values.maximumCapacity,
                  name: values.name,
                  number: values.number,
                  unit: values.unit.id,
                },
                navigate: () => {
                  navigate(`/warehouse/${id}/classes`, {
                    replace: true,
                  });
                },
                successMessage: t('Storage Class has been successfully updated.'),
              }),
            );
          }
        }}
        render={(props: FormRenderProps) => {
          const isDisabled =
            !props.valueGetter('number') || !props.valueGetter('name') || !props.valueGetter('unit')?.id || !props.valueGetter('maximumCapacity');

          return (
            <>
              <FormElement
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                }}
              >
                <fieldset
                  style={{
                    border: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        marginBottom: '24px',
                        width: '48.6%',
                        flexShrink: 0,
                      }}
                    >
                      <div className={style.fieldTitle}>{t('Name of Storage Class')}</div>
                      <Field name='name' component={Input} customProp={{ placeholder: 'Enter name' }} />
                    </div>
                    <div
                      style={{
                        marginBottom: '24px',
                        width: '48.6%',
                        flexShrink: 0,
                      }}
                    >
                      <div className={style.fieldTitle}>{t('Number')}</div>
                      <Field name='number' component={Input} customProp={{ placeholder: 'Enter number' }} />
                    </div>
                    <div
                      style={{
                        marginBottom: '24px',
                        width: '48.6%',
                        flexShrink: 0,
                      }}
                    >
                      <div className={style.fieldTitle}>{t('Unit')}</div>
                      <Field
                        name='unit'
                        component={DropDown}
                        customProp={{
                          placeholder: 'Select unit',
                          data: units,
                          textField: 'abbreviation',
                          dataItemKey: 'id',
                        }}
                      />
                    </div>
                    <div
                      style={{
                        marginBottom: '24px',
                        width: '48.6%',
                        flexShrink: 0,
                      }}
                    >
                      <div className={style.fieldTitle}>{t('Maximum Capacity')}</div>
                      <Field name='maximumCapacity' type='number' component={Input} customProp={{ placeholder: 'Enter maximum capacity' }} />
                    </div>
                  </div>
                  <div className={style.textAreaWrapper}>
                    <div className={style.fieldTitle}>
                      {t('Description')}
                      <span className={style.optionalText}> ({t('optional')})</span>
                    </div>
                    <Field name='descr' component={TextArea} customProp={{ placeholder: 'Enter description' }} />
                  </div>
                </fieldset>
                <div className={classId ? style.buttonWrapper : style.btnWrapper}>
                  {classId ? (
                    <Button className={style.deleteBtn} onClick={openModalHandler} type='button'>
                      <Svg
                        icon='deleteIcon'
                        style={{
                          width: '16px',
                          height: '16px',
                        }}
                        className={style.deleteImg}
                      />
                      {t('Delete')}
                    </Button>
                  ) : null}
                  <Button
                    type={isDisabled || !props.modified ? 'button' : 'submit'}
                    className={isDisabled || !props.modified ? style.disabledCreateBtn : style.createBtn}
                    style={{ width: !classId ? '356px' : '262px' }}
                  >
                    {isStorageClassDataLoading ? <ButtonSpinner /> : t('Save')}
                  </Button>
                </div>
              </FormElement>
              <ExitConfirmationModal when={!props.submitted && props.modified} />
            </>
          );
        }}
      />
    </div>
  );
};

export default StorageClassDetails;
