import { IPerson } from '../../store/slices/users.slice';
import { adminInstance } from '../api';

const getUserList = () => {
  return adminInstance.get<IPerson[]>(`api/v1/user/list`).then((res) => res.data);
};

const createUser = (data: { firstName: string; lastName: string; email: string }) => {
  return adminInstance.post<IPerson>(`api/v1/user`, data).then((res) => res.data);
};

const deleteUsers = (ids: string[]) => {
  return adminInstance.delete(`api/v1/user?ids=${ids}`).then((res) => res.data);
};

const getUserById = (userId: string) => {
  return adminInstance.get<IPerson>(`api/v1/user/${userId}`).then((res) => res.data);
};

const getUserInfo = () => {
  return adminInstance.get<IPerson>(`api/v1/user/me`).then((res) => res.data);
};

const updateUserById = (
  userId: string,
  data: { firstName: string; lastName: string; email: string },
) => {
  return adminInstance.put<IPerson>(`api/v1/user/${userId}`, data).then((res) => res.data);
};

const User = {
  get: {
    getUserList,
    getUserById,
    getUserInfo,
  },
  post: {
    createUser,
  },
  put: {
    updateUserById,
  },
  delete: {
    deleteUsers,
  },
};

export default User;
