import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import style from './style.module.scss';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { IWarehouse, deleteWarehouseById, setIsWarehouseDataLoading, updateWarehouseById } from '../../store/slices/warehouse.slice';
import { Button } from '@progress/kendo-react-buttons';
import { ButtonSpinner, PulsLoader, Input, TextArea, Svg, DropDown } from '../../components';
import { DeletionModal } from '../../helpers/modals';
import { useTranslation } from 'react-i18next';
import ExitConfirmationModal from '../../components/ExitConfirmationModal';
import { Checkbox } from '@progress/kendo-react-inputs';

const WarehouseDetails = () => {
  const dispatch = useAppDispatch();
  const warehouse = useAppSelector((state) => state.warehouse.warehouse);
  const isWarehousesDataLoading = useAppSelector((state) => state.warehouse.isWarehousesDataLoading);
  const { t } = useTranslation();
  const allWarehouses = useAppSelector((state) => state.warehouse.warehousesData);

  const deleteWareHouseHandler = async (hide: () => void) => {
    if (warehouse) {
      await dispatch(
        deleteWarehouseById({
          id: warehouse.id,
          navigate: () => {
            window.location.replace('/warehouse');
          },
          hide,
          successMessage: t('Warehouse has been successfully deleted.'),
        }),
      );
    }
  };

  const openModalHandler = () => {
    DeletionModal({
      deleteHandler: deleteWareHouseHandler,
      deleteText: t('Are you sure that you want to delete warehouse?'),
      deleteTitle: t('Delete Warehouse?'),
    });
  };

  useEffect(() => {
    return () => {
      dispatch(setIsWarehouseDataLoading(false));
    };
  }, []);

  if (!warehouse || isWarehousesDataLoading) {
    return <PulsLoader />;
  }

  if (warehouse.connectedWarehouse) {
    (!warehouse?.connectedWarehouse?.id || !warehouse?.connectedWarehouse?.name) && <PulsLoader />;
  }

  return (
    <>
      {!isWarehousesDataLoading ? (
        <Form
          key={warehouse?.id}
          initialValues={{
            warehouseName: warehouse?.name,
            descr: warehouse?.description,
            konzernNr: warehouse?.konzernNr,
            firmaNr: warehouse?.firmaNr,
            betriebNr: warehouse?.betriebNr,
            isStorageItemCreatingAllowed: warehouse?.isStorageItemCreatingAllowed,
            connectedWarehouse: warehouse?.connectedWarehouse
              ? { id: warehouse?.connectedWarehouse?.id, name: warehouse?.connectedWarehouse?.name }
              : { id: null, name: t('No connected warehouse') },
          }}
          onSubmit={(values) => {
            if (warehouse) {
              dispatch(
                updateWarehouseById({
                  id: warehouse?.id,
                  data: {
                    name: values.warehouseName,
                    betriebNr: values.betriebNr,
                    description: values.descr,
                    firmaNr: values.firmaNr,
                    konzernNr: values.konzernNr,
                    isStorageItemCreatingAllowed: values.isStorageItemCreatingAllowed,
                    connectedWarehouseId: values.connectedWarehouse.id,
                  },
                  successMessage: t('Warehouse has been successfully updated.'),
                }),
              );
            }
          }}
          render={(props: FormRenderProps) => {
            const isDisabled =
              !props.valueGetter('warehouseName') ||
              !props.valueGetter('konzernNr') ||
              !props.valueGetter('firmaNr') ||
              !props.valueGetter('betriebNr');
            !props.valueGetter('connectedWarehouse');

            return (
              <>
                <FormElement className={style.form}>
                  <fieldset className={style.fieldset}>
                    <div>
                      <div
                        style={{
                          marginBottom: '48px',
                        }}
                      >
                        <div className={style.groupTitle}>{t('Main Info')}</div>
                        <div
                          style={{
                            marginBottom: '23px',
                          }}
                        >
                          <div className={style.fieldTitle}>{t('Name of the warehouse')}</div>
                          <Field name='warehouseName' component={Input} customProp={{ placeholder: 'Enter name' }} />
                        </div>
                        <div className={style.textAreaWrapper}>
                          <div className={style.fieldTitle}>
                            {t('Description')}
                            <span className={style.optionalText}> ({t('optional')})</span>
                          </div>
                          <Field name='descr' component={TextArea} customProp={{ placeholder: 'Enter description' }} />
                        </div>
                      </div>
                      <div>
                        <div className={style.groupTitle}>{t('Address details')}</div>
                        <div className={style.addressDetailWrapper}>
                          <div className={style.addressDetailItemWrapper}>
                            <div className={style.fieldTitle}>{t('Konzern Nr.')}</div>
                            <Field name='konzernNr' component={Input} customProp={{ placeholder: 'Enter Konzern Nr.' }} />
                          </div>
                          <div className={style.addressDetailItemWrapper}>
                            <div className={style.fieldTitle}>{t('Firma Nr.')}</div>
                            <Field name='firmaNr' component={Input} customProp={{ placeholder: 'Enter Firma Nr.' }} />
                          </div>
                          <div className={style.addressDetailItemWrapper}>
                            <div className={style.fieldTitle}>{t('Betrieb Nr.')}</div>
                            <Field name='betriebNr' component={Input} customProp={{ placeholder: 'Enter Betrieb Nr.' }} />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className={style.groupTitle}>{t('Connected warehouse')}</div>
                        <div className={style.addressDetailItemWrapper}>
                          <Field
                            name='connectedWarehouse'
                            component={DropDown}
                            customProp={{
                              data: [
                                { name: t('No connected warehouse'), id: null },
                                ...allWarehouses.filter((w: IWarehouse) => w.id !== warehouse?.id).map((w) => ({ name: t(w.name), id: w.id })),
                              ],
                              textField: 'name',
                              dataItemKey: 'id',
                            }}
                          />
                        </div>
                        <Field
                          name='isStorageItemCreatingAllowed'
                          component={Checkbox}
                          label={t('Allow manually create Storage Items')}
                          style={{
                            width: '20px',
                            height: '20px',
                          }}
                          className={props.valueGetter('isStorageItemCreatingAllowed') ? style.checkedCheckbox : ''}
                        />
                      </div>
                    </div>
                  </fieldset>
                  <div className={style.btnWrapper}>
                    <Button type={'button'} onClick={openModalHandler} className={style.deleteBtn}>
                      <Svg
                        icon='removeIcon'
                        style={{
                          width: '16px',
                          height: '16px',
                        }}
                        className={style.deleteImg}
                      />
                      {t('Delete')}
                    </Button>
                    <Button
                      type={isDisabled || !props.modified ? 'button' : 'submit'}
                      className={isDisabled || !props.modified ? style.disabledCreateBtn : style.createBtn}
                    >
                      {isWarehousesDataLoading ? <ButtonSpinner /> : t('Save')}
                    </Button>
                  </div>
                </FormElement>
                <ExitConfirmationModal when={!props.submitted && props.modified} />
              </>
            );
          }}
        />
      ) : (
        <PulsLoader />
      )}
    </>
  );
};

export default WarehouseDetails;
