import { DropDownList } from '@progress/kendo-react-dropdowns';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.scss';

interface ICustomTableGridPager {
  totalCount?: number;
  itemsPerPage?: number;
  itemsPerPageHandler?: (val: number) => void;
  page?: number;
  totalPages?: number;
  setPage?: (page: number) => void;
}

interface IIcon {
  isActive: boolean;
}

const PrevArrow: FC<IIcon> = ({ isActive }) => {
  return (
    <svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M5 1L1 5L5 9' stroke={isActive ? '#1D1D1B' : '#C8CED9'} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

const NextArrow: FC<IIcon> = ({ isActive }) => {
  return (
    <svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1 1L5 5L1 9' stroke={isActive ? '#1D1D1B' : '#C8CED9'} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const CustomTableGridPager: FC<ICustomTableGridPager> = ({
  itemsPerPage = 25,
  itemsPerPageHandler,
  page = 1,
  setPage,
  totalCount = 1,
  totalPages = 1,
}) => {
  const { t } = useTranslation();
  const data = ['25', '50', '100', '250', '500', '1000'];

  return (
    <div className={style.pagerWrapper}>
      <div className={style.dropDownWrapper}>
        <DropDownList
          className={style.dropdown}
          data={data}
          value={itemsPerPage}
          itemRender={(li, itemProps) => {
            return (
              <li
                className={itemProps.selected ? style.activeListItem : style.listItem}
                onClick={() => {
                  if (itemsPerPageHandler) {
                    itemsPerPageHandler(+itemProps.dataItem);
                  }
                }}
              >
                {itemProps.dataItem}
              </li>
            );
          }}
        />
        <span className={style.elementsProPageText}>{t('Elemente pro Seite')}</span>
      </div>
      <div className={style.pages}>
        <div
          style={{
            border: `1px solid ${page > 1 ? '#1D1D1B' : '#C8CED9'}`,
            marginRight: '23px',
            userSelect: 'none',
          }}
          className={style.arrowsWrapper}
          onClick={() => {
            if (page === 1) return;
            if (setPage) setPage(page - 1);
          }}
        >
          <PrevArrow isActive={page > 1} />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {totalPages < 7 ? (
            <>
              {Array.apply(0, Array(totalPages)).map((i, idx) => {
                return (
                  <div
                    className={`${style.pageNumberWrapper} ${page === idx + 1 ? style.activePageNumber : ''}`}
                    key={idx}
                    onClick={() => {
                      if (setPage) setPage(idx + 1);
                    }}
                  >
                    {idx + 1}
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {page % 4 >= 0 && page > 3 && page + 2 < totalPages ? (
                <>
                  <div
                    className={`${style.pageNumberWrapper} ${page === 1 ? style.activePageNumber : ''}`}
                    onClick={() => {
                      if (setPage) setPage(1);
                    }}
                  >
                    1
                  </div>
                  <div
                    className={style.pageNumberWrapper}
                    style={{
                      cursor: 'auto',
                    }}
                  >
                    ...
                  </div>
                  <div
                    className={`${style.pageNumberWrapper}`}
                    onClick={() => {
                      if (setPage) setPage(page - 1);
                    }}
                  >
                    {page - 1}
                  </div>
                  <div
                    className={`${style.pageNumberWrapper} ${style.activePageNumber}`}
                    onClick={() => {
                      if (setPage) setPage(page);
                    }}
                  >
                    {page}
                  </div>
                  <div
                    className={style.pageNumberWrapper}
                    onClick={() => {
                      if (setPage) setPage(page + 1);
                    }}
                  >
                    {page + 1}
                  </div>
                  <div
                    className={style.pageNumberWrapper}
                    style={{
                      cursor: 'auto',
                    }}
                  >
                    ...
                  </div>
                  <div
                    className={`${style.pageNumberWrapper} ${page === totalPages ? style.activePageNumber : ''}`}
                    onClick={() => {
                      if (setPage) setPage(totalPages);
                    }}
                  >
                    {totalPages}
                  </div>
                </>
              ) : page % 3 >= 0 && page > 4 && page + 2 >= totalPages ? (
                <>
                  <div
                    className={`${style.pageNumberWrapper} ${page === 1 ? style.activePageNumber : ''}`}
                    onClick={() => {
                      if (setPage) setPage(1);
                    }}
                  >
                    1
                  </div>
                  <div
                    className={style.pageNumberWrapper}
                    style={{
                      cursor: 'auto',
                    }}
                  >
                    ...
                  </div>
                  <div
                    className={`${style.pageNumberWrapper} ${page === totalPages - 4 ? style.activePageNumber : ''}`}
                    onClick={() => {
                      if (setPage) setPage(totalPages - 3);
                    }}
                  >
                    {totalPages - 4}
                  </div>
                  <div
                    className={`${style.pageNumberWrapper} ${page === totalPages - 3 ? style.activePageNumber : ''}`}
                    onClick={() => {
                      if (setPage) setPage(totalPages - 3);
                    }}
                  >
                    {totalPages - 3}
                  </div>
                  <div
                    className={`${style.pageNumberWrapper} ${page === totalPages - 2 ? style.activePageNumber : ''}`}
                    onClick={() => {
                      if (setPage) setPage(totalPages - 2);
                    }}
                  >
                    {totalPages - 2}
                  </div>
                  <div
                    className={`${style.pageNumberWrapper} ${page === totalPages - 1 ? style.activePageNumber : ''}`}
                    onClick={() => {
                      if (setPage) setPage(totalPages - 1);
                    }}
                  >
                    {totalPages - 1}
                  </div>
                  <div
                    className={`${style.pageNumberWrapper} ${page === totalPages ? style.activePageNumber : ''}`}
                    onClick={() => {
                      if (setPage) setPage(totalPages);
                    }}
                  >
                    {totalPages}
                  </div>
                </>
              ) : (
                <>
                  {Array.apply(0, Array(5)).map((i, idx) => {
                    return (
                      <div
                        key={idx}
                        className={`${style.pageNumberWrapper} ${page === idx + 1 ? style.activePageNumber : ''}`}
                        onClick={() => {
                          if (setPage) setPage(idx + 1);
                        }}
                      >
                        {idx + 1}
                      </div>
                    );
                  })}
                  <div
                    className={style.pageNumberWrapper}
                    style={{
                      cursor: 'auto',
                    }}
                  >
                    ...
                  </div>
                  <div
                    className={`${style.pageNumberWrapper} ${page === totalPages ? style.activePageNumber : ''}`}
                    onClick={() => {
                      if (setPage) setPage(totalPages);
                    }}
                  >
                    {totalPages}
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div
          style={{
            border: `1px solid ${page < totalPages ? '#1D1D1B' : '#C8CED9'}`,
            marginLeft: '23px',
            userSelect: 'none',
          }}
          className={style.arrowsWrapper}
          onClick={() => {
            if (page === totalPages) return;
            if (setPage) setPage(page + 1);
          }}
        >
          <NextArrow isActive={page < totalPages} />
        </div>
      </div>
      <div className={style.totalCount}>
        {t('Einträge')} {(page - 1) * itemsPerPage + 1}-{page * itemsPerPage} {t('von')} {totalCount}
      </div>
    </div>
  );
};
