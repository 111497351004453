import React, { FC } from 'react';
import { TableGrid } from '../../../../components';
import { useAppSelector } from '../../../../store';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SortDescriptor } from '@progress/kendo-data-query';

interface IContentComponent {
  dataHandler: (data: { sort: Array<SortDescriptor>; itemsPerPage?: number; currentPage?: number; inputValue?: string }) => void;
  deleteHandler: (hide: () => void, ids: string[]) => void;
}

const ContentComponent: FC<IContentComponent> = ({ dataHandler, deleteHandler }) => {
  const { t } = useTranslation();
  const storageCriteriaData = useAppSelector((state) => state.storageCriteria.storageCriteriaData);
  const storageCriteriaDataCount = useAppSelector((state) => state.storageCriteria.storageCriteriaDataCount);
  const navigate = useNavigate();

  return (
    <div>
      <TableGrid
        data={storageCriteriaData}
        items={[
          {
            field: 'number',
            title: 'Number',
            width: '140px',
          },
          {
            field: 'name',
            title: 'Name',
          },
          {
            field: 'unit.name',
            title: 'Unit',
            width: '140px',
          },
          {
            field: 'maximumCapacity',
            title: 'Max Capacity',
            width: '190px',
          },
        ]}
        totalCount={storageCriteriaDataCount}
        dataHandler={dataHandler}
        navigate={() => {
          navigate('new-criteria', {
            replace: false,
          });
        }}
        withHeaderSearch
        addButtonText='Add Storage Criteria'
        deleteFunc={deleteHandler}
        deleteText={t('Are you sure that you want to delete storage criteria?') || ''}
        deleteTitle={t('Delete Storage Criteria?') || ''}
        saveSorting
        sortingKey='storageCriteria'
      />
    </div>
  );
};

export default ContentComponent;
