import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import DetailButton from '../DetailButton';
import style from './style.module.scss';

const DetailBtnTableCell = (props: GridCellProps & { onClick: () => void }) => {
  return (
    <td
      className={`k-table-td ${style.linkCell}`}
      style={{
        ...props.style,
        border: 'none',
        position: 'sticky',
        backgroundColor: 'rgba(218, 218, 218, 0.5) !important',
        borderLeft: '1px solid rgba(0, 0, 0, 0.08) !important',
      }}
    >
      <DetailButton actionHandle={props.onClick} />
    </td>
  );
};

export default DetailBtnTableCell;
