import React, { FC } from 'react';
import style from './style.module.scss';
import { ContentWrapper } from '../../../../components/ContentWrapper';
import { useAppSelector } from '../../../../store';
import LinkWrapper from '../LinkWrapper';

interface IWarehouseDataWrapper {
  children: React.ReactNode;
}

const tabs = [
  {
    name: 'Warehouse Details',
    route: 'details',
    activeLinkPadding: '7.5',
  },
  {
    name: 'Units',
    route: 'units',
    activeLinkPadding: '9',
  },
  {
    name: 'Storage Classes',
    route: 'classes',
    activeLinkPadding: '8',
  },
  {
    name: 'Storage Criteria',
    route: 'criteria',
    activeLinkPadding: '7.5',
  },
  {
    name: 'Storage Locations',
    route: 'location',
    activeLinkPadding: '7.5',
  },
  {
    name: 'Storage Areas',
    route: 'area',
    activeLinkPadding: '8',
  },
];

export const WarehouseDataWrapper: FC<IWarehouseDataWrapper> = ({ children }) => {
  const isWarehousesDataLoading = useAppSelector((state) => state.warehouse.isWarehousesDataLoading);
  const isStorageClassDataLoading = useAppSelector((state) => state.storageClass.isStorageClassDataLoading);
  const isStorageAreaDataLoading = useAppSelector((state) => state.storageArea.isStorageAreaDataLoading);
  const isStorageCriteriaDataLoading = useAppSelector((state) => state.storageCriteria.isStorageCriteriaDataLoading);
  const isUnitDataLoading = useAppSelector((state) => state.unit.isUnitDataLoading);
  const isStorageAreaHandlePageActive = useAppSelector((state) => state.storageArea.isStorageAreaHandlePageActive);

  const isLoading =
    isWarehousesDataLoading || isStorageClassDataLoading || isStorageAreaDataLoading || isStorageCriteriaDataLoading || isUnitDataLoading;

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <ContentWrapper
        style={{
          position: 'relative',
        }}
      >
        <LinkWrapper tabs={tabs}/>
        <div
          className={style.contentWrapper}
          style={{
            position: isLoading ? 'relative' : undefined,
            padding: isStorageAreaHandlePageActive ? 0 : '',
          }}
        >
          {children}
        </div>
      </ContentWrapper>
    </div>
  );
};
