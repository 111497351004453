import { IStorageCriteria, IStorageCriteriaBody, IStorageCriteriaDataResponse } from '../../store/slices/storageCriteria.slice';
import { adminInstance } from '../api';

const getStorageCriteria = ({
  limit,
  page,
  search,
  sortBy,
  sortOrder,
}: {
  page?: number;
  limit?: number;
  search?: string;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc' | '';
}) => {
  return adminInstance
    .get<IStorageCriteriaDataResponse | IStorageCriteria[]>(`api/v1/storage-criteria`, {
      params: {
        page,
        limit,
        search,
        sortBy,
        sortOrder,
      },
    })
    .then((res) => res.data);
};
const getStorageCriteriaById = (id: string) => {
  return adminInstance.get<IStorageCriteria>(`api/v1/storage-criteria/${id}`).then((res) => res.data);
};

const createStorageCriteria = (data: IStorageCriteriaBody) => {
  return adminInstance.post<IStorageCriteria>(`api/v1/storage-criteria`, data).then((res) => res.data);
};

const updateStorageCriteriaById = (id: string, data: IStorageCriteriaBody) => {
  return adminInstance.put<IStorageCriteria>(`api/v1/storage-criteria/${id}`, data).then((res) => res.data);
};

const deleteStorageCriteriaById = (ids: string[]) => {
  return adminInstance
    .delete(`api/v1/storage-criteria?`, {
      params: {
        ids,
      },
    })
    .then((res) => res.data);
};

const StorageCriteria = {
  get: {
    getStorageCriteria,
    getStorageCriteriaById,
  },
  post: {
    createStorageCriteria,
  },
  put: {
    updateStorageCriteriaById,
  },
  delete: {
    deleteStorageCriteriaById,
  },
};

export default StorageCriteria;
