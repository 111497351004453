import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { showErrorNotification } from '../../util/showErrorNotification';
import API from '../../api/api';

export interface IActivityProtocol {
  id: string;
  user: {
    name: string;
    id?: string;
  };
  changes: any;
  action: string;
  table: string;
  warehouse: string;
  date: string;
}

export interface IActivityProtocolState {
  data: IActivityProtocol[];
  dataCount: number;
  isActivityProtocolDataLoading: boolean;
  isDataFirstTimeLoading: boolean;
}

const initialState: IActivityProtocolState = {
  data: [],
  dataCount: 0,
  isActivityProtocolDataLoading: false,
  isDataFirstTimeLoading: false,
};

export const getActivityProtocolData = createAsyncThunk(
  'activityProtocol/getActivityProtocolData',
  async (
    {
      limit,
      page,
      search,
      sortBy,
      sortOrder,
      endDate,
      startDate,
    }: {
      page?: number;
      limit?: number;
      search?: string;
      sortBy?: string;
      sortOrder?: 'asc' | 'desc';
      startDate?: string;
      endDate?: string;
      isDataFirstTimeLoading?: boolean;
    },
    thunkApi,
  ) => {
    try {
      const data = await API.activityProtocol.get.getActivityProtocol({ limit, page, search, sortBy, sortOrder, endDate, startDate });

      return data;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

const activityProtocolSlice = createSlice({
  name: 'loadingList',
  initialState,
  reducers: {
    clearActivityProtocolData: (state) => {
      state.data = [];
      state.dataCount = 0;
    },
    setActivityProtocolDataLoading: (state, action: PayloadAction<boolean>) => {
      state.isActivityProtocolDataLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getActivityProtocolData.pending, (state, action) => {
      if (action.meta.arg.isDataFirstTimeLoading) {
        state.isDataFirstTimeLoading = true;
      }
      state.isActivityProtocolDataLoading = true;
    });
    builder.addCase(getActivityProtocolData.fulfilled, (state, action) => {
      if (action.payload) {
        state.data = action.payload.data;
        state.dataCount = action.payload.totalCount;
      }
      state.isActivityProtocolDataLoading = false;
      state.isDataFirstTimeLoading = false;
    });
    builder.addCase(getActivityProtocolData.rejected, (state) => {
      state.isActivityProtocolDataLoading = false;
      state.isDataFirstTimeLoading = false;
    });
  },
});

export const { clearActivityProtocolData, setActivityProtocolDataLoading } = activityProtocolSlice.actions;

export const activityProtocolReducer = activityProtocolSlice.reducer;
