import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../../api/api';
import { setIsLoading } from './app.slice';
import { reset } from '..';

export interface IPerson {
  firstName: string;
  lastName: string;
  email: string;
  role: 'ADMIN' | 'USER';
  createdAt: string;
  _id: string;
}

export interface IUsersState {
  users: IPerson[];
  userInfo: IPerson | null;
}

const initialState: IUsersState = {
  users: [],
  userInfo: null,
};

export const getUserList = createAsyncThunk('users/getUserList', async (_, thunkApi) => {
  try {
    thunkApi.dispatch(setIsLoading(true));
    const users = await API.user.get.getUserList();

    thunkApi.dispatch(setIsLoading(false));
    return users;
  } catch (error) {
    thunkApi.dispatch(setIsLoading(false));
    console.log('error', error);
  }
});

export const createUser = createAsyncThunk('users/createUser', async (data: { firstName: string; lastName: string; email: string }, thunkApi) => {
  try {
    thunkApi.dispatch(setIsLoading(true));
    const user = await API.user.post.createUser(data);

    thunkApi.dispatch(setIsLoading(false));
    return user;
  } catch (error) {
    thunkApi.dispatch(setIsLoading(false));
    console.log('error', error);
  }
});

export const deleteUsers = createAsyncThunk('users/deleteUsers', async (ids: string[], thunkApi) => {
  try {
    thunkApi.dispatch(setIsLoading(true));
    await API.user.delete.deleteUsers(ids);
    thunkApi.dispatch(setIsLoading(false));
    return ids;
  } catch (error) {
    thunkApi.dispatch(setIsLoading(false));
    console.log(error);
  }
});

export const getUserById = createAsyncThunk('users/getUserById', async (id: string, thunkApi) => {
  try {
    thunkApi.dispatch(setIsLoading(true));
    const user = await API.user.get.getUserById(id);
    thunkApi.dispatch(setIsLoading(false));
    return user;
  } catch (error) {
    thunkApi.dispatch(setIsLoading(false));
    console.log(error);
  }
});

export const updateUserById = createAsyncThunk(
  'users/updateUserById',
  async (obj: { id: string; data: { firstName: string; lastName: string; email: string } }, thunkApi) => {
    try {
      thunkApi.dispatch(setIsLoading(true));
      const user = await API.user.put.updateUserById(obj.id, obj.data);

      thunkApi.dispatch(setIsLoading(false));
      return user;
    } catch (error) {
      thunkApi.dispatch(setIsLoading(false));
      console.log(error);
    }
  },
);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clearUsers: (state) => {
      state.users = [];
    },
    clearUserInfo: (state) => {
      state.userInfo = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserList.fulfilled, (state, action) => {
      if (action.payload?.length) {
        state.users = [...action.payload];
      }
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      if (action.payload) {
        state.users = [...state.users, action.payload];
      }
    });
    builder.addCase(deleteUsers.fulfilled, (state, action) => {
      state.users = [...state.users.filter((user) => !action.payload?.includes(user._id) && user)];
    });
    builder.addCase(getUserById.fulfilled, (state, action) => {
      if (action.payload) {
        state.userInfo = action.payload;
      }
    });
    builder.addCase(updateUserById.fulfilled, (state, action) => {
      if (action.payload) {
        state.userInfo = action.payload;
      }
    });
      builder.addCase(reset, () => {
        return initialState;
      });
  },
});

export const { clearUsers, clearUserInfo } = usersSlice.actions;

export const usersReducer = usersSlice.reducer;
