import { ReactComponent as WarehouseIcon } from '../img/warehouseIcon.svg';
import { ReactComponent as DashboardIcon } from '../img/dashboardIcon.svg';
import { ReactComponent as ReceivingAreaIcon } from '../img/receivingAreaIcon.svg';
import { ReactComponent as StorageAreaIcon } from '../img/storageSearchIcon.svg';
import { ReactComponent as LoadingListIcon } from '../img/loadingListIcon.svg';
import { ReactComponent as FireBrigadeReportIcon } from '../img/fireBrigadeReportIcon.svg';
import { ReactComponent as StorageArea } from '../img/storageAreaPin.svg';
import { ReactComponent as ActivityProtocolIcon } from '../img/activityProtocolIcon.svg';
import { ROLES } from '../helpers/constants';
import { SVGProps } from 'react';

export interface SidebarLink {
  title: string;
  route: string;
  roles?: string[];
  icon: React.ComponentType<SVGProps<SVGSVGElement>>;
  activeColor: string;
  inactiveColor: string;
}

export interface SidebarGroup {
  groupName: string;
  roles?: string[];
  linkNames: SidebarLink[];
}

export const sidebarConfig: SidebarGroup[] = [
  {
    groupName: 'Operations',
    linkNames: [
      {
        title: 'Dashboard',
        route: '/dashboard',
        roles: [ROLES.ADMIN, ROLES.MANAGEMENT],
        icon: DashboardIcon,
        activeColor: '#EA7600',
        inactiveColor: '#C8CED9',
      },
      {
        title: 'Receiving Area',
        route: '/receiving-area',
        icon: ReceivingAreaIcon,
        activeColor: '#EA7600',
        inactiveColor: '#C8CED9',
      },
      {
        title: 'Storage Search',
        route: '/storage-search',
        icon: StorageAreaIcon,
        activeColor: '#EA7600',
        inactiveColor: '#C8CED9',
      },
      {
        title: 'Loading List',
        route: '/loading-list',
        icon: LoadingListIcon,
        activeColor: '#EA7600',
        inactiveColor: '#C8CED9',
      },
      {
        title: 'Storage Area',
        route: '/storage-area',
        icon: StorageArea,
        activeColor: '#EA7600',
        inactiveColor: '#C8CED9',
      },
    ],
  },
  {
    groupName: 'Reports',
    linkNames: [
      {
        title: 'Fire Brigade Report',
        route: '/brigade-report',
        icon: FireBrigadeReportIcon,
        activeColor: '',
        inactiveColor: '',
      },
    ],
  },
  {
    groupName: 'Administration',
    roles: [ROLES.ADMIN, ROLES.MANAGEMENT],
    linkNames: [
      {
        title: 'Warehouses',
        route: '/warehouse',
        roles: [ROLES.ADMIN, ROLES.MANAGEMENT],
        icon: WarehouseIcon,
        activeColor: '',
        inactiveColor: '',
      },
      {
        title: 'Activity Protocol',
        route: '/activity-protocol',
        roles: [ROLES.ADMIN, ROLES.MANAGEMENT],
        icon: ActivityProtocolIcon,
        activeColor: '',
        inactiveColor: '',
      },
    ],
  },
];
