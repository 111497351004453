import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextArea as KendoTextArea } from '@progress/kendo-react-inputs';
import style from './style.module.scss';

export const TextArea = (p: any) => {
  const { t } = useTranslation();
  const { customProp = {}, ...other } = p || {};

  if (!customProp.className) {
    customProp.className = style.textArea;
  }

  if (customProp.placeholder) {
    customProp.placeholder = t(customProp.placeholder);
  }

  return <KendoTextArea {...{ ...other, ...customProp }} />;
};
