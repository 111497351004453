import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { useNavigate, useParams } from 'react-router-dom';
import { clearUnit, createUnit, deleteUnit, getUnitById, updateUnit } from '../../store/slices/unit.slice';
import style from './style.module.scss';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { ButtonSpinner, PulsLoader, Input, TextArea, LinkNavigationComponent, Svg } from '../../components';
import { DeletionModal } from '../../helpers/modals';
import ExitConfirmationModal from '../../components/ExitConfirmationModal';

const UnitDetails = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { unitId, id } = useParams();
  const navigate = useNavigate();
  const unit = useAppSelector((state) => state.unit.unit);
  const isUnitDataLoading = useAppSelector((state) => state.unit.isUnitDataLoading);

  const deleteHandler = (hide: () => void) => {
    if (unitId) {
      dispatch(
        deleteUnit({
          ids: [unitId],
          text: t('Unit have been successfully deleted.'),
          navigate: () => {
            navigate(`/warehouse/${id}/units`);
          },
          hide,
        }),
      );
    }
  };

  const openModalHandler = () => {
    DeletionModal({
      deleteHandler,
      deleteText: t('Are you sure that you want to delete units?'),
      deleteTitle: t('Delete Units?'),
    });
  };

  useEffect(() => {
    if (unitId) {
      dispatch(getUnitById(unitId));
    }

    return () => {
      dispatch(clearUnit());
    };
  }, []);

  if (unitId && !unit) {
    return <PulsLoader />;
  }

  return (
    <div
      style={{
        height: 'calc(100% - 55px)',
      }}
    >
      <LinkNavigationComponent
        navigate={() => {
          navigate(`/warehouse/${id}/units`, {
            replace: false,
          });
        }}
        listText='Units List'
        detailText={unitId ? t('Unit Details') : t('Add New Unit')}
      />
      <Form
        initialValues={{
          name: unit && unitId ? unit.name : '',
          abbreviation: unit && unitId ? unit.abbreviation : '',
          descr: unit && unitId ? unit.description : '',
        }}
        onSubmit={(values) => {
          if (!unitId) {
            dispatch(
              createUnit({
                data: {
                  description: values.descr,
                  name: values.name,
                  abbreviation: values.abbreviation,
                },
                navigate: () => {
                  navigate(`/warehouse/${id}/units`, {
                    replace: false,
                  });
                },
                successMessage: t('Unit has been successfully created.'),
              }),
            );
          } else {
            dispatch(
              updateUnit({
                id: unitId,
                data: {
                  description: values.descr,
                  name: values.name,
                  abbreviation: values.abbreviation,
                },
                navigate: () => {
                  navigate(`/warehouse/${id}/units`, {
                    replace: false,
                  });
                },
                successMessage: t('Unit has been successfully updated.'),
              }),
            );
          }
        }}
        render={(props: FormRenderProps) => {
          const isDisabled = !props.valueGetter('abbreviation') || !props.valueGetter('name');
          return (
            <>
              <FormElement
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                }}
              >
                <fieldset
                  style={{
                    border: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        marginBottom: '24px',
                        width: '48.6%',
                        flexShrink: 0,
                      }}
                    >
                      <div className={style.fieldTitle}>{t('Name of Unit')}</div>
                      <Field name='name' component={Input} customProp={{ placeholder: 'Enter name' }} />
                    </div>
                    <div
                      style={{
                        marginBottom: '24px',
                        width: '48.6%',
                        flexShrink: 0,
                      }}
                    >
                      <div className={style.fieldTitle}>{t('Abbreviation')}</div>
                      <Field name='abbreviation' component={Input} customProp={{ placeholder: 'Enter abbreviation' }} />
                    </div>
                  </div>
                  <div className={style.textAreaWrapper}>
                    <div className={style.fieldTitle}>
                      {t('Description')}
                      <span className={style.optionalText}> ({t('optional')})</span>
                    </div>
                    <Field name='descr' component={TextArea} customProp={{ placeholder: 'Enter description' }} />
                  </div>
                </fieldset>
                <div className={unitId ? style.buttonWrapper : style.btnWrapper}>
                  {unitId ? (
                    <Button className={style.deleteBtn} onClick={openModalHandler} type='button'>
                      <Svg
                        icon='deleteIcon'
                        style={{
                          width: '16px',
                          height: '16px',
                        }}
                        className={style.deleteImg}
                      />
                      {t('Delete')}
                    </Button>
                  ) : null}
                  <Button
                    type={isDisabled || !props.modified ? 'button' : 'submit'}
                    className={isDisabled || !props.modified ? style.disabledCreateBtn : style.createBtn}
                    style={{
                      width: !unitId ? '356px' : '262px',
                    }}
                  >
                    {isUnitDataLoading ? <ButtonSpinner /> : t('Save')}
                  </Button>
                </div>
              </FormElement>
              <ExitConfirmationModal when={!props.submitted && props.modified} />
            </>
          );
        }}
      />
    </div>
  );
};

export default UnitDetails;
