import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../../api/api';
import { showErrorNotification } from '../../util/showErrorNotification';
import { IStorageItem } from './storageItem.slice';
import { reset } from '..';

enum BOOKING_TYPE {
  IN = 'In',
  MOVE = 'Move',
  OUT = 'Out',
}

export interface IBookingHistory {
  id: string;
  date: string;
  user: string;
  bookingType: BOOKING_TYPE;
  bookingComment: string;
  storageArea: any;
  storageLocation: any;
  storageAreaId: string;
  storageItem: IStorageItem;
  quantity?: number;
  unit?: string;
}

interface IInitialState {
  storageAreaBookingHistory: IBookingHistory[];
  isStorageAreaBookingHistoryLoading: boolean;
  isFirstTimeStorageAreaBookingHistoryLoading: boolean;
  storageAreaBookingHistoryTotalCount: number;
}

const initialState: IInitialState = {
  storageAreaBookingHistory: [],
  isStorageAreaBookingHistoryLoading: false,
  storageAreaBookingHistoryTotalCount: 0,
  isFirstTimeStorageAreaBookingHistoryLoading: false,
};

export const getStorageAreaBookingHistory = createAsyncThunk(
  'storageAreaBookingHistory/getStorageAreaBookingHistory',
  async (
    {
      limit,
      page,
      sortBy,
      sortOrder,
      storageArea,
    }: {
      page?: number;
      limit?: number;
      sortBy?: string;
      sortOrder?: string;
      storageArea?: string;
      isFirstTime?: boolean;
    },
    thunkApi,
  ) => {
    try {
      const storageAreaBookingHistory = await API.bookingHistory.get.getStorageAreaBookingHistory({
        limit,
        page,
        sortBy,
        sortOrder,
        storageArea,
      });

      return storageAreaBookingHistory;
    } catch (error) {
      thunkApi.dispatch(showErrorNotification(error));
    }
  },
);

export const storageAreaBookingHistorySlice = createSlice({
  name: 'storageAreaBookingHistory',
  initialState,
  reducers: {
    clearStorageAreaBookingHistory: (state) => {
      state.isFirstTimeStorageAreaBookingHistoryLoading = false;
      state.storageAreaBookingHistory = [];
      state.storageAreaBookingHistoryTotalCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStorageAreaBookingHistory.pending, (state, action) => {
      if (action.meta.arg.isFirstTime) {
        state.isFirstTimeStorageAreaBookingHistoryLoading = action.meta.arg.isFirstTime;
      }
      state.isStorageAreaBookingHistoryLoading = true;
    });
    builder.addCase(getStorageAreaBookingHistory.fulfilled, (state, action) => {
      if (action.payload) {
        state.storageAreaBookingHistory = [...action.payload.data];
        state.storageAreaBookingHistoryTotalCount = action.payload.totalCount;
      }
      state.isStorageAreaBookingHistoryLoading = false;
      state.isFirstTimeStorageAreaBookingHistoryLoading = false;
    });
    builder.addCase(getStorageAreaBookingHistory.rejected, (state) => {
      state.isStorageAreaBookingHistoryLoading = false;
      state.isFirstTimeStorageAreaBookingHistoryLoading = false;
    });
    builder.addCase(reset, () => {
      return initialState;
    });
  },
});

export const { clearStorageAreaBookingHistory } = storageAreaBookingHistorySlice.actions;

export const storageAreaBookingHistoryReducer = storageAreaBookingHistorySlice.reducer;
