import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputChangeEvent, Input as KendoInput } from '@progress/kendo-react-inputs';
import style from './style.module.scss';

export const Input = (p: any) => {
  const { t } = useTranslation();
  const { customProp, validationMessage, ...other } = p || {};

  if (!customProp.className) {
    customProp.className = style.input;
  }

  if (validationMessage) {
    customProp.className = `${customProp.className} ${style.error}`;
  }

  if (customProp.placeholder) {
    customProp.placeholder = t(customProp.placeholder);
  }

  return (
    <div
      style={{
        position: 'relative',
        // width: '100%'
      }}
    >
      <KendoInput {...{ ...other, ...customProp }} />
      {validationMessage ? (
        <div
          style={{
            color: 'red',
          }}
        >
          {validationMessage}
        </div>
      ) : null}
    </div>
  );
};
